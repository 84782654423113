/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import { withSnackbar } from "notistack";
import * as moment from "moment";
import { DownloadExcel } from "../../../../../servies/services";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import NumberComp2 from "../../../../../components/NumberComp2";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Sidebar from "../../../../../components/sidebar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  ViewEsmaReport,
  getAllVersionsByPaymentDate,
  getAllPaymentDatesByDealName,
  generateAnnex14XML,
  download14XML
} from "../../../../../servies/services";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import AccordIcon from "../../../../../images/AccordIcon.png";
import * as ExcelJS from "exceljs";
import json2xml from "json2xml";
import { debounce } from "lodash";
import { Spin } from 'antd'; // Import the Spin component

class Annex14 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
      downArrow: false,
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      annex14Records: {},
      isAccordian: false,
      isAccordian1: false,
      isAccordian2: false,
      isAccordian3: false,
      DealName: sessionStorage.getItem("dealname"),
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      Version: sessionStorage.getItem("version"),
      VersionsChange: [],
      Assetclass: sessionStorage.getItem("Assetclass"),
      isESMA_Flag: sessionStorage.getItem("isESMA_Flag"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      getlastPaymentDates: [],
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      formLoader1: false,
      screenloader: false,
      MapDataValues: [],
      MapDataKeys: [],
      data: [],
      searchTerm: "",
      isDataFound: true,
      isSearchOpen: false,
      sortColumn: "",
      sortAscending: true,

      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      loadingExcel: false,
      loadingXML: false,
      loadingCSV: false,
    };
    this.handleButtonClick = debounce(this.handleButtonClick.bind(this), 100);
  }

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  showPrev = () => {
    this.props.history.push("/admin/annex12");
  };
  showNext = () => {
    // this.props.history.push("/admin/annex14");
  };

  popoverBottomDownload = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={this.handleDownloadExcel}>
          Excel {this.state.loadingExcel && <Spin size="small" />}
        </button>
        <hr className="servicer-popover-hr" />
        <button onClick={this.handleGenerateAnnex2XML}>
          XML {this.state.loadingXML && <Spin size="small" />}
        </button>
        <hr className="servicer-popover-hr" />
        <button onClick={this.handleDownloadCSV}>
          CSV {this.state.loadingCSV && <Spin size="small" />}
        </button>
      </Popover>
    );
  };

  handleGenerateAnnex2XML = async () => {
    this.setState({ loadingXML: true });
    try {
      await this.generateAnnex14XML();
    } finally {
      this.setState({ loadingXML: false });
    }
  };

  generateAnnex14XML = async () => {
    try {
      this.setState({ formLoader1: true }); // Show loader while API is being called

      // Ensure NextPaymentDate is defined
      const x = this.state?.NextPaymentDate;
      if (!x) {
        this.props.enqueueSnackbar("Next Payment Date is missing!", {
          variant: "error",
        });
        this.setState({ formLoader1: false });
        return;
      }

      // Extract month and year safely
      const month = x.length >= 2 ? x.slice(0, 2) : "";
      const year = x.length >= 10 ? x.slice(6, 10) : "";

      // Ensure DealName exists
      const dealName = this.state.DealName || "UnknownDeal";

      // Prepare the request data
      const requestData = {
        DealName: dealName,
        Month: month,
        Year: year,
        Version: this.state.Version,
        peer: this.state.peer,
      };

      let APIResponse;
      try {
        APIResponse = await generateAnnex14XML(requestData);
      } catch (error) {
        APIResponse = error;
      }

      console.log("APIResponse", APIResponse);

      // Check the isSuccess flag in the response
      if (APIResponse?.data?.isSuccess) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "success",
          autoHideDuration: 2000,
        });

        // Call download function since XML generation was successful
        await this.download14XML({
          DealName: dealName,
          Month: month,
          Year: year,
        });
      } else {
        // Handle API response errors
        let errorMessages = [];
        if (APIResponse?.data?.errors?.length > 0) {
          errorMessages = APIResponse.data.errors.map(
            (err) =>
              `Message: ${err.message}\nLine: ${err.line || "-"}\nColumn: ${
                err.column || "-"
              }`
          );
        } else if (APIResponse?.data?.error) {
          errorMessages.push(`Message: ${APIResponse.data.error}`);
        } else if (APIResponse?.data?.message) {
          errorMessages.push(`Message: ${APIResponse.data.message}`);
        }

        console.log("errorMessages", errorMessages);

        // Save errors to a text file
        if (errorMessages.length > 0) {
          const errorText = errorMessages.join("\n\n");
          const filename = `ValidationErr-Annex14-${dealName}-${month}-${year}.txt`;

          console.log("errorText", errorText);

          try {
            const blob = new Blob([errorText], { type: "text/plain" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => URL.revokeObjectURL(link.href), 100);
            const message = APIResponse.data.message;
            this.props.enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 2000,
            });
            await this.download14XML({
              DealName: dealName,
              Month: month,
              Year: year,
            });
          } catch (error) {
            console.error("Error creating or downloading the file:", error);
          }
        }
      }
    } catch (error) {
      this.props.enqueueSnackbar("An error occurred while generating XML", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      this.setState({ formLoader1: false }); // Hide loader
    }
  };


download14XML = async (data) => {
    try {
        this.setState({ formLoader1: true });

        const fileName = `Annex14-${data.DealName}-${data.Month}-${data.Year}.xml`;
        const APIResponse = await download14XML(data);
        console.log({APIResponse});

        this.props.enqueueSnackbar("Download successful!", {
            variant: "success",
            autoHideDuration: 3000
        });

        if (APIResponse.data) {
            // Create a Blob from the XML string
            const blob = new Blob([APIResponse.data], { type: "application/xml" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            }, 100);
        } else {
            throw new Error("File content not provided");
        }
    } catch (error) {
        this.props.enqueueSnackbar(error.message || "An error occurred while downloading XML", {
            variant: "error",
            autoHideDuration: 3000
        });
    } finally {
        this.setState({ formLoader1: false });
    }
};

  handleButtonClick() {
    this.setState((prevState) => ({
      downArrow: !prevState.downArrow,
    }));
  }

  handleOverlayHide = () => {
    this.setState({ downArrow: false });
  };

  handleAccordian = () => {
    this.setState((prevState) => {
      return { isAccordian: !prevState.isAccordian };
    });
  };
  handleAccordian1 = () => {
    this.setState((prevState) => {
      return { isAccordian1: !prevState.isAccordian1 };
    });
  };
  handleAccordian2 = () => {
    this.setState((prevState) => {
      return { isAccordian2: !prevState.isAccordian2 };
    });
  };
  handleAccordian3 = () => {
    this.setState((prevState) => {
      return { isAccordian3: !prevState.isAccordian3 };
    });
  };

  handleClickAnnex = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
    });
    this.props.history.push("/admin/annex");
  };

  handleClickAnnex12 = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
    });
    this.props.history.push("/admin/annex12");
  };

  handleClickAnnex14 = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
    });
    this.props.history.push("/admin/annex14");
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  handleDealNameChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("dealname", newValue);
    this.setState({ DealName: newValue, isDisable: true }, async () => {
      console.log("pppppp", this.state.DealName);
      await this.getAllPaymentDatesByDealName();
      await this.getAllVersionsByPaymentDate();

      if (this.state.isLoansProcessed === "No") {
        this.props.history.push("/admin/general_recurring");
      }

      const { Version, getDashboardVersions } = this.state;

      if (
        (Version === "WIP" || Version === "V1") &&
        getDashboardVersions.length === 1
      ) {
        this.setState({ isDisable: false, Version: "WIP" }, async () => {
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      } else {
        function getMaxVersion(versions) {
          let highestNumber = -1;
          let prefix = "";

          for (const version of versions) {
            const numberMatch = version.match(/\d+/); // Extract the number using regex
            if (numberMatch) {
              const number = parseInt(numberMatch[0]);
              if (number > highestNumber) {
                highestNumber = number;
                prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
              }
            }
          }

          if (highestNumber >= 0) {
            return prefix + highestNumber;
          } else {
            // Handle the case where no valid version numbers were found
            return null;
          }
        }

        const latestVersion = getMaxVersion(getDashboardVersions); // Assuming the latest version is at the beginning of the array

        if (Version !== latestVersion) {
          // If the selected version is not the latest, call DealRecurring
          this.setState({ isDisable: this.state.isDisable });
          await this.ViewEsmaReport();
        } else {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.ViewEsmaReport();
            this.setState({ checkLoadStatus: false });
          });
        }
      }
    });
  };

  getAllPaymentDatesByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    const { NextPaymentDate } = this.state;

    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllPaymentDatesByDealName(data);

    console.log("getAllPaymentDatesByDealName", APIResponse.data);
    if (APIResponse.status === 200) {
      const paymentDates = APIResponse.data.PaymentDates;
      console.log(paymentDates, "payment");

      const [nextMonth, nextDay, nextYear] = NextPaymentDate.split("/");

      // Find the first payment date in the same month and year
      const sameMonthYearDate = paymentDates.find((date) => {
        const [month, day, year] = date.split("/");
        return month === nextMonth && year === nextYear;
      });

      const nextPaymentDate = sameMonthYearDate || paymentDates[0]; // Default to the first available date if none matches

      console.log("nextPaymentDate", nextPaymentDate);
      // // Determine the value for nextpaymentdate
      // const nextPaymentDate = paymentDates.includes(NextPaymentDate)
      //   ? NextPaymentDate
      //   : paymentDates[0];

      // Update sessionStorage with the determined nextpaymentdate
      sessionStorage.setItem("nextpaymentdate", nextPaymentDate);

      // Update sessionStorage with the dashboard dates
      sessionStorage.setItem("getDashboardDates", JSON.stringify(paymentDates));

      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleDownloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("ESMA - Annex14");

    const { annex14Records } = this.state;

    let startRow = 1;

    for (const tableName in annex14Records) {
      const tableData = annex14Records[tableName];

      // Add table name as header with orange font color
      const tableHeaderRow = worksheet.addRow([tableName]);
      tableHeaderRow.font = { color: { argb: "003aff" }, bold: true };

      // Get the headers from the first row of data
      const headers = Object.keys(tableData[0]);
      const headerRow = worksheet.addRow(headers); // Add header row
      headerRow.font = { color: { argb: "e56811" }, bold: true }; // Set font color to orange

      // Add table data
      tableData.forEach((row) => {
        const values = Object.values(row);
        worksheet.addRow(values);
      });

      worksheet.addRow([]);

      startRow += tableData.length + 2; // Add 2 for spacing between tables
    }

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const filename = "ESMA - Annex14.xlsx";

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        }
      })
      .catch((err) => {
        console.error("Error writing Excel:", err);
      });
  };

  handleDownloadXML = () => {
    const { annex14Records } = this.state;

    console.log(">>>>", annex14Records);

    let xmlContent = '<?xml version="1.0" encoding="UTF-8"?><root>'; // Start with XML declaration and root element

    for (const tableName in annex14Records) {
      const tableData = annex14Records[tableName];

      xmlContent += `<${tableName}>`;
      tableData.forEach((row) => {
        xmlContent += json2xml({ [`${tableName}Item`]: row }, { header: true });
      });

      xmlContent += `</${tableName}>`;
    }

    xmlContent += "</root>"; // Close the root element

    const blob = new Blob([xmlContent], {
      type: "text/xml",
    });

    const filename = "ESMA - Annex14.xml";

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
            }
  };

  handleDownloadCSV = () => {
    this.setState({ loadingCSV: true });
    try {
        const { annex14Records } = this.state;
        let csvContent = [];

        // Add each table's data to the CSV content
        for (const tableName in annex14Records) {
            const tableData = annex14Records[tableName];
            
            // Add table name as a section header
            csvContent.push([tableName]);
            
            // Get headers from the first row
            const headers = Object.keys(tableData[0]);
            csvContent.push(headers);
            
            // Add table data
            tableData.forEach(row => {
                const rowValues = headers.map(header => {
                    const value = row[header]?.toString() || '';
                    // Escape quotes and handle commas
                    return value.includes(',') || value.includes('"') 
                        ? `"${value.replace(/"/g, '""')}"` 
                        : value;
                });
                csvContent.push(rowValues);
            });
            
            // Add blank line between tables
            csvContent.push([]);
        }

        // Convert array to CSV string
        const csvString = csvContent
            .map(row => row.join(','))
            .join('\n');

        // Create blob and download
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const filename = "ESMA - Annex14.csv";

        if (window.navigator.msSaveOrOpenBlob) {
            // Handle IE11
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
        }

        this.props.enqueueSnackbar('CSV downloaded successfully!', {
            variant: 'success',
            autoHideDuration: 3000,
        });
    } catch (error) {
        console.error('Error generating CSV:', error);
        this.props.enqueueSnackbar('Error downloading CSV', {
            variant: 'error',
            autoHideDuration: 3000,
        });
    } finally {
        this.setState({ loadingCSV: false });
    }
};

  ViewEsmaReport = async () => {
    this.setState({
      getLoansLoader: true,
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      loading: true,
      screenloader: true,
    });
    let x = this.state.NextPaymentDate;
    console.log();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;

    data.peer = this.state.peer;
    data.Version = this.state.Version;

    console.log("datatatata", data);
    const APIResponse = await ViewEsmaReport(data);
    console.log("ViewEsmaReport", APIResponse);

    if (APIResponse.status === 200 && APIResponse.data.isSuccess === true) {
      const annex14Data = APIResponse.data.data["Annex 14"];
      const securitisation = annex14Data["Securitisation Information"];
      const tranchebond = annex14Data["Tranche/Bond-Level Information"];
      const accountlevel = annex14Data["Account-Level Information"];
      const counterpartylevel = annex14Data["Counterparty-Level Information"];

      const Asset_Class = sessionStorage.getItem("Assetclass");
      let annexNumber;

      // Set annex number based on asset class
      if (this.state.isESMA_Flag === "Yes") {
        switch (Asset_Class) {
          case "Residential Real Estate":
            annexNumber = 2;
            break;
          case "Commercial Mortgage":
            annexNumber = 3;
            break;
          case "Corporate Loans":
            annexNumber = 4;
            break;
          case "Auto Loans":
            annexNumber = 5;
            break;
          case "Consumer Loans":
            annexNumber = 6;
            break;
          case "Credit Cards":
            annexNumber = 7;
            break;
          case "Leasing":
            annexNumber = 8;
            break;
          case "Esoteric":
            annexNumber = 9;
            break;
          case "Non-Performing Loans":
            annexNumber = 10;
            break;
          case "Asset Backed Commercial Papers":
            annexNumber = 11;
            break;
          default:
            annexNumber = "";
            break;
        }
      } else {
        annexNumber = "";
      }
      const buttonName = `Annex ${annexNumber}`;

      this.setState({
        getLoansLoader: false,
        tableData: securitisation,
        tableData1: tranchebond,
        tableData2: accountlevel,
        tableData3: counterpartylevel,
        annex14Records: annex14Data,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: buttonName,
      });
    } else if (
      APIResponse.status === 200 &&
      APIResponse.data.isSuccess === false
    ) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("nextpaymentdate", newValue);
    this.setState({ NextPaymentDate: newValue, isDisable: true }, async () => {
      console.log("pppppp", this.state.NextPaymentDate);
      await this.getAllVersionsByPaymentDate();

      if (this.state.isLoansProcessed === "No") {
        this.props.history.push("/admin/general_recurring");
      }
      const { Version, getDashboardVersions } = this.state;

      if (
        (Version === "WIP" || Version === "V1") &&
        getDashboardVersions.length === 1
      ) {
        this.setState({ isDisable: false }, async () => {
          sessionStorage.setItem("version", "WIP");
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      } else {
        function getMaxVersion(versions) {
          let highestNumber = -1;
          let prefix = "";

          for (const version of versions) {
            const numberMatch = version.match(/\d+/); // Extract the number using regex
            if (numberMatch) {
              const number = parseInt(numberMatch[0]);
              if (number > highestNumber) {
                highestNumber = number;
                prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
              }
            }
          }

          if (highestNumber >= 0) {
            return prefix + highestNumber;
          } else {
            // Handle the case where no valid version numbers were found
            return null;
          }
        }

        const latestVersion = getMaxVersion(getDashboardVersions); // Assuming the latest version is at the beginning of the array

        if (Version !== latestVersion) {
          // If the selected version is not the latest, call DealRecurring
          this.setState({ isDisable: this.state.isDisable });
          await this.ViewEsmaReport();
        } else {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.ViewEsmaReport();
            this.setState({ checkLoadStatus: false });
          });
        }
      }
    });
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the Version state with the selected value
    sessionStorage.setItem("version", newValue);
    this.setState(
      {
        Version: newValue,
        isDisable: true,
      },
      async () => {
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.ViewEsmaReport();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            // If the selected version is not the latest, call ViewEsmaReport
            this.setState({ isDisable: this.state.isDisable });
            this.ViewEsmaReport();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.ViewEsmaReport();
            });
          }
        }
      }
    );
  };

  componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.setItem("isTabChange", false);
    sessionStorage.setItem("AddedContent", []);
    sessionStorage.setItem("TranchItems", []);
    if (
      this.state.isLoansProcessed === "No" ||
      sessionStorage.getItem("isESMA_Flag") === "No"
    ) {
      this.props.history.push("/admin/general_recurring");
    }
    const { Version, getDashboardVersions } = this.state;

    if (
      (Version === "WIP" || Version === "V1") &&
      getDashboardVersions.length === 1
    ) {
      this.setState({ isDisable: false }, async () => {
        sessionStorage.setItem("version", "WIP");
        await this.ViewEsmaReport();
        this.setState({ checkLoadStatus: false });
      });
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          const numberMatch = version.match(/\d+/); // Extract the number using regex
          if (numberMatch) {
            const number = parseInt(numberMatch[0]);
            if (number > highestNumber) {
              highestNumber = number;
              prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }
      const latestVersion = getMaxVersion(getDashboardVersions);
      if (Version !== latestVersion) {
        // If the selected version is not the latest, call DealRecurring
        this.setState({ isDisable: this.state.isDisable });
      } else {
        this.setState({ isDisable: false, Version: "WIP" }, async () => {
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      }
    }

    // if (this.state.Version === "V1" || this.state.Version === "V2") {
    //   this.setState({ isDisable: this.state.isDisable });
    //   this.ViewEsmaReport();
    // } else {
    //   this.setState({ isDisable: false });
    //   this.ViewEsmaReport();
    // }
  }
  // async componentDidMount() {
  //   var component = window.location.pathname;
  //   sessionStorage.setItem("component", component);
  //   this.ViewAccountTable();
  // }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "1px solid var(--tableBorder) !important",
          },
          responsiveBase: {
            overflowX: 'auto',
          },
        },
        MUIDataTableBodyRow: {
          root: {
            '&:nth-child(odd)': {
              backgroundColor: 'var(--white) !important',
              '& td': {
                color: 'var(--tableText) !important',
                backgroundColor: 'var(--white) !important',
                '&:first-child': {
                  backgroundColor: 'var(--white) !important',
                  position: 'sticky',
                left: 0,
                  zIndex: 100,
                }
              }
            },
            '&:nth-child(even)': {
              backgroundColor: 'var(--tdEven) !important',
              '& td': {
                color: 'var(--tableText) !important',
                backgroundColor: 'var(--tdEven) !important',
                '&:first-child': {
                  backgroundColor: 'var(--tdEven) !important',
                  position: 'sticky',
                  left: 0,
                  zIndex: 100,
                }
              }
            },
            '&:hover': {
              backgroundColor: 'inherit !important',
            },
          },
        },
        MUIDataTableBodyCell: {
          root: {
            padding: "12px 16px",
            textAlign: "left",
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            color: "var(--tableText) !important",
            borderBottom: "var(--tableBorder) !important",
            position: "relative",
            zIndex: 1,
            "&::before": {
              display: 'none', // Remove the problematic pseudo-element
            },
            "&::after": {
              display: 'none',
            },
            "@media (min-width: 800px)": {
              "&:first-child": {
                position: "sticky",
                left: 0,
                zIndex: 1,
              },
            },
            "&:first-child": {
              paddingLeft: "24px",
            },
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "var(--table-header-bg) !important",
            color: "var(--headerText) !important",
            borderBottom: "var(--tableBorder) !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
            position: "relative",
            zIndex: 1,
            "&:first-child": {
              position: "sticky",
              left: 0,
              zIndex: 500,
              top: 0,
            },
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "var(--tableBorder) !important",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
          },
        },
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
            backgroundColor: "var(--white)",
            position: "relative",
            color: "var(--tableText) !important",
            border: " 1px solid var(--tableBorder) !important",
          },
        },
      },
    });

  render() {
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const sortedDeals = [...this.state.getDashboardDeals].sort();
    const columns = [
      {
        name: "Unique Identifier",
        label: "Unique Identifier",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Data Cut-Off Date",
        label: "Data Cut-Off Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "No Longer STS",
        label: "No Longer STS",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },

      {
        name: "Remedial Actions",
        label: "Remedial Actions",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Administrative Actions",
        label: "Administrative Actions",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Material Amendment to Transaction Documents",
        label: "Material Amendment to Transaction Documents",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Perfection Of Sale",
        label: "Perfection Of Sale",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Current Waterfall Type",
        label: "Current Waterfall Type",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Master Trust Type",
        label: "Master Trust Type",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "SSPE Value",
        label: "SSPE Value",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "SSPE Principal Value",
        label: "SSPE Principal Value",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "SSPE Number Of Accounts",
        label: "SSPE Number Of Accounts",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Note Principal Balance",
        label: "Note Principal Balance",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Seller Share",
        label: "Seller Share",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Funding Share",
        label: "Funding Share",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Revenue Allocated To This Series",
        label: "Revenue Allocated To This Series",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Interest Rate Swap Benchmark",
        label: "Interest Rate Swap Benchmark",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Interest Rate Swap Maturity Date",
        label: "Interest Rate Swap Maturity Date",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Interest Rate Swap Notional",
        label: "Interest Rate Swap Notional",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Currency Swap Payer Currency",
        label: "Currency Swap Payer Currency",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Currency Swap Receiver Currency",
        label: "Currency Swap Receiver Currency",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Exchange Rate For Currency Swap",
        label: "Exchange Rate For Currency Swap",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Currency Swap Maturity Date",
        label: "Currency Swap Maturity Date",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Currency Swap Notional",
        label: "Currency Swap Notional",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
    ];

    const columns2 = [
      {
        name: "Unique Identifier",
        label: "Unique Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Original Account Identifier",
        label: "Original Account Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "New Account Identifier",
        label: "New Account Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Account Type",
        label: "Account Type",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Account Target Balance",
        label: "Account Target Balance",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "3rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Account Actual Balance",
        label: "Account Actual Balance",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "3.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Amortising Account",
        label: "Amortising Account",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "4rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
    ];

    const columns1 = [
      {
        name: "Unique Identifier",
        label: "Unique Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Original Tranche Identifier",
        label: "Original Tranche Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "New Tranche Identifier",
        label: "New Tranche Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "International Securities Identification Number",
        label: "International Securities Identification Number",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Tranche Name",
        label: "Tranche Name",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Tranche/Bond Type",
        label: "Tranche/Bond Type",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Currency",
        label: "Currency",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Original Principal Balance",
        label: "Original Principal Balance",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Current Principal Balance",
        label: "Current Principal Balance",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Interest Payment Frequency",
        label: "Interest Payment Frequency",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Interest Payment Date",
        label: "Interest Payment Date",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Principal Payment Date",
        label: "Principal Payment Date",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Current Coupon",
        label: "Current Coupon",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Current Interest Rate Margin/Spread",
        label: "Current Interest Rate Margin/Spread",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Coupon Floor",
        label: "Coupon Floor",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Coupon Cap",
        label: "Coupon Cap",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Step-Up/Step-Down Coupon Value",
        label: "Step-Up/Step-Down Coupon Value",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Step-Up/Step-Down Coupon Date",
        label: "Step-Up/Step-Down Coupon Date",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Business Day Convention",
        label: "Business Day Convention",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Current Interest Rate Index",
        label: "Current Interest Rate Index",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Current Interest Rate Index Tenor",
        label: "Current Interest Rate Index Tenor",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Issue Date",
        label: "Issue Date",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Disbursement Date",
        label: "Disbursement Date",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Legal Maturity",
        label: "Legal Maturity",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Extension Clause",
        label: "Extension Clause",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Next Call Date",
        label: "Next Call Date",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Clean-Up Call Threshold",
        label: "Clean-Up Call Threshold",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Next Put date",
        label: "Next Put date",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Day Count Convention",
        label: "Day Count Convention",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Settlement Convention",
        label: "Settlement Convention",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Current Attachment Point",
        label: "Current Attachment Point",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Original Attachment Point",
        label: "Original Attachment Point",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Current Credit Enhancement",
        label: "Current Credit Enhancement",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Original Credit Enhancement",
        label: "Original Credit Enhancement",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Credit Enhancement Formula",
        label: "Credit Enhancement Formula",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Pari-Passu Tranches",
        label: "Pari-Passu Tranches",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Senior Tranches",
        label: "Senior Tranches",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Outstanding Principal Deficiency Ledger Balance",
        label: "Outstanding Principal Deficiency Ledger Balance",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Guarantor Legal Entity Identifier",
        label: "Guarantor Legal Entity Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Guarantor Name",
        label: "Guarantor Name",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Guarantor ESA Subsector",
        label: "Guarantor ESA Subsector",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Protection Type",
        label: "Protection Type",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
    ];

    const columns3 = [
      {
        name: "Unique Identifier",
        label: "Unique Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Counterparty Legal Entity Identifier",
        label: "Counterparty Legal Entity Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>{value}</React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Counterparty Name",
        label: "Counterparty Name",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Counterparty Type",
        label: "Counterparty Type",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Counterparty Country Of Establishment",
        label: "Counterparty Country Of Establishment",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Counterparty Rating Threshold",
        label: "Counterparty Rating Threshold",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Counterparty Rating",
        label: "Counterparty Rating",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Counterparty Rating Source Legal Entity Identifier",
        label: "Counterparty Rating Source Legal Entity Identifier",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>{value}</React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Counterparty Rating Source Name",
        label: "Counterparty Rating Source Name",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar activeComponent={"ESMA Reporting"} />
          <div className="content">
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div
                    className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1.5rem",
                      width: "90rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingBottom: "1.6rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      <h3 className="headerdashboard">ESMA REPORTING</h3>
                    </div>
                    <div
                      style={{
                        // display: "flex",
                        alignItems: "right",
                        gap: "5rem",
                      }}
                    ></div>
                  </div>
                </div>
                <div className="row row14 move-dropdowns-annexs">
                  <div className="general-InvContianer">
                    <div>
                      <label className="label">Deal Name</label>
                      <div>
                        <select
                          className="input-select-general-new1-deal"
                          value={this.state.DealName}
                          onChange={this.handleDealNameChange}
                        >
                          {sortedDeals.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="general-InvContianer">
                    <div>
                      <label className="label">Payment Date</label>
                      <select
                        className="input-select-general-new1"
                        value={this.state?.NextPaymentDate}
                        onChange={this.handlePaymentDateChange}
                      >
                        {this.state.getDashboardDates?.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="general-InvContianer">
                    <div>
                      <label className="label">Version</label>
                      <select
                        className="input-select-general-new1"
                        value={
                          this.state.Version === "WIP"
                            ? sessionStorage.getItem("version")
                            : this.state.Version
                        }
                        onChange={this.handleVersionChange}
                      >
                        {this.state.getDashboardVersions.map((option, index) => (
                          <option key={`${option}-${index}`} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "-35px" }}>
                  <div className="row row1 movement-tabs-annexs">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-15px",
                      }}
                    >
                      <div>
                        {this.state.buttonName ? (
                          <button
                            type="button"
                            onClick={() => this.handleClickAnnex()}
                            className={
                              this.state.activeInsights1 == true
                                ? "issuerDashboard-table-top-button-rejected-active"
                                : "issuerDashboard-table-top-button-rejected"
                            }
                          >
                            {this.state.buttonName}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => this.handleClickAnnex()}
                            className={
                              this.state.activeInsights1 == true
                                ? "issuerDashboard-table-top-button-rejected-active"
                                : "issuerDashboard-table-top-button-rejected"
                            }
                          >
                            Annex
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => this.handleClickAnnex12()}
                          className={
                            this.state.activeInsights2 == true
                              ? "issuerDashboard-table-top-button-insights-active"
                              : "issuerDashboard-table-top-button-insights"
                          }
                        >
                          Annex 12
                        </button>

                        <button
                          type="button"
                          onClick={() => this.handleClickAnnex14()}
                          className={
                            this.state.activeInsights3 == true
                              ? "issuerDashboard-table-top-button-workbench-active"
                              : "issuerDashboard-table-top-button-workbench"
                          }
                        >
                          Annex 14
                        </button>
                      </div>
                    </div>
                    <div>
                      {" "}
                      {this.state.createStatus === "Update" ? null : (
                        <OverlayTrigger
                          rootClose
                          trigger="click"
                          placement="bottom"
                          overlay={this.popoverBottomDownload()}
                          onExited={this.handleOverlayHide}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleButtonClick}
                          >
                            Download
                            {this.state.downArrow ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )}
                          </Button>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>

                  <div className="row row1 movement-header-annexs">
                    <div className="investor-heading-container">
                      <h1 className="headerdashboard1">
                        Securitization Information
                      </h1>
                    </div>
                    <div>
                      <img
                        src={AccordIcon}
                        alt="Accordian"
                        className={
                          this.state.isAccordian
                            ? "AccordImgAnnexReverse"
                            : "AccordImgAnnex"
                        }
                        onClick={this.handleAccordian}
                      />
                    </div>
                  </div>
                  {!this.state.isAccordian ? (
                    <React.Fragment>
                      <div className="workbench-table-container movement-table-annexs">
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                          <MUIDataTable
                            // title={'Dashboard'}
                            data={this.state.tableData}
                            columns={columns}
                            options={options}
                          />
                        </MuiThemeProvider>
                      </div>
                    </React.Fragment>
                  ) : null}

                  <div className="row row1 movement-header-annexs">
                    <div className="investor-heading-container">
                      <h1 className="headerdashboard1">
                        Tranche/Bond-Level Information
                      </h1>
                    </div>
                    <div>
                      <img
                        src={AccordIcon}
                        alt="Accordian"
                        className={
                          this.state.isAccordian1
                            ? "AccordImgAnnexReverse"
                            : "AccordImgAnnex"
                        }
                        onClick={this.handleAccordian1}
                      />
                    </div>
                  </div>
                  {!this.state.isAccordian1 ? (
                    <React.Fragment>
                      <div className="workbench-table-container movement-table-annexs">
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                          <MUIDataTable
                            // title={'Dashboard'}
                            data={this.state.tableData1}
                            columns={columns1}
                            options={options}
                          />
                        </MuiThemeProvider>
                      </div>
                    </React.Fragment>
                  ) : null}

                  <div className="row row1 movement-header-annexs">
                    <div className="investor-heading-container">
                      <h1 className="headerdashboard1">
                        Account-Level Information
                      </h1>
                    </div>
                    <div>
                      <img
                        src={AccordIcon}
                        alt="Accordian"
                        className={
                          this.state.isAccordian2
                            ? "AccordImgAnnexReverse"
                            : "AccordImgAnnex"
                        }
                        onClick={this.handleAccordian2}
                      />
                    </div>
                  </div>
                  {!this.state.isAccordian2 ? (
                    <React.Fragment>
                      <div className="workbench-table-container movement-table-annexs">
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                          <MUIDataTable
                            // title={'Dashboard'}
                            data={this.state.tableData2}
                            columns={columns2}
                            options={options}
                          />
                        </MuiThemeProvider>
                      </div>
                    </React.Fragment>
                  ) : null}

                  <div className="row row1 movement-header-annexs">
                    <div className="investor-heading-container">
                      <h1 className="headerdashboard1">
                        Counterparty-Level Information
                      </h1>
                    </div>
                    <div>
                      <img
                        src={AccordIcon}
                        alt="Accordian"
                        className={
                          this.state.isAccordian3
                            ? "AccordImgAnnexReverse"
                            : "AccordImgAnnex"
                        }
                        onClick={this.handleAccordian3}
                      />
                    </div>
                  </div>
                  {!this.state.isAccordian3 ? (
                    <React.Fragment>
                      <div className="workbench-table-container movement-table-annexs">
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                          <MUIDataTable
                            // title={'Dashboard'}
                            data={this.state.tableData3}
                            columns={columns3}
                            options={options}
                          />
                        </MuiThemeProvider>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
                <div>
                  <React.Fragment>
                    <>
                      {/* <React.Fragment> */}
                      <div className="btn_move_Needed">
                        <div
                          className="btn_prev"
                          onClick={(e) => this.showPrev(e)}
                        >
                          Previous
                        </div>
                        {/* <button
                          className="btn_next"
                          onClick={(e) => this.showNext(e)}
                        >
                          Next
                        </button> */}
                      </div>
                    </>
                  </React.Fragment>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Annex14);
