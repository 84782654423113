import AIcon1 from "../../images/AIcon11.png";
import PortfolioAnalyIcon from "../../images/PortfolioAnalyIcon.png";
import TrusteeIcon from "../../images/TrusteeIcon.png";
import DealAnalyIcon from "../../images/DealAnalyIcon.png";

import DashboardClient from "../../images/dashboardClient.png";
import SetUp from "../../images/SetUp.svg";
import SetUpRecurring from "../../images/SetUp_Reccuring.svg";
import ReportsRecurring from "../../images/ReportsRecurring.svg";
import ESMA from "../../images/ESMA.svg";
import initial_setupClient from "../../images/initial_setupClient.png";

const OrgName = sessionStorage.getItem("OrgName");

export const IssuerIcons = [
  {
    linkto: "#nogo",
    title: "Dashboard",
    icon: AIcon1,
    linkto: "/dashboard",
  },
  {
    linkto: "#nogo",
    title: "Deal Creation",
    icon: SetUp,
    linkto: "/admin/general",
  },
  {
    linkto: "#nogo",
    title: "Deal Recurring",
    icon: SetUpRecurring,
    linkto: "/admin/general_recurring",
  },
  {
    linkto: "#nogo",
    title: "Reporting",
    icon: ReportsRecurring,
    linkto: "/admin/collateral_recurring",
  },
  {
    linkto: "#nogo",
    title: "ESMA Reporting",
    icon: ESMA,
    linkto: "/admin/annex",
  },
];

export const Servicer_Dashboard = [
  {
    linkto: "#nogo",
    title: "Servicer Dashboard",
    icon: AIcon1,
    linkto: "/dashboard/servicer",
  },
];

// console.log({ isSecuritisation });

export const getInvestorDashboard = () => {
  const isSecuritisation =
    sessionStorage.getItem("isSecuritisation") === "Securitisation";

  return [
    {
      linkto: "#nogo",
      title: "Portfolio",
      icon: PortfolioAnalyIcon,
      linkto: "/investor/standardAnalytics",
    },
    {
      linkto: "#nogo",
      title: "Deal",
      icon: DealAnalyIcon,
      linkto: !isSecuritisation
        ? "/investor/fundingAnalytics"
        : "/investor/summaryAnalytics",
    },
    {
      linkto: "#nogo",
      title: "Consolidated Report",
      icon: TrusteeIcon,
      linkto: "/investor/dashboard",
    },
    {
      linkto: "#nogo",
      title: "ESMA Reporting",
      icon: ESMA,
      linkto: "/investor/Annex_Inv",
    },
  ];
};

export const Issuer_DashBoard = [
  {
    linkto: "#nogo",
    title: "IssuerPortfolioAnalytics",
    icon: PortfolioAnalyIcon,
    linkto: "/issuer/standard_issuer",
  },
  {
    linkto: "#nogo",
    title: "IssuerDealAnalytics",
    icon: DealAnalyIcon,
    linkto: "/issuer/summary_issuer",
  },
  {
    linkto: "#nogo",
    title: "IssuerDashBoard",
    icon: TrusteeIcon,
    linkto: "/issuer/dashboard",
  },
];

export const PendingUsersDashBoard = [
  {
    linkto: "#nogo",
    title: "PendingDashBoard",
    icon: AIcon1,
    linkto: "/admin/users/trustee/Pending",
  },
  {
    linkto: "#nogo",
    title: "SetUpDashBoard",
    icon: initial_setupClient,
    linkto: "/admin/trustee/setup-page",
  },
];

export const Processor = [
  {
    linkto: "#nogo",
    title: "Processor Dashboard",
    icon: AIcon1,
    linkto: "/processor/dashboard",
  },
];

export const Originator = [
  // {
  //   'linkto': '#nogo',
  //   'title': 'Portfolio Setup Wizard',
  //   'icon': AIcon1,
  //   'linkto': '/portfolio-setup-wizard/eligible-portfolio-import',
  // },
  // {
  //   'linkto': '#nogo',
  //   'title': 'Loan Pool Setup',
  //   'icon': AIcon2,
  //   'linkto': '/pool-details'
  // },
  // {
  //   'linkto': '#nogo',
  //   'title': 'Transaction Details',
  //   'icon': AIcon3,
  //   'linkto': '/transaction-details/bank-details',
  //   'subitems': [
  //     {
  //       'linkto': '/transaction-details/bank-details',
  //       'title': 'Bank Details',
  //       'icon': Icon,
  //     },
  //     {
  //       'linkto': '/transaction-details/payment-details',
  //       'title': 'Transaction Details',
  //       'icon': Icon,
  //     },
  //   ],
  // },
  // {
  //   'linkto': '#nogo',
  //   'title': 'Admin Dashboard',
  //   'icon': AIcon1,
  //   'linkto': '/admin/dashboard'
  // },
  {
    linkto: "#nogo",
    title: "Processor Dashboard",
    icon: AIcon1,
    linkto: "/processor/dashboard",
  },
  // {
  //   'linkto': '#nogo',
  //   'title': 'Processor Dashboard',
  //   'icon': AIcon2,
  //   'linkto': '/processor/rundd'
  // },
  // {
  //   'linkto': '#nogo',
  //   'title': 'Processor Dashboard',
  //   'icon': AIcon2,
  //   'linkto': '/processor/rundd/ddreport'
  // },
];

export const portfolioSetupWizard = [
  {
    linkto: "/portfolio-setup-wizard/eligible-portfolio-import",
    title: "Eligible Portfolio Import",
    className: "step1",
    step: "1",
  },
  {
    linkto: "/portfolio-setup-wizard/due-diligence",
    title: "Due Diligence",
    className: "step2",
    step: "2",
  },
  {
    linkto: "/portfolio-setup-wizard/pool-formation",
    title: "Pool Formation",
    className: "step3",
    step: "3",
  },
  {
    linkto: "/portfolio-setup-wizard/pool-details",
    title: "Pool Details",
    className: "step4",
    step: "4",
  },
];

export const adminUser = [
  {
    linkto: "/admin/users/" + OrgName + "/Pending",
    title: "Pending Users ",
    icon: DashboardClient,
    subitems: [
      {
        linkto: "/admin/users/" + OrgName + "/Pending",
        title: "Pending Users",
        icon: DashboardClient,
      },
      {
        linkto: "/admin/users/" + OrgName + "/Approved",
        title: "Approved Users",
        icon: DashboardClient,
      },
    ],
  },
];
