import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import Plot from "react-plotly.js";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import { DownOutlined, FilterFilled } from "@ant-design/icons";
import fIlter from "../../../../../images/FilterMappedTape.svg";

// Generic function to process data
const processData = (data, lengthKey) => {
  if (!data || Object.keys(data).length === 0) return [];
  const keys = Object.keys(data);
  const length = data[lengthKey].length;
  const tableData = [];

  for (let i = 0; i < length; i++) {
    const row = { key: i };
    keys.forEach((key) => {
      row[key] = data[key][i];
    });
    tableData.push(row);
  }

  return tableData;
};

const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};

function PortfolioDash() {
  const [plotData, setPlotData] = useState({
    PA_CDR_CPR_TIME: { data: [], layout: {} },
    PA_LTV: { data: [], layout: {} },
    PA_originations_revamp: { data: [], layout: {} },
    PA_DelinquenciesGraph: { data: [], layout: {} },
    PA_performing_nonPerforming_revamp: { data: [], layout: {} },
    PA_Collateral_Performance_temp_revamp: { data: [], layout: {} },
    PA_principalBal_dealwise_revamp: { data: [], layout: {} },
    PA_revamp_state: { data: [], layout: {} },
    PA_wtAvgFico_revamp: { data: [], layout: {} },
  });

  const [collateralData, setCollateralData] = useState([]);
  const [DealAndLoanDetails, setDealAndLoanDetails] = useState([]);
  const [Delinquency, setDelinquency] = useState([]);
  const [assetLevelInfo, setassetLevelInfo] = useState([]);
  const [cdr_cpr, setcdr_cpr] = useState([]);
  const [liabilitySideInfo, setliabilitySideInfo] = useState([]);
  const [tableTitles, setTableTitles] = useState([]);
  const plotContainerRef = useRef(null); // Ref to track the container
  const scatterPlotContainer = useRef(null); // Ref to track the container
  const [filterCriteria, setFilterCriteria] = useState({});
  const [filteredClrChangeColumns, setFilteredClrChangeColumns] = useState({});
  const [filterStates, setFilterStates] = useState({});
  const [currentBatch, setCurrentBatch] = useState(1);
  const [sortOrder, setSortOrder] = useState({});
  const [filteredData, setFilteredData] = useState({
    [tableTitles[1]]: [],
    [tableTitles[2]]: [],
    [tableTitles[3]]: [],
  });
  const [isFiltered, setIsFiltered] = useState({
    [tableTitles[1]]: false,
    [tableTitles[2]]: false,
    [tableTitles[3]]: false,
  });
  const itemsPerBatch = 45;

  // Handler to manage filtering for each table
  const handleFilter = (tableName, data, columnKey, criteria) => {
    console.log({ tableName, columnKey, criteria, data });

    const applyCondition = (val, cond, target) => {
      switch (cond) {
        case "equals":
          return val === target;
        case "less_than":
          return val < target;
        case "greater_than":
          return val > target;
        case "not_equals":
          return val !== target;
        default:
          return true;
      }
    };

    setFilterStates((prevState) => {
      const updatedState = { ...prevState };

      if (!updatedState[tableName]) {
        updatedState[tableName] = {};
      }

      const tableState = updatedState[tableName];

      // Immediately set isFiltered[tableName] to false when criteria is null
      if (!criteria) {
        setIsFiltered((prevIsFiltered) => ({
          ...prevIsFiltered,
          [tableName]: false, // Set isFiltered to false for this table
        }));
      }

      if (criteria) {
        // Apply filter if criteria is provided
        tableState[columnKey] = { ...criteria, filterApplied: true };
        setFilteredClrChangeColumns((prev) => ({
          ...prev,
          [`${tableName}-${columnKey}`]: true,
        }));
        // Set isFiltered to true if filtered data has results, otherwise set to false
        setIsFiltered((prevIsFiltered) => ({
          ...prevIsFiltered,
          [tableName]: true, // true if data exists after filter, else false
        }));
      } else {
        // Remove the filter when criteria is null
        delete tableState[columnKey];
        setFilteredClrChangeColumns((prev) => ({
          ...prev,
          [`${tableName}-${columnKey}`]: false,
        }));
      }

      // Apply filters and return updated state
      const filteredDataForTable = data.filter((item) =>
        Object.entries(tableState).every(([key, filterCriteria]) => {
          if (!filterCriteria) return true;

          const { condition1, value1, operator, condition2, value2 } =
            filterCriteria;

          const condition1Result = applyCondition(
            item[key],
            condition1,
            value1
          );
          const condition2Result = condition2
            ? applyCondition(item[key], condition2, value2)
            : true;

          return operator === "AND"
            ? condition1Result && condition2Result
            : condition1Result || condition2Result;
        })
      );

      console.log({ filteredDataForTable }); // Verify filtered data

      // Update filteredData state
      setFilteredData((prevFilteredData) => ({
        ...prevFilteredData,
        [tableName]: filteredDataForTable,
      }));

      return updatedState;
    });
  };

  const handleSort = (tableName, columnKey, order) => {
    let data;
    if (tableName === tableTitles[1]) {
      data = isFiltered[tableName] ? filteredData[tableName] : assetLevelInfo;
    } else if (tableName === tableTitles[2]) {
      data = isFiltered[tableName]
        ? filteredData[tableName]
        : liabilitySideInfo;
    } else if (tableName === tableTitles[3]) {
      data = isFiltered[tableName] ? filteredData[tableName] : collateralData;
    }

    // console.log({ isFiltered });

    const sortedData = [...data].sort((a, b) => {
      const vA = a[columnKey];
      const vB = b[columnKey];

      // Handle numeric values
      if (!isNaN(vA) && !isNaN(vB)) {
        return order === "ascend" ? vA - vB : vB - vA;
      }

      // Handle date values
      if (!isNaN(Date.parse(vA)) && !isNaN(Date.parse(vB))) {
        return order === "ascend"
          ? new Date(vA) - new Date(vB)
          : new Date(vB) - new Date(vA);
      }

      // Handle string values like "h1", "h2", "h10"
      const extractNumber = (str) => {
        const match = str.match(/(\d+)/); // Extract numeric part from the string
        return match ? parseInt(match[0], 10) : 0;
      };

      const numA = extractNumber(vA);
      const numB = extractNumber(vB);

      // Compare based on the numeric part (for strings like "h1", "h2", "h10")
      if (numA !== numB) {
        return order === "ascend" ? numA - numB : numB - numA;
      }

      // If numeric parts are equal, fallback to lexicographical comparison
      const compareStrings = vA?.localeCompare(vB) || 0;

      // Reverse the result if sorting is in descending order (Z to A)
      return order === "ascend" ? compareStrings : -compareStrings;
    });

    // After sorting, apply pagination (slice the sorted data)
    const slicedData = sortedData.slice(0, itemsPerBatch);

    // Debug log to check slicedData before setting the state
    // console.log("Sorted and sliced data for table", tableName, slicedData);

    // Update the appropriate state based on table name
    if (tableName === tableTitles[1]) {
      setassetLevelInfo(slicedData); // Check if this state is used in the table rendering
    } else if (tableName === tableTitles[2]) {
      setliabilitySideInfo(slicedData); // Check if this state is used in the table rendering
    } else if (tableName === tableTitles[3]) {
      setCollateralData(slicedData); // Check if this state is used in the table rendering
    }
  };

  useEffect(() => {
    if (tableTitles[1] && tableTitles[2] && tableTitles[3]) {
      setFilteredData({
        [tableTitles[1]]: [],
        [tableTitles[2]]: [],
        [tableTitles[3]]: [],
      });
      setIsFiltered({
        [tableTitles[1]]: false,
        [tableTitles[2]]: false,
        [tableTitles[3]]: false,
      });
    }
  }, [tableTitles]); // Run when tableTitles is updated

  // Dynamic column generation with specific table and column filtering
  const generateColumns = (
    data,
    specialColumnData,
    onFilter,
    onSort,
    tableName
  ) => {
    if (!data || data.length === 0) return [];

    const keys = Object.keys(data[0]).filter((key) => key !== "key");

    return keys.map((key, index) => {
      const column = {
        title: key.replace(/_/g, " "),
        dataIndex: key,
        key,
        width: 150,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            tableName={tableName}
            data={data}
            columnKey={key}
            onSort={onSort}
            onFilter={onFilter} // Use tableName in the filter handler
            columnValues={getUniqueValues(data, key)}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: filteredClrChangeColumns[`${tableName}-${key}`]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        onHeaderCell: () => ({
          style: {
            textAlign: "center", // Adjust alignment as needed
          },
        }),
        render: (text) => {
          const isDollarColumn = key.toLowerCase().includes("($)");
          if (isDollarColumn) {
            return <div style={{ textAlign: "right" }}>{text}</div>;
          }

          const isWholeOrIntegerWithZeroDecimal =
            /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text);
          const isCountColumn = key.toLowerCase().includes("count");
          const isPercentColumn = key.toLowerCase().includes("%");

          if (
            isWholeOrIntegerWithZeroDecimal ||
            isCountColumn ||
            isPercentColumn
          ) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }

          return <div style={{ textAlign: "left" }}>{text}</div>;
        },
      };

      if (specialColumnData && index === 0) {
        column.width = 150;
        column.fixed = "left";
      }

      return column;
    });
  };

  useEffect(() => {
    // Safely parse the finalData from sessionStorage
    const finalData = JSON.parse(sessionStorage.getItem("finalData") || "{}");

    // Check if finalData is an object and has keys
    if (finalData && Object.keys(finalData).length > 0) {
      const getTableKeys = Object.keys(finalData);
      console.log("finalData keys:", getTableKeys);
      setTableTitles(getTableKeys);
    } else {
      console.log("finalData is empty or not found in session storage.");
    }

    if (finalData && Object.keys(finalData).length !== 0) {
      // Function to parse plot data with dynamic layout width
      const parsePlotData = (data) => {
        let parsedData = { data: [], layout: {} };

        if (typeof data === "string") {
          parsedData = JSON.parse(data);
        } else if (typeof data === "object" && data !== null) {
          parsedData = data;
        }

        if (!parsedData.layout) {
          parsedData.layout = {};
        }

        // Set layout width to 100% using the container's width
        parsedData.layout.width =
          plotContainerRef.current?.offsetWidth || window.innerWidth;

        return parsedData;
      };
      const parsePlotDataScatter = (data) => {
        let parsedData = { data: [], layout: {} };

        if (typeof data === "string") {
          parsedData = JSON.parse(data);
        } else if (typeof data === "object" && data !== null) {
          parsedData = data;
        }

        if (!parsedData.layout) {
          parsedData.layout = {};
        }

        // Set layout width to 100% using the container's width
        parsedData.layout.width =
          scatterPlotContainer.current?.offsetWidth || window.innerWidth;

        return parsedData;
      };

      // Parsing and setting plot data objects with the generalized function
      const PA_CDR_CPR_TIME = parsePlotData(finalData["CPR/CDR Graph"]);
      const PA_LTV = parsePlotData(finalData["Loan to Value"]);
      const PA_originations_revamp = parsePlotData(finalData["Origination"]);
      const PA_DelinquenciesGraph = parsePlotData(
        finalData["Delinquencies Graph"]
      );
      const PA_performing_nonPerforming_revamp = parsePlotDataScatter(
        finalData["Collateral Performance"]
      );
      const PA_Collateral_Performance_temp_revamp = parsePlotDataScatter(
        finalData["Loss Severity"]
      );
      const PA_revamp_state = parsePlotDataScatter(finalData["Property State"]);
      const PA_wtAvgFico_revamp = parsePlotData(finalData["Borrower FICO"]);
      const PA_principalBal_dealwise_revamp = parsePlotData(
        finalData["Principal Balance"]
      );

      // Set the parsed and formatted plot data into state
      setPlotData({
        PA_CDR_CPR_TIME,
        PA_LTV,
        PA_originations_revamp,
        PA_performing_nonPerforming_revamp,
        PA_Collateral_Performance_temp_revamp,
        PA_DelinquenciesGraph,
        PA_revamp_state,
        PA_wtAvgFico_revamp,
        PA_principalBal_dealwise_revamp,
      });

      // Specific data processing functions calling the generic function
      const processCollateralData = (data) => processData(data, "Type");
      const processDelinquency = (data) => processData(data, "Loan Status");
      const processAssetLevelInfo = (data) => processData(data, "Deal Name");
      const processCDR_CPR = (data) => processData(data, "CDR/CPR");
      const processLiabilitySideInfo = (data) => processData(data, "Deal Name");

      // Using the functions with conditions
      if (finalData["Collection Summary"]) {
        setCollateralData(
          processCollateralData(finalData["Collection Summary"])
        );
      }
      if (finalData["Delinquency"]) {
        setDelinquency(processDelinquency(finalData["Delinquency"]));
      }
      if (finalData["Asset Summary"]) {
        setassetLevelInfo(processAssetLevelInfo(finalData["Asset Summary"]));
      }
      if (finalData["CPR/CDR"]) {
        setcdr_cpr(processCDR_CPR(finalData["CPR/CDR"]));
      }
      if (finalData["Bond Summary"]) {
        setliabilitySideInfo(
          processLiabilitySideInfo(finalData["Bond Summary"])
        );
      }
    }
    // Update layout width on window resize for multiple plots
    const handleResize = () => {
      setPlotData((prevPlotData) => {
        // Update plot data with new widths for each layout
        const updatedPlotData = {
          ...prevPlotData,
          PA_CDR_CPR_TIME: {
            ...prevPlotData.PA_CDR_CPR_TIME,
            layout: {
              ...prevPlotData.PA_CDR_CPR_TIME.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_LTV: {
            ...prevPlotData.PA_LTV,
            layout: {
              ...prevPlotData.PA_LTV.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_originations_revamp: {
            ...prevPlotData.PA_originations_revamp,
            layout: {
              ...prevPlotData.PA_originations_revamp.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_performing_nonPerforming_revamp: {
            ...prevPlotData.PA_performing_nonPerforming_revamp,
            layout: {
              ...prevPlotData.PA_performing_nonPerforming_revamp.layout,
              width:
                scatterPlotContainer.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_Collateral_Performance_temp_revamp: {
            ...prevPlotData.PA_Collateral_Performance_temp_revamp,
            layout: {
              ...prevPlotData.PA_Collateral_Performance_temp_revamp.layout,
              width:
                scatterPlotContainer.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_DelinquenciesGraph: {
            ...prevPlotData.PA_DelinquenciesGraph,
            layout: {
              ...prevPlotData.PA_DelinquenciesGraph.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_principalBal_dealwise_revamp: {
            ...prevPlotData.PA_principalBal_dealwise_revamp,
            layout: {
              ...prevPlotData.PA_principalBal_dealwise_revamp.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_revamp_state: {
            ...prevPlotData.PA_revamp_state,
            layout: {
              ...prevPlotData.PA_revamp_state.layout,
              width:
                scatterPlotContainer.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_wtAvgFico_revamp: {
            ...prevPlotData.PA_wtAvgFico_revamp,
            layout: {
              ...prevPlotData.PA_wtAvgFico_revamp.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
        };

        return updatedPlotData;
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Render each table separately with independent filter logic
  const renderTable = (data, title, specialColumnData, tableName) => {
    return (
      <>
        <label className="tableHeaders">{title}</label>
        <Table
          className="performance-table"
          columns={generateColumns(
            data,
            specialColumnData,
            handleFilter,
            handleSort,
            tableName
          )}
          dataSource={isFiltered[tableName] ? filteredData[tableName] : data} // Use filteredData if isFiltered is true for this table
          pagination={false}
          scroll={{
            x: "max-content",
            y: data.length > 5 ? "calc(100vh - 500px)" : undefined,
          }}
        />
      </>
    );
  };

  return (
    <div className="portfolioDash">
      <div className="workbench-table-container">
        <div className="dash-table-alignments">
          {renderTable(assetLevelInfo, tableTitles[1], true, tableTitles[1])}
          {renderTable(liabilitySideInfo, tableTitles[2], true, tableTitles[2])}
          {renderTable(collateralData, tableTitles[3], true, tableTitles[3])}
          {/* <label className="tableHeaders">{tableTitles[1]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(assetLevelInfo, true)} // Dynamically generated columns
            dataSource={assetLevelInfo}
            pagination={false}
            scroll={{
              x: "max-content",
              y: assetLevelInfo.length > 5 ? "calc(100vh - 500px)" : undefined,
            }}
          />
          <label className="tableHeaders">{tableTitles[2]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(liabilitySideInfo, true)} // Dynamically generated columns
            dataSource={liabilitySideInfo}
            pagination={false}
            scroll={{
              x: "max-content",
              y:
                liabilitySideInfo.length > 5
                  ? "calc(100vh - 500px)"
                  : undefined,
            }}
          />
          <label className="tableHeaders">{tableTitles[3]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(collateralData, true)} // Dynamically generated columns
            dataSource={collateralData}
            pagination={false}
            scroll={{
              x: "max-content",
              y: collateralData.length > 5 ? "calc(100vh - 500px)" : undefined,
            }}
          /> */}
          {/* <label className="tableHeaders">{tableTitles[4]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(Delinquency, true)} // Dynamically generated columns
            dataSource={Delinquency}
            pagination={false}
            scroll={{ x: "max-content" }}
          /> */}

          {/* <label className="tableHeaders">{tableTitles[1]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(DealAndLoanDetails, true)} // Dynamically generated columns
            dataSource={DealAndLoanDetails}
            // pagination={false}
            scroll={{ x: "max-content" }}
          /> */}
        </div>
      </div>
      <div className="chart-container-portfoilo">
        <div className="performance-table">
          {Array.isArray(Delinquency) && Delinquency.length > 0 ? (
            <div className="table-loans-wrapper">
              <table>
                <thead className="normal_table_headers">
                  <tr>
                    {/* Generate column headers dynamically, excluding 'key' */}
                    {Object.keys(Delinquency[0])
                      .filter((col) => col !== "key")
                      .map((col) => (
                        <th key={col}>{col}</th>
                      ))}
                  </tr>
                </thead>
                <tbody className="table-body-wrapper">
                  {/* Render rows based on the Delinquency data */}
                  {Delinquency.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {/* Render each cell in the row, excluding 'key' */}
                      {Object.keys(Delinquency[0])
                        .filter((col) => col !== "key")
                        .map((col) => {
                          const lowerCol = col.toLowerCase();

                          // Determine alignment based on column header name only
                          const textAlign = (() => {
                            if (lowerCol.includes("($)")) return "right"; // Dollar column
                            if (lowerCol.includes("count")) return "center"; // Count column
                            if (lowerCol.includes("%")) return "center"; // Percent column
                            return "left"; // Default alignment
                          })();

                          return (
                            <td
                              key={`${rowIndex}-${col}`}
                              style={{ textAlign }}
                            >
                              {row[col] !== undefined ? row[col] : ""}
                            </td>
                          );
                        })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>No data available</div>
          )}
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_DelinquenciesGraph.data}
            layout={plotData.PA_DelinquenciesGraph.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>

        <div
          className="plot-borders-bar-performance"
          ref={scatterPlotContainer}
        >
          <Plot
            data={plotData.PA_performing_nonPerforming_revamp.data}
            layout={plotData.PA_performing_nonPerforming_revamp.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>

        <div className="performance-table">
          {Array.isArray(cdr_cpr) && cdr_cpr.length > 0 ? (
            <div className="table-loans-wrapper">
              <table>
                <thead className="normal_table_headers">
                  <tr>
                    {/* Generate column headers dynamically, excluding 'key' */}
                    {Object.keys(cdr_cpr[0])
                      .filter((col) => col !== "key")
                      .map((col) => (
                        <th key={col}>{col}</th>
                      ))}
                  </tr>
                </thead>
                <tbody className="table-body-wrapper">
                  {/* Render rows based on the cdr_cpr data */}
                  {cdr_cpr.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {/* Render each cell in the row, excluding 'key' */}
                      {Object.keys(cdr_cpr[0])
                        .filter((col) => col !== "key")
                        .map((col) => {
                          const lowerCol = col.toLowerCase();

                          // Determine alignment based on column header name only
                          const textAlign = (() => {
                            if (lowerCol.includes("($)")) return "right"; // Dollar column
                            if (lowerCol.includes("count")) return "center"; // Count column
                            if (lowerCol.includes("%")) return "center"; // Percent column
                            return "left"; // Default alignment
                          })();

                          return (
                            <td
                              key={`${rowIndex}-${col}`}
                              style={{ textAlign }}
                            >
                              {row[col] !== undefined ? row[col] : ""}
                            </td>
                          );
                        })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>No data available</div>
          )}
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_CDR_CPR_TIME.data}
            layout={plotData.PA_CDR_CPR_TIME.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "zoom",
                "resetScale2d",
                // "toImage",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_principalBal_dealwise_revamp.data}
            layout={plotData.PA_principalBal_dealwise_revamp.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_originations_revamp.data}
            layout={plotData.PA_originations_revamp.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_wtAvgFico_revamp.data}
            layout={plotData.PA_wtAvgFico_revamp.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,

              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_LTV.data}
            layout={plotData.PA_LTV.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders-bar-collateral" ref={scatterPlotContainer}>
          <Plot
            data={plotData.PA_Collateral_Performance_temp_revamp.data}
            layout={plotData.PA_Collateral_Performance_temp_revamp.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders-bar-scatter" ref={scatterPlotContainer}>
          <Plot
            data={plotData.PA_revamp_state.data}
            layout={plotData.PA_revamp_state.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>
      </div>
    </div>
  );
}

export default PortfolioDash;
