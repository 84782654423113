import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSnackbar } from "notistack";
import Sidebar1 from "../../../../../components/sidebar/sidebar.js";
import { withSnackbar } from "notistack";
import {
  GetDataByPaymentDatesDeal,
  GetDataByDefaultValue,
  GetDataByUpdateAsset,
  GetDataByUpdateIssuer,
  GetDataByUpdateLoans,
  GetDataByUpdateLoansDetails,
  GetDataByTableChartDataTile,
} from "../../../../../servies/services.js";
import LinearLoader from "../../../../../components/loader/LinearLoader.js";
import DataDisplaySummary from "./DataDisplaySummary.js";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ChatAssistant from "./ChatAssistant.js";
import IDA from "../../../../../images/IDA.svg";
import BETA from "../../../../../images/BETA.svg";
import RedFlag from "../../../../../images/RedFlag.svg";
import ThumbUp from "../../../../../images/thumb_up_alt.svg";
import ThumbDown from "../../../../../images/thumb_down_alt.svg";
import Back_Icon from "../../../../../images/Back_Icon.svg";
import { useHistory } from "react-router-dom";
import {
  Table,
  Card,
  Row,
  Col,
  Spin,
  Input,
  Button,
  Dropdown,
  Menu,
} from "antd";
import "./DealAnalyticsLoans.css";
import LoanDetailsAnalytics from "./LoanDetailsAnalytics.js";
import { debounce } from "lodash";
import {
  CaretDownOutlined,
  FilterFilled,
  CaretUpOutlined,
} from "@ant-design/icons";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import fIlter from "../../../../../images/FilterMappedTape.svg";
import * as XLSX from "xlsx";
// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

// Function to get unique values for a column
const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};

const DealAnalyticsLoans = (props) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [getLoansLoader, setGetLoansLoader] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [startDate, setStartDate] = useState(new Date("2022-01-01"));
  const [endDate, setEndDate] = useState(new Date("2024-07-25"));
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [assetClassOptions, setAssetClassOptions] = useState([]);
  const [selectedAssetClass, setSelectedAssetClass] = useState([]);
  const [issuerOptions, setIssuerOptions] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState([]);
  const [dealsOptions, setDealsOptions] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [rowsSelected, setRowsSelected] = useState(null);
  const [investorTab1, setInvestorTab1] = useState(false);
  const [investorTab2, setInvestorTab2] = useState(false);
  const [investorTab3, setInvestorTab3] = useState(false);
  const [investorTab4, setInvestorTab4] = useState(false);
  const [investorTab5, setInvestorTab5] = useState(true);
  const [investorTab6, setInvestorTab6] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const history = useHistory();
  const [displayData, setDisplayData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [selectedDealID, setSelectedDealID] = useState(null); // State for selected deal ID
  const [dateOptions, setDateOptions] = useState([]);
  const [Deals, setDeals] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [sortOrder, setSortOrder] = useState({});
  const [filterCriteria, setFilterCriteria] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [theme, setTheme] = useState(sessionStorage.getItem("dark-theme"));
  const [filteredClrChangeColumns, setFilteredClrChangeColumns] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [currentBatch, setCurrentBatch] = useState(1);
  const itemsPerBatch = 45;
  const inputRef = useRef(null);

  useEffect(() => {
    const component = window.location.pathname;
    sessionStorage.setItem("component", component);
  }, []);

  const fetchDefaultData = useCallback(async () => {
    const userId = sessionStorage.getItem("user_id");
    const userName = sessionStorage.getItem("user_name");

    if (!userId || !userName) {
      console.error("User data is missing from sessionStorage");
      return;
    }

    const data = { UserId: userId, UserName: userName };
    setScreenLoader(true);
    setLoading(true);

    try {
      const APIResponse = await GetDataByDefaultValue(data);
      if (APIResponse.status === 200) {
        const { asset_class, issuer_group, deal_type, start_date, end_date } =
          APIResponse.data.result;

        const assetClassOptions = asset_class.map((option) => ({
          value: option,
          label: option,
        }));
        const dealsArray = deal_type || [];

        setAssetClassOptions(assetClassOptions);
        setSelectedAssetClass(assetClassOptions); // Store asset class for next API call
        setStartDate(start_date);
        setEndDate(end_date);
        setDeals(dealsArray);

        // Trigger the next API call
        fetchUpdatedAssetData(assetClassOptions); // Pass asset class options to fetchUpdatedAssetData
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUpdatedAssetData = useCallback(
    async (assetClass = selectedAssetClass) => {
      const userName = sessionStorage.getItem("user_name");
      const userId = sessionStorage.getItem("user_id");

      const data = {
        UserName: userName,
        UserId:userId,
        asset_class: assetClass.map((item) => item.value),
      };

      setGetLoansLoader(true);
      setLoading(true);

      try {
        const APIResponse = await GetDataByUpdateAsset(data);
        if (APIResponse.status === 200) {
          const issuerArray = APIResponse.data.result.issuer_group;
          const issuerOptions = issuerArray.map((option) => ({
            value: option,
            label: option,
          }));
          setIssuerOptions(issuerOptions);

          // Set selectedIssuer to the first option in issuerOptions if it's not already set
          const newSelectedIssuer =
            selectedIssuer.length > 0 ? selectedIssuer : issuerOptions;

          setSelectedIssuer(newSelectedIssuer);

          // Call fetchUpdatedIssuerData with newSelectedIssuer after setting it
          fetchUpdatedIssuerData(newSelectedIssuer);

          if (selectedAssetClass[0]?.value) {
            sessionStorage.setItem('AssetClass', selectedAssetClass[0].value);
          }
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error("Error updating asset data:", error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setGetLoansLoader(false);
        setLoading(false);
      }
    },
    [selectedAssetClass]
  );

  const fetchUpdatedIssuerData = useCallback(
    async (issuerGroup) => {
      // console.log({ issuerGroup });

      const userName = sessionStorage.getItem("user_name");
      const userId = sessionStorage.getItem("user_id");

      const data = {
        UserName: userName,
        UserId:userId,
        issuer_group: issuerGroup.map((item) => item.value),
      };

      setGetLoansLoader(true);
      setLoading(true);

      try {
        const APIResponse = await GetDataByUpdateIssuer(data);
        if (APIResponse.status === 200) {
          const dealsArray = APIResponse.data.result.deal_type;
          const dealsOptions = dealsArray.map((deal) => {
            const [key, value] = Object.entries(deal)[0];
            return { value: key, label: value };
          });

          // console.log({ dealsOptions });

          setDealsOptions(dealsOptions);
          // Set initial deal to either the first option or null if empty
          // const initialSelectedDeal =
          //   dealsOptions.length > 0 ? dealsOptions[0] : [];

          const initialSelectedDeal =
            sessionStorage.getItem("selectedPreviousDeal") === null // Check if selectedPreviousDeal is not set
              ? dealsOptions.length > 0
                ? dealsOptions[0]
                : null // Use the first deal if available
              : JSON.parse(sessionStorage.getItem("selectedPreviousDeal"))[0]; // Otherwise, use the value from sessionStoragenull;

          // console.log({ initialSelectedDeal, whatsHappens });

          setSelectedDeals(initialSelectedDeal ? [initialSelectedDeal] : null);
          setSelectedDealID(
            initialSelectedDeal ? initialSelectedDeal.value : null
          );
          // fetchUpdatedPaymentDates(selectedAssetClass, initialSelectedDeal);

          // Call fetchUpdatedLoansData even if initialSelectedDeal is null
          // fetchUpdatedLoansData(
          //   initialSelectedDeal ? initialSelectedDeal.value : null
          // );
          // Call fetchUpdatedLoansData when dealsOptions is empty
          if (dealsOptions.length === 0) {
            fetchUpdatedLoansData(null); // Call with null
          }
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error("Error updating issuer data:", error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setGetLoansLoader(false);
        setLoading(false);
      }
    },
    [] // No dependencies to avoid re-renders
  );
  const fetchUpdatedPaymentDates = useCallback(
    async (dealID = selectedDealID) => {
      // console.log({ selectedDealID }); // Log selected deal ID

      const data = {
        deal_id: dealID,
      };

      setGetLoansLoader(true);
      setLoading(true);

      try {
        const APIResponse = await GetDataByPaymentDatesDeal(data);
        if (APIResponse.status === 200) {
          const dateOptions =
            Array.isArray(APIResponse.data.payment_dates) &&
            APIResponse.data.payment_dates.length > 0
              ? APIResponse.data.payment_dates.map((date) => ({
                  value: date,
                  label: date,
                }))
              : [];

          // console.log(
          //   "dateOptions",
          //   dateOptions.length,
          //   APIResponse.data.payment_dates
          // );

          const startDateNeeded =
            dateOptions.length > 0
              ? dateOptions[dateOptions.length - 1].value
              : "";
          // console.log({ startDateNeeded });

          const endDateNeeded =
            dateOptions.length > 0 ? dateOptions[0].value : "";

          sessionStorage.setItem("startDateNeeded", startDateNeeded);
          sessionStorage.setItem("endDateNeeded", endDateNeeded);

          // Update state using functional component state hooks
          setStartDate(startDateNeeded);
          setEndDate(endDateNeeded);
          setDateOptions(dateOptions);
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error("Error fetching payment dates:", error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setGetLoansLoader(false);
        setLoading(false);
      }
    },
    [] // Add selectedDealID as a dependency
  );

  const fetchTableChartData = useCallback(async () => {
    const selectedDealsOriginal = (
      Array.isArray(selectedDeals) ? selectedDeals : []
    )
      .map((selectedDeal) => {
        if (!selectedDeal || !selectedDeal.value) return null;
        const originalDeal = Deals.find(
          (deal) => deal && Object.keys(deal)[0] === selectedDeal.value
        );
        return originalDeal || null;
      })
      .filter(Boolean);
      const dealValue = selectedDealsOriginal[0] ? Object.values(selectedDealsOriginal[0])[0] : '';
      sessionStorage.setItem('dealname', dealValue);
    // console.log({ startDate });

    const getStartDate = sessionStorage.getItem("startDateNeeded");
    const getEndDate = sessionStorage.getItem("endDateNeeded");

    const data = {
      asset_class: (Array.isArray(selectedAssetClass) ? selectedAssetClass : [])
        .map((item) => item && item.value)
        .filter(Boolean),
      issuer_group: (Array.isArray(selectedIssuer) ? selectedIssuer : [])
        .map((item) => item && item.value)
        .filter(Boolean),
      deal_type: selectedDealsOriginal,
      start_date: getStartDate,
      end_date: getEndDate,
    };

    setScreenLoader(true);
    setLoading(true);
    setGetLoansLoader(true);

    try {
      const APIResponse = await GetDataByTableChartDataTile(data);

      if (APIResponse.status === 200) {
        setGetLoansLoader(false);
        setScreenLoader(false);
        setIsDataLoaded(true);
        setSelectedLoanId(null);
        setHiddenColumns(!hiddenColumns);

        // Debugging log
        // console.log("Fetched Data:", APIResponse.data.result.table);

        // Clear existing session data (to ensure update)
        // sessionStorage.removeItem("finalDataSummary");
        sessionStorage.removeItem("finalDataTableSummary");

        // Store updated data in session storage
        // sessionStorage.setItem(
        //   "finalDataSummary",
        //   JSON.stringify(APIResponse.data.result)
        // );
        const isSecuritisation =
          sessionStorage.getItem("isSecuritisation") === "Securitisation";

        const finalData = isSecuritisation
          ? APIResponse.data.table
          : APIResponse.data.Credit_Tile;

        sessionStorage.setItem(
          "finalDataTableSummary",
          JSON.stringify(finalData)
        );

        // console.log(
        //   "Updated sessionStorage - finalDataTableSummary:",
        //   sessionStorage.getItem("finalDataTableSummary")
        // );
      } else {
        setGetLoansLoader(false);
        setLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setGetLoansLoader(false);
      setLoading(false);
      enqueueSnackbar("An error occurred while fetching data", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [
    selectedDeals,
    Deals,
    selectedAssetClass,
    selectedIssuer,
    startDate,
    endDate,
  ]);
  const fetchUpdatedLoansData = useCallback(
    async (dealID = selectedDealID) => {
      const data = { "Deal ID": dealID };

      setGetLoansLoader(true);
      setLoading(true);

      try {
        const APIResponse = await GetDataByUpdateLoans(data);

        if (APIResponse.status === 200) {
          const LoansData = APIResponse.data;
          // console.log({ LoansData });

          setTableData(LoansData);
          setDisplayData(LoansData.slice(0, itemsPerBatch));
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error("Error updating loans data:", error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setGetLoansLoader(false);
        setLoading(false);
        setScreenLoader(false);
      }
    },
    [] // No dependencies
  );

  // useEffect to watch for changes to selectedDealID and call fetchUpdatedLoansData
  useEffect(() => {
    const fetchData = async () => {
      if (selectedDealID) {
        try {
          await fetchUpdatedPaymentDates(selectedDealID); // Call payment dates function
          await fetchTableChartData(); // Call table chart data function
          await fetchUpdatedLoansData(selectedDealID); // Call loans data function
        } catch (error) {
          console.error("Error in fetching data:", error);
          // Optionally handle errors or show notifications here
        }
      }
    };

    fetchData();
  }, [selectedDealID]);

  // On initial load, call the default data function to start the sequence
  useEffect(() => {
    fetchDefaultData();
  }, [fetchDefaultData]);

  useEffect(() => {
    const checkTheme = () => {
      const darkTheme = sessionStorage.getItem("dark-theme");
      if (darkTheme !== theme) {
        // Only update theme if it changes
        setTheme(darkTheme);
      }
    };

    window.addEventListener("storage", checkTheme);
    const interval = setInterval(checkTheme, 500); // Check every 500ms instead of 10ms

    return () => {
      window.removeEventListener("storage", checkTheme);
      fetchUpdatedLoansDeatilsData();
      clearInterval(interval);
    };
  }, [theme]); // Add theme as a dependency to prevent stale values

  const fetchUpdatedLoansDeatilsData = useCallback(async () => {
    const dealID = selectedDealID;
    const selectedLoan = selectedLoanId;
    const data = { "Deal ID": dealID, "Loan ID": selectedLoan };

    setGetLoansLoader(true);
    setLoading(true);

    try {
      const APIResponse = await GetDataByUpdateLoansDetails(data);
      // console.log({ APIResponse });

      if (APIResponse.status === 200) {
        const LoanDetilsData = APIResponse.data;
        // console.log({ LoanDetilsData });
        setGraphData(LoanDetilsData);
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error("Error updating issuer data:", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setGetLoansLoader(false);
      setLoading(false);
      setScreenLoader(false);
    }
  }, [selectedLoanId]);

  const handleLoanClick = (loanId) => {
    // Check if the clicked Loan ID is the same as the selected one
    // console.log({ loanId, selectedLoanId });

    if (loanId === selectedLoanId) {
      // If same ID is clicked, toggle hiddenColumns and keep selectedLoanId
      setHiddenColumns(false);
      setSelectedLoanId(null);
    } else {
      // Update with a new selection and show all columns
      setSelectedLoanId(loanId);
      setHiddenColumns(true);
    }
  };

  // Assuming tableData is the data used to generate columns
  useEffect(() => {
    // Generate columns only once based on initial data or when the selectedLoanId changes
    setColumns(generateColumns(tableData, selectedLoanId));
  }, [tableData, selectedLoanId]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      setLoading(true);
      const dataToFilter = term
        ? tableData.filter((record) =>
            Object.values(record).some((value) =>
              value.toString().toLowerCase().includes(term)
            )
          )
        : tableData;

      setFilteredDatas(dataToFilter);
      setDisplayData(dataToFilter.slice(0, itemsPerBatch));
      setLoading(false);
    }, 300),
    [tableData]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const clearSearch = () => {
    setSearchTerm(""); // Clear the search term
    if (inputRef.current) {
      inputRef.current.focus(); // Re-focus on the input after clearing
    }
  };

  const generateColumns = (data, selectedLoanId) => {
    const record = data[0] || {}; // Use first data record as a sample for keys

    // Define the specific columns you want to display
    const requiredColumns = [
      "Loan ID",
      "Loan Status",
      "Current Principal Balance",
      "Current Interest Rate",
      "Maturity Date",
      "Purpose",
    ];

    return Object.keys(record)
      .filter((key) => requiredColumns.includes(key))
      .map((key) => ({
        title: key, // Optionally format the title
        dataIndex: key,
        key,
        width: 150,
        sortOrder: sortOrder[key],
        // sorter: (a, b) => {
        //   const valueA = a[key];
        //   const valueB = b[key];
        //   // Custom sorting logic based on data type
        //   return isNaN(valueA) || isNaN(valueB)
        //     ? valueA.localeCompare(valueB) // Text sorting
        //     : parseFloat(valueA) - parseFloat(valueB); // Numeric sorting
        // },
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey={key}
            onSort={handleSort}
            onFilter={handleFilter}
            columnValues={getUniqueValues(data, key)}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: filteredClrChangeColumns[key] ? "#018e82" : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (text, record) => {
          if (key === "Loan ID") {
            // console.log(
            //   "Selected Loan ID:",
            //   selectedLoanId,
            //   "Record Loan ID:",
            //   record["Loan ID"]
            // );
            return (
              <span
                onClick={() => handleLoanClick(record["Loan ID"])}
                style={{
                  color: "#018E82",
                  cursor: "pointer",
                  textDecoration:
                    selectedLoanId === record["Loan ID"] ? "underline" : "none",
                }}
              >
                {text}
              </span>
            );
          } else if (key === "Debt to Income Ratio (DIR)") {
            return (
              <span>
                <img
                  src={RedFlag}
                  alt="Red Flag"
                  style={{ marginRight: "4px", width: "16px" }}
                />
                {text}
              </span>
            );
          } else if (key === "Revolving Line Utilization Rate") {
            return (
              <span>
                <img
                  src={record.key === "1" ? ThumbUp : ThumbDown}
                  alt={record.key === "1" ? "Thumb Up" : "Thumb Down"}
                  style={{ width: "16px", marginRight: "4px" }}
                />
                {text}
              </span>
            );
          }
          return text; // Default render for other columns
        },
        ...(key === "Loan ID" && { fixed: "left" }), // Make "Loan ID" column fixed
      }));
  };

  // Track selectedLoanId changes
  useEffect(() => {
    if (selectedLoanId) {
      // console.log("Selected Loan ID has changed:", selectedLoanId);
      setSelectedLoanId(selectedLoanId);
      fetchUpdatedLoansDeatilsData();
    }
  }, [selectedLoanId]);

  // Scroll event handler
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth } =
      event.target;

    // Only proceed if we are at the bottom of the vertical scroll
    const isVerticalScrollBottom = scrollTop + clientHeight >= scrollHeight - 5;
    const isAtHorizontalStart = scrollLeft === 0; // Optional: Use this if you want to avoid loading on horizontal scrolls

    if (isVerticalScrollBottom && !loading && isAtHorizontalStart) {
      loadMoreData();
    }
  };

  // Load next batch of data
  const loadMoreData = () => {
    if (loading || displayData.length >= filteredDatas.length) return; // Stop if all data loaded
    const nextBatch = currentBatch + 1;
    setCurrentBatch(nextBatch);
    setLoading(true); // Start loading
    setDisplayData((prevData) => [
      ...prevData,
      ...filteredDatas.slice(prevData.length, prevData.length + itemsPerBatch),
    ]);
    setLoading(false); // End loading
  };

  // const columns = generateColumns(tableData);

  const filteredColumns = columns.filter((col) => {
    if (col.dataIndex === "Loan ID") {
      return true; // Always show "Loan ID" column
    }
    if (hiddenColumns && selectedLoanId) {
      return false; // Hide all other columns when hiddenColumns is true
    }
    return true;
  });

  const handleFilter = (tableName, data, columnKey, criteria) => {
    const newCriteria = { ...filterCriteria, [columnKey]: criteria };
    setFilterCriteria(newCriteria);

    // Check if all criteria are empty
    const isCriteriaEmpty = Object.values(newCriteria).every(
      (criteria) => !criteria || !criteria.value1
    );

    if (isCriteriaEmpty) {
      // Reset to the first batch of the full dataset if no criteria are selected
      setDisplayData([...tableData.slice(0, currentBatch * itemsPerBatch)]);
      setFilteredClrChangeColumns((prev) => ({
        ...prev,
        [columnKey]: false,
      }));
      return;
    }

    // Apply filters
    const filtered = tableData.filter((item) => {
      return Object.keys(newCriteria).every((key) => {
        const criteria = newCriteria[key];
        if (!criteria) return true;

        const { condition1, value1, operator, condition2, value2 } = criteria;

        const applyCondition = (value, condition, target) => {
          switch (condition) {
            case "equals":
              return value === target;
            case "less_than":
              return value < target;
            case "greater_than":
              return value > target;
            case "not_equals":
              return value !== target;
            default:
              return false;
          }
        };

        const condition1Met = applyCondition(item[key], condition1, value1);
        const condition2Met = applyCondition(item[key], condition2, value2);

        return operator === "AND"
          ? condition1Met && condition2Met
          : condition1Met || condition2Met;
      });
    });

    // Apply sorting after filtering
    const sortedFiltered = [...filtered].sort((a, b) => {
      const valueA = a[sortOrder.columnKey];
      const valueB = b[sortOrder.columnKey];

      if (!isNaN(valueA) && !isNaN(valueB)) {
        return sortOrder.order === "ascend" ? valueA - valueB : valueB - valueA;
      }

      if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
        return sortOrder.order === "ascend"
          ? new Date(valueA) - new Date(valueB)
          : new Date(valueB) - new Date(valueA);
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder.order === "ascend"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0;
    });

    // Update display data to only show the current batch
    setDisplayData(sortedFiltered.slice(0, currentBatch * itemsPerBatch));
    setFilteredClrChangeColumns((prev) => ({
      ...prev,
      [columnKey]: !!criteria,
    }));
  };

  const handleSort = (tableName, columnKey, order) => {
    // Determine the chunk size based on the total dataset size
    const chunkSize = tableData.length; // Max 1000 rows or 10% of the dataset
    const sortedData = [];

    const sortChunk = (start) => {
      const end = Math.min(start + chunkSize, tableData.length);
      const chunk = tableData.slice(start, end).sort((a, b) => {
        const valueA = a[columnKey];
        const valueB = b[columnKey];

        // Handle numeric values
        if (!isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB))) {
          return order === "ascend"
            ? parseFloat(valueA) - parseFloat(valueB)
            : parseFloat(valueB) - parseFloat(valueA);
        }

        // Handle date values
        if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
          return order === "ascend"
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
        }

        // Handle string values
        if (typeof valueA === "string" && typeof valueB === "string") {
          return order === "ascend"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        // Handle null or undefined values
        if (valueA == null || valueB == null) {
          return valueA == null ? 1 : -1; // Nulls/undefined are treated as greater
        }

        return 0; // Fallback for other types
      });

      sortedData.push(...chunk);

      if (end < tableData.length) {
        setTimeout(() => sortChunk(end), 0); // Process the next chunk
      } else {
        // Update state after all chunks are sorted
        setTableData(sortedData);
        setDisplayData(sortedData.slice(0, itemsPerBatch));
      }
    };

    sortChunk(0);
  };

  const handleBack = () => {
    setSelectedLoanId(null);
    setHiddenColumns(!hiddenColumns);
  };

  const renderCards = () => {
    const loanData = tableData.find(
      (loan) => loan["Loan ID"] === selectedLoanId
    );

    if (!loanData) return null;

    return (
      <div>
        <LoanDetailsAnalytics
          loanData={loanData}
          handleBack={handleBack}
          {...(graphData && { graphData })}
        />
      </div>
    );
  };

  const blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const handleClickSummary = () => {
    setInvestorTab1(true);
    setInvestorTab2(false);
    setInvestorTab3(false);
    setInvestorTab4(false);
    setInvestorTab5(false);
    setInvestorTab6(false);
    history.push("/investor/summaryAnalytics");
  };
  const handleClickFunding = () => {
    setInvestorTab1(true);
    setInvestorTab2(false);
    setInvestorTab3(false);
    setInvestorTab4(false);
    setInvestorTab5(false);
    setInvestorTab6(false);
    history.push("/investor/fundingAnalytics");
  };
  const handleClickPayments = () => {
    setInvestorTab1(false);
    setInvestorTab2(true);
    setInvestorTab3(false);
    setInvestorTab4(false);
    setInvestorTab5(false);
    setInvestorTab6(false);
    history.push("/investor/paymentAnalytics");
  };

  const handleClickStrats = () => {
    setInvestorTab1(false);
    setInvestorTab2(false);
    setInvestorTab3(true);
    setInvestorTab4(false);
    setInvestorTab5(false);
    setInvestorTab6(false);
    history.push("/investor/stratsAnalytics");
  };

  const handleClickPerformance = () => {
    setInvestorTab1(false);
    setInvestorTab2(false);
    setInvestorTab3(false);
    setInvestorTab4(true);
    setInvestorTab5(false);
    setInvestorTab6(false);
    history.push("/investor/performanceAnalytics");
  };

  const handleClickLoans = () => {
    setInvestorTab1(false);
    setInvestorTab2(false);
    setInvestorTab3(false);
    setInvestorTab4(false);
    setInvestorTab5(true);
    setInvestorTab6(false);
  };
  const handleClickQC = () => {
    setInvestorTab1(false);
    setInvestorTab2(false);
    setInvestorTab3(false);
    setInvestorTab4(false);
    setInvestorTab5(false);
    setInvestorTab6(true);
    history.push("/investor/QCAnalytics");
  };

  const handleSelectChange = (selectedOption) => {
    // Log the new value
    console.log("Updating selectedAssetClass:", selectedOption);
    setScreenLoader(true);

    const updatedSelectedAssetClass = selectedOption ? [selectedOption] : [];
    setSelectedAssetClass(updatedSelectedAssetClass);
    fetchUpdatedAssetData(updatedSelectedAssetClass);

    sessionStorage.setItem("AssetClass", selectedOption.label);
  };

  const handleIssuerChange = (selectedOptions) => {
    setSelectedIssuer(selectedOptions);
    setScreenLoader(true);
    // Call fetchUpdatedIssuerData immediately after setting selectedIssuer
    fetchUpdatedIssuerData(selectedOptions);
  };

  const handleDealsChange = (selectedOptions) => {
    // Add this line
    sessionStorage.setItem("dealname", selectedOptions.label);
    
    sessionStorage.setItem(
        "selectedPreviousDeal",
        JSON.stringify([selectedOptions])
    );

    setSelectedDeals([selectedOptions]); // Update selected deal
    setSelectedDealID(selectedOptions.value); // Update selected deal ID based on selection
    setScreenLoader(true);
    setIsDataLoaded(false);

    // Trigger additional actions if necessary, e.g., fetching data based on the new selected deal
    // setTimeout(async () => {
    //   await GetDataByTableChartDataTile(selectedOption.value);
    // }, 1000); // Delay of 1 second (optional)
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "200px", // Set the fixed width of the input box
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid var(--tableBorder)",
      borderRadius: "8px",
      minHeight: "38px",
      boxShadow: "none",
      color: "var(--text_Color)",
      backgroundColor: "var(--dropdown-bg)",
      '&:hover': {
        border: "1px solid var(--tableBorder)", // Keep the same border on hover
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#adaeaa",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--text_Color)",
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto", // Dynamically set the width of the dropdown
      minWidth: "200px", // Ensure it doesn't become smaller than the input
      zIndex: 9999, // Ensure the dropdown appears above other elements
      backgroundColor: "var(--dropdown-bg)", // Background color for dropdown
      color: "var(--text_Color)", // Text color for dropdown
    }),
    option: (provided, state) => ({
      ...provided,
      whiteSpace: "nowrap", // Prevent text from wrapping in the dropdown
      backgroundColor: state.isSelected
        ? "var(--dropdown-selected-bg)" // Background for the selected option
        : state.isFocused
        ? "var(--dropdown-hover-bg)" // Background for hovered or focused option
        : "var(--dropdown-bg)", // Default background color
      color: "var(--text_Color)", // Text color for options
      cursor: "pointer",
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the vertical separator line
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#018e82", // Set the color of the dropdown arrow
      "&:hover": {
        color: "#018e82", // Maintain the same color on hover
      },
    }),
    clearIndicator: () => ({
      display: "none", // Completely hide the clear (X) icon
    }),
  };

  const loanIds = displayData.map((record) => record["Loan ID"]);
  // const duplicates = loanIds.filter(
  //   (id, index) => loanIds.indexOf(id) !== index
  // );
  // console.log("Duplicate Loan IDs:", duplicates);

  const exportDynamicExcel = async (e) => {
    e.domEvent.stopPropagation();

    setLoadingExcel(true); // Set loading state before anything else
    try {
      if (!tableData || tableData.length === 0) {
        enqueueSnackbar("No data available to export", { variant: "warning" });
        return;
      }

      // Simulate slight delay to let spinner render
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Convert tableData to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Create a workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Loans");

      // Trigger the Excel file download
      XLSX.writeFile(workbook, "Loans.xlsx");
      // enqueueSnackbar("Excel file downloaded successfully!", {
      //   variant: "success",
      // });
    } catch (error) {
      console.error("Error exporting Excel:", error);
      enqueueSnackbar("Error exporting Excel file", { variant: "error" });
    } finally {
      setLoadingExcel(false); // Ensure loading is reset
    }
  };
  const exportDynamicCSV = async (e) => {
    e.domEvent.stopPropagation();
    setLoadingCSV(true);
    try {
      if (!tableData || tableData.length === 0) {
        enqueueSnackbar("No data available to export", { variant: "warning" });
        return;
      }

      // Simulate slight delay to let spinner render
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Convert tableData to CSV format
      const csvContent = [
        Object.keys(tableData[0]).join(","), // Header row
        ...tableData.map((row) =>
          Object.values(row)
            .map((value) => `"${value}"`)
            .join(",")
        ),
      ].join("\n");

      // Create a Blob object and trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "Loans.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting CSV:", error);
      enqueueSnackbar("Error exporting CSV file", { variant: "error" });
    } finally {
      setLoadingCSV(false);
    }
  };
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={exportDynamicExcel}>
        Excel {loadingExcel && <Spin size="small" style={{ marginLeft: 2 }} />}
      </Menu.Item>
      <hr className="custom-inv-ddHr" />
      <Menu.Item key="2" onClick={exportDynamicCSV}>
        CSV {loadingCSV && <Spin size="small" style={{ marginLeft: 2 }} />}
      </Menu.Item>
    </Menu>
  );
  const isSecuritisation =
    sessionStorage.getItem("isSecuritisation") === "Securitisation";
  return (
    <React.Fragment>
      <div className="">
        <Sidebar1 activeComponent={"Deal"} />
        <div className="content-bdb">
          <div className="header">{/* <Header></Header> */}</div>
          {screenLoader ? (
            <LinearLoader />
          ) : (
            <>
              <div className="page-contentofpool1">
                <div className="align-fixed-content-loans">
                  <div className="row1">
                    <div
                      className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "90rem",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {!isSecuritisation ? (
                          <h3 className="headerdashboard-analytics">
                            FACILITY
                          </h3>
                        ) : (
                          <h3 className="headerdashboard-analytics">DEAL</h3>
                        )}{" "}
                      </div>
                    </div>
                  </div>

                  <div className="FixTails">
                    <div className="dropdown-row">
                      <div className="dropdown-container">
                        <label className="dropdown-label">Asset Class:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={assetClassOptions}
                            placeholder="Asset Class"
                            value={selectedAssetClass}
                            onChange={handleSelectChange}
                            components={{ DropdownIndicator }}
                            styles={customStyles}
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Issuer Group:</label>
                        <div className="dropdown-content-topBar">
                          <MultiSelect
                            options={issuerOptions}
                            placeholder="Issuer"
                            value={selectedIssuer}
                            onChange={handleIssuerChange}
                            valueRenderer={customValueRenderer}
                            labelledBy="Select"
                            className="custom-multiselect multiselect-width"
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Deals:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={dealsOptions}
                            placeholder="Deal"
                            value={selectedDeals}
                            onChange={handleDealsChange}
                            components={{ DropdownIndicator }}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <DataDisplaySummary />

                  <div className="SummaryStds">
                    <div className="col-5 col-sm-5 col-md-5 hellocard">
                      {!isSecuritisation ? (
                        <button
                          type="button"
                          onClick={handleClickFunding}
                          className={
                            investorTab1 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Funding
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={handleClickSummary}
                          className={
                            investorTab1 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Summary
                        </button>
                      )}
                      {!isSecuritisation ? (
                        <button
                          type="button"
                          onClick={handleClickPayments}
                          className={
                            investorTab2
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Payments
                        </button>
                      ) : null}
                      <button
                        type="button"
                        onClick={handleClickStrats}
                        className={
                          investorTab3
                            ? "issuerDashboard-table-top-button-insights-active-analytics"
                            : "issuerDashboard-table-top-button-insights-analytics"
                        }
                      >
                        Strats
                      </button>
                      <button
                        type="button"
                        onClick={handleClickPerformance}
                        className={
                          investorTab4
                            ? "issuerDashboard-table-top-button-insights-active-analytics"
                            : "issuerDashboard-table-top-button-insights-analytics"
                        }
                      >
                        Performance
                      </button>
                      <button
                        type="button"
                        onClick={handleClickLoans}
                        className={
                          investorTab5
                            ? "issuerDashboard-table-top-button-insights-active-analytics"
                            : "issuerDashboard-table-top-button-insights-analytics"
                        }
                      >
                        Loans
                      </button>
                      {!isSecuritisation ? (
                        <button
                          type="button"
                          onClick={handleClickQC}
                          className={
                            investorTab6
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          QC
                        </button>
                      ) : null}
                    </div>
                    {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      width: "300px",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={exportDynamicExcel}
                      style={{
                        backgroundColor: "#194d40",
                        width: 150,
                        marginLeft: "10px",
                      }}
                    >
                      Download Excel
                      {loadingExcel && (
                        <Spin
                          size="small"
                          // style={{ marginLeft: "8px" }} // Ensure space between text and spinner
                        />
                      )}
                    </Button>
                    <Button
                      type="primary"
                      onClick={exportDynamicCSV}
                      style={{
                        backgroundColor: "#194d40",
                        width: 150,
                        marginLeft: "10px",
                      }}
                    >
                      Download CSV
                      {loadingCSV && (
                        <Spin
                          size="small"
                          // style={{ marginLeft: "8px" }} // Ensure space between text and spinner
                        />
                      )}
                    </Button>
                  </div> */}

                    <div className="IDA_Virtual">
                      {/* <Input
                      ref={inputRef}
                      placeholder="Search Loans..."
                      value={searchTerm}
                      onChange={handleSearch}
                      className="custom-input-loans"
                      style={{
                        marginBottom: 16,
                        marginLeft: 30,
                        width: 350,
                        border: "1px solid #000",
                      }}
                      // suffix={
                      //   searchTerm && (
                      //     <CloseOutlined
                      //       onClick={clearSearch}
                      //       style={{ cursor: "pointer", color: "#aaa" }}
                      //     />
                      //   )
                      // }
                    /> */}

                      <div className="IDA_Icons">
                        <img
                          src={IDA}
                          alt="VirtualAsst"
                          style={{ height: "38px", width: "38px" }}
                        />
                        <img
                          src={BETA}
                          alt="VirtualAsst"
                          className="beta"
                          style={{ height: "18px", width: "38px" }}
                        />
                      </div>
                      <ChatAssistant />
                    </div>
                  </div>
                </div>

                <div className="scrollable-content-loans">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      position: "relative",
                      bottom: "0rem",
                      right: "0.1rem",
                    }}
                  >
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      visible={dropdownVisible}
                      onVisibleChange={(visible) => setDropdownVisible(visible)}
                      overlayClassName="custom-inv-ddDownload"
                    >
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: "#ffc000",
                          width: 120,
                          color: "#000",
                        }}
                      >
                        <span>Download</span>
                        {dropdownVisible ? (
                          <CaretUpOutlined
                            style={{
                              color: "#000",
                            }}
                          />
                        ) : (
                          <CaretDownOutlined
                            style={{
                              color: "#000",
                            }}
                          />
                        )}
                      </Button>
                    </Dropdown>
                  </div>
                  <div
                    className="loan-table-alignments"
                    // style={{ flex: "0 0 200px" }}
                    // style={{ height: "400px", overflow: "auto" }}
                  >
                    <Table
                      columns={filteredColumns}
                      dataSource={displayData}
                      onScroll={handleScroll}
                      loading={loading}
                      pagination={false} // Disable pagination for now
                      rowKey={(record, index) =>
                        record["Loan ID"] || `${index}`
                      }
                      className={`performance-table ${
                        selectedLoanId ? "selected-loan-table" : ""
                      }`}
                      scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
                      style={{
                        flexGrow: selectedLoanId ? 0.1 : 1, // Conditionally apply flex-grow
                        borderTopRightRadius: selectedLoanId ? "0px" : "10px",
                        borderBottomRightRadius: selectedLoanId
                          ? "0px"
                          : "10px",
                        height: selectedLoanId ? "630px" : "",
                        overflowY: selectedLoanId ? "visible" : "",
                        scrollbarWidth: "thin", // For Firefox
                        scrollbarColor: "#888 #f1f1f1",
                      }}
                    />

                    {hiddenColumns && selectedLoanId && (
                      <div style={{ flex: 1 }} className="loans-cards">
                        {renderCards()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(DealAnalyticsLoans);
