import React, { createContext, useState } from "react";
import { useSnackbar } from "notistack";

export const Context = createContext();

const ContextProvider = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [input, setInput] = useState("");
  const [recentPrompt, setRecentPrompt] = useState("");
  const [recentDocumentPrompt, setRecentDocumentPrompt] = useState("");
  const [prevPrompts, setPrevPrompts] = useState([]);
  const [prevDocumentPrompts, setPrevDocumentPrompts] = useState([]);
  const [allPrompts, setAllPrompts] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState("");
  const [resultData, setResultData] = useState("");
  const [currentPromptIndex, setCurrentPromptIndex] = useState(-1);
  const [currentDocumentPromptIndex, setCurrentDocumentPromptIndex] = useState(-1);
  const [showPrevPrompts, setShowPrevPrompts] = useState(false);
  const [showPrevDocumentPrompts, setShowPrevDocumentPrompts] = useState(false);
  const [displayedPrompts, setDisplayedPrompts] = useState([]);
  const [displayedDocumentPrompts, setDisplayedDocumentPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [selectedDocumentPrompt, setSelectedDocumentPrompt] = useState(null);
  const [isModalFeedbackVisible, setIsModalFeedbackVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [docid, setDocid] = useState("");

  const showModal = () => {
    setIsModalFeedbackVisible(true);
  };

  const handleCancelFeedback = () => {
    setIsModalFeedbackVisible(false);
  };

  // Function to handle clicking the save button
  const handleSaveClick = () => {
    if (recentPrompt.trim() !== "") {
      if (!displayedPrompts.includes(recentPrompt.trim())) {
        setDisplayedPrompts((prev) => [...prev, recentPrompt.trim()]);
        setSelectedPrompt(recentPrompt);
        setShowPrevPrompts(true);
      }
    }
    if (recentDocumentPrompt.trim() !== "") {
      if (!displayedDocumentPrompts.includes(recentDocumentPrompt.trim())) {
        setDisplayedDocumentPrompts((prev) => [...prev, recentDocumentPrompt.trim()]);
        setSelectedDocumentPrompt(recentDocumentPrompt);
        setShowPrevDocumentPrompts(true);
      }
    }
  };

  const loadPrompt = async (prompt, index) => {
    console.log({ prompt });
    
    setRecentPrompt(prompt);
    setRecentDocumentPrompt(prompt);
    setSelectedPrompt(prompt);
    setSelectedDocumentPrompt(prompt);
    setCurrentPromptIndex(index);
    setCurrentDocumentPromptIndex(index);
  };

  const contextValue = {
    prevPrompts,
    prevDocumentPrompts,
    setPrevDocumentPrompts,
    setPrevPrompts,
    setRecentPrompt,
    setRecentDocumentPrompt,
    setCurrentPromptIndex,
    setCurrentDocumentPromptIndex,
    currentPromptIndex,
    currentDocumentPromptIndex,
    recentPrompt,
    recentDocumentPrompt,
    showResult,
    loading,
    resultData,
    input,
    showPrevPrompts,
    showPrevDocumentPrompts,
    selectedPrompt,
    isModalFeedbackVisible,
    comment,
    setComment,
    handleCancelFeedback,
    showModal,
    setIsModalFeedbackVisible,
    setSelectedPrompt,
    setSelectedDocumentPrompt,
    setInput,
    setShowResult,
    handleSaveClick,
    setDocid,
    displayedPrompts,
    displayedDocumentPrompts,
    selectedDocumentPrompt,
    setDisplayedPrompts,
    setDisplayedDocumentPrompts,
    loadPrompt,
    setLoading,
    setResultData,
    docid
  };

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};

export default ContextProvider;
