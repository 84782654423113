/* eslint-disable require-jsdoc */
import React, { Component, createRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactModal from "react-modal";
import { customStylesauto } from "../../../../../components/customscripts/customscript";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Popover from "react-bootstrap/Popover";
import {
  DealRecurring,
  editmanualinputRecurring,
  CalculatePayments,
  CalculatePrePayments,
  CalculateCollateralPayments,
  CalculateConsolidatedPayments,
  CalculateESMAPayments,
  getAllVersionsByPaymentDate,
  getAllPaymentDatesByDealName,
} from "../../../../../servies/services";
import NumberComp from "../../../../../components/NumberComp";
import NumberComp2 from "../../../../../components/NumberComp2";
import NumberComp4 from "../../../../../components/NumberComp4";
import NumberComp3 from "../../../../../components/NumberComp3";
import { Table } from "antd";
import * as moment from "moment";
import BeanEater from "../../../../../images/BeanEater.gif";
import { customStylesautosmallmodalpopupBorrow } from "../../../../../components/customscripts/customscript";
import { debounce } from "lodash";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import { Pagination } from "antd"; // Import AntD Pagination
import fIlter from "../../../../../images/FilterMappedTape.svg";

// Function to get unique values for a column
const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};

class Expenses_Recurring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Version: sessionStorage.getItem("version"),
      isSecuritisation: sessionStorage.getItem("isSecuritisation"),
      TableName: "ManualInputs",
      downArrow: false,
      peers: JSON.parse(sessionStorage.getItem("peers")),
      peer: sessionStorage.getItem("peer"),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      VersionsChange: [],
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      reloadComponent: false,
      formLoader: false,
      formLoaderCalulate: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      isBorrow: false,
      BorrowingBase: "False",
      rowsSelected: null,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      screenloader: false,
      formData1: {
        Name: "",
        Value: "",
        "Data Type": "",
      },
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: true,
      filterCriteria: {}, // Stores filter conditions
      sortOrder: {}, // Stores sort column and order
      displayData: [], // Filtered and sorted data for display
      currentBatch: 1, // Current batch of data to display
      itemsPerBatch: 50, // Number of items per batch
      currentPage: 1, // Current page
      pageSize: 10, // Number of rows per page
      filteredClrChangeColumns: {},
      addswitchtoggle: false,
      openModalPayment: false,
      Data_Type: "",
    };
    this.buttonRef = createRef();
    this.handleButtonClick = debounce(this.handleButtonClick.bind(this), 100);
  }

  showPrev = () => {
    this.props.history.push("/admin/expenses_recurring");
  };
  showNext = () => {
    this.props.history.push("/admin/accounts_recurring");
  };

  popoverBottom = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={() => this.Expenses(this.state.DealName)}>Deal</button>
        <hr className="servicer-popover-hr" />
        <button
          onClick={() =>
            this.Loans(this.state.DealName, this.state?.NextPaymentDate)
          }
        >
          Loan Tape
        </button>
        {/* <hr className="servicer-popover-hr" />
        <button>Change Period</button> */}
      </Popover>
    );
  };
  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };
  handleClickGeneral = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/general_recurring");
  };

  handleClickTranches = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/tranches_recurring");
  };
  handleClickFees = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/fees_recurring");
  };
  handleClickExpenses = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/expenses_recurring");
  };
  handleClickAccounts = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/accounts_recurring");
  };

  handleClickTests = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/tests_recurring");
  };
  handleBorrowingBase = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: true,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/borrowingbase_recurring");
  };
  handleClickVariables = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: true,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/variable_recurring");
  };
  handleClickPaymentRules = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: true,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/payment_recurring");
  };

  handleClickCollateral = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: true,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/collateral_recurring");
  };

  handleClickLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: true,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/loantape_recurring");
  };

  handleClickConsolidated = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: true,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/consolidated_recurring");
  };

  handleClickFiles = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: true,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.props.history.push("/admin/files_recurring");
  };
  handleClickManualInputs = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
      activeInsights10: false,
      activeInsights11: false,
      activeInsights12: false,
      activeInsightsBB: false,
      activeInsightsMI: true,
    });
    this.props.history.push("/admin/manualinput_recurring");
  };

  handleRowClick = (record) => {
    console.log("row data: ", record["Data Type"]);
    console.log(this.state.formData1);

    this.setState({
      Data_Type: record["Data Type"], // Access the Data Type field directly from the record
      open3: true, // Assuming this opens a modal or triggers a specific action
      selectedRow: record, // Save the entire record for future reference
      formData1: {
        Name: record["Name"], // Map the record fields to form data
        Value: record["Value"],
        "Data Type": record["Data Type"],
      },
      showModal: true, // Trigger the modal to open
    });
  };

  handleChange(event) {
    // Do something with the event, for example, update the state
    this.setState({
      formData1: {
        ...this.state.formData1,
        Value: event.target.value,
      },
    });
  }

  onCloseModal2 = () => {
    this.setState({ open3: false });
  };
  onCloseModal3 = () => {
    this.setState({ open4: false });
  };

  onCloseModal4 = () => {
    this.setState({ open5: false });
  };

  onOpenModal4 = () => {
    this.setState({ open5: true });
  };

  EditSubmit = (e) => {
    e.preventDefault();

    // const ExpenseAmountDueChange =
    //   this.state.formData1.ExpenseAmountDue.toString().includes(",")
    //     ? parseFloat(this.state.formData1.ExpenseAmountDue.replace(/,/g, ""))
    //     : this.state.formData1.ExpenseAmountDue;
    // delete this.state.formData1.ExpenseAmountDue;
    // this.state.formData1.ExpenseAmountDue = ExpenseAmountDueChange;

    // const ExpenseAmountPaidChange = `$${this.state.formData1.ExpenseAmountPaid}`;
    // delete this.state.formData1.ExpenseAmountPaid;
    // this.state.formData1.ExpenseAmountPaid = ExpenseAmountPaidChange;
    console.log(this.state.formData1);
    console.log("hello we have clicked the button");

    this.editmanualDealRecurring();
  };

  DealRecurring = async () => {
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.Version = this.state.Version;
    data.TableName = this.state.TableName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await DealRecurring(data);

    console.log("expensesDealRecurring", APIResponse.data);

    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        displayData: APIResponse.data,
        loading: false,
        openModalPayment: false,
      });
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
      //this.props.history.push("/admin/previewmapfields");
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  onCloseModalBorrow = () => {
    this.setState({ isBorrow: false });
  };
  onOpenModalBorrow = () => {
    this.setState({ isBorrow: true });
  };
  checkBorrowBaseYes = async () => {
    this.setState(
      { calculateBorrow: true, BorrowingBase: "True", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };
  checkBorrowBaseNo = async () => {
    this.setState(
      { calculateBorrow: false, BorrowingBase: "False", isBorrow: false },
      async () => {
        await this.CalculatePrePayments();
      }
    );
  };

  CalculatePrePayments = async () => {
    this.setState({ getLoansLoader: true, formLoaderCalulate: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.NextPaymentDate = this.state?.NextPaymentDate;
    data.LastPaymentDate = this.state?.LastPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePrePayments(data);

    console.log("AccountsCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoaderCalulate: false,
        });
        // const message = APIResponse.data.message;
        // this.props.enqueueSnackbar(message, {
        //   variant: "info",
        //   autoHideDuration: 3000,
        // });
        this.CalculatePayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoaderCalulate: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoaderCalulate: false });
    }
  };

  CalculatePayments = async () => {
    this.setState({ getLoansLoader: true, formLoaderCalulate: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.BorrowingBase = this.state.BorrowingBase;
    data.peers = this.state.peers;
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePayments(data);

    console.log("ExpensesCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoaderCalulate: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateCollateralPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoaderCalulate: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoaderCalulate: false });
    }
  };

  CalculateCollateralPayments = async () => {
    this.setState({ getLoansLoader: true, formLoaderCalulate: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateCollateralPayments(data);

    console.log("GeneralCalculateCollateralPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoaderCalulate: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        await this.CalculateConsolidatedPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoaderCalulate: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoaderCalulate: false });
    }
  };

  CalculateConsolidatedPayments = async () => {
    this.setState({ getLoansLoader: true, formLoaderCalulate: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateConsolidatedPayments(data);

    console.log("PaymentCalculateConsolidatedPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoaderCalulate: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        if (sessionStorage.getItem("isESMA_Flag") === "Yes") {
          await this.CalculateESMAPayments();
        } else {
          this.props.history.push({
            pathname: "/admin/manualinput_recurring",
            state: { reloadComponent: true, timestamp: new Date().getTime() },
          });
        }
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoaderCalulate: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoaderCalulate: false });
    }
  };

  CalculateESMAPayments = async () => {
    this.setState({ getLoansLoader: true, formLoaderCalulate: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userId = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateESMAPayments(data);

    console.log("PaymentCalculateESMAPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoaderCalulate: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.props.history.push({
          pathname: "/admin/manualinput_recurring",
          state: { reloadComponent: true, timestamp: new Date().getTime() },
        });
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoaderCalulate: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoaderCalulate: false });
    }
  };

  editmanualDealRecurring = async () => {
    this.setState({ formLoader: true });
    let x = this.state.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    let data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    // data.Data_Type=this.state.Data_Type

    data.TableName = this.state.TableName;
    const ObjAdd = {
      ...this.state.formData1,
    };
    data.Data = ObjAdd;
    data.peers = this.state.peers;
    console.log("dataAddddd", data);

    const APIResponse = await editmanualinputRecurring(data);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.DealRecurring();
        this.onCloseModal2();
      } else {
        // const message = APIResponse.data.message;
        // this.props.enqueueSnackbar(message, {
        //   variant: "error",
        //   autoHideDuration: 3000,
        // });
        this.setState({ formLoader: false });
        this.onCloseModal2();
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal2();
    }
  };

  Expenses = (value) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("dashChanges", false);
    this.props.history.push({
      pathname: "/admin/manualinput",
      state: { checkRecurring: true },
    });
  };
  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: false,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: true,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringBorrowingBase: false,
        checkRecurringPaymentRules: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  handleDealNameChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("dealname", newValue);
    this.setState(
      { DealName: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.DealName);
        await this.getAllPaymentDatesByDealName();
        await this.getAllVersionsByPaymentDate();


        if (this.state.isLoansProcessed === "No") {
          this.props.history.push("/admin/general_recurring");
        }

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.DealRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.DealRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.DealRecurring();
            });
          }
        }
      }
    );
  };

  getAllPaymentDatesByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    const { NextPaymentDate } = this.state;

    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllPaymentDatesByDealName(data);

    console.log("getAllPaymentDatesByDealName", APIResponse.data);
    if (APIResponse.status === 200) {
      const paymentDates = APIResponse.data.PaymentDates;
      console.log(paymentDates, "payment");

      const [nextMonth, nextDay, nextYear] = NextPaymentDate.split("/");

      // Find the first payment date in the same month and year
      const sameMonthYearDate = paymentDates.find((date) => {
        const [month, day, year] = date.split("/");
        return month === nextMonth && year === nextYear;
      });

      const nextPaymentDate = sameMonthYearDate || paymentDates[0]; // Default to the first available date if none matches

      console.log("nextPaymentDate", nextPaymentDate);
      // // Determine the value for nextpaymentdate
      // const nextPaymentDate = paymentDates.includes(NextPaymentDate)
      //   ? NextPaymentDate
      //   : paymentDates[0];

      // Update sessionStorage with the determined nextpaymentdate
      sessionStorage.setItem("nextpaymentdate", nextPaymentDate);

      // Update sessionStorage with the dashboard dates
      sessionStorage.setItem("getDashboardDates", JSON.stringify(paymentDates));

      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("nextpaymentdate", newValue);
    this.setState(
      { NextPaymentDate: newValue, isDisable: true, openModalPayment: true },
      async () => {
        console.log("pppppp", this.state.NextPaymentDate);
        await this.getAllVersionsByPaymentDate();

        if (this.state.isLoansProcessed === "No") {
          this.props.history.push("/admin/general_recurring");
        }

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.DealRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.DealRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.DealRecurring();
            });
          }
        }
      }
    );
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the Version state with the selected value
    sessionStorage.setItem("version", newValue);
    this.setState(
      {
        Version: newValue,
        isDisable: true,
      },
      async () => {
        const { Version, getDashboardVersions } = this.state;

        if (this.state.isLoansProcessed === "No") {
          this.props.history.push("/admin/general_recurring");
        }

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.DealRecurring();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            // If the selected version is not the latest, call DealRecurring
            this.setState({ isDisable: this.state.isDisable });
            this.DealRecurring();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.DealRecurring();
            });
          }
        }
      }
    );
  };

  async componentDidUpdate(prevProps) {
    console.log("PrevProps:", prevProps.location.state);
    console.log("CurrentProps:", this.props.location.state);

    if (
      this.props.location.state?.reloadComponent &&
      prevProps.location.state?.reloadComponent !==
        this.props.location.state?.reloadComponent
    ) {
      console.log("Triggering DealRecurring");
      await this.DealRecurring();

      // Reset reloadComponent after processing
      this.props.history.push({
        ...this.props.location,
        state: { ...this.props.location.state, reloadComponent: false },
      });
    }
  }

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);

    if (this.state.isLoansProcessed === "No") {
      this.props.history.push("/admin/general_recurring");
    }
    const { Version, getDashboardVersions } = this.state;

    if (
      (Version === "WIP" || Version === "V1") &&
      getDashboardVersions.length === 1
    ) {
      this.setState({ isDisable: false, Version: "WIP" }, async () => {
        await this.DealRecurring();
      });
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          const numberMatch = version.match(/\d+/); // Extract the number using regex
          if (numberMatch) {
            const number = parseInt(numberMatch[0]);
            if (number > highestNumber) {
              highestNumber = number;
              prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }

      const latestVersion = getMaxVersion(getDashboardVersions);
      if (Version !== latestVersion) {
        // If the selected version is not the latest, call DealRecurring
        this.setState({ isDisable: this.state.isDisable });
        this.DealRecurring();
      } else {
        this.setState({ isDisable: false, Version: "WIP" }, async () => {
          await this.DealRecurring();
        });
      }
    }

    // if (this.state.Version === "V1" || this.state.Version === "V2") {
    //   this.setState({ isDisable: this.state.isDisable });
    //   this.DealRecurring();
    // } else {
    //   this.setState({ isDisable: false });
    //   this.DealRecurring();
    // }
  }

  goBackToDashBoard = () => {
    this.props.history.push({
      pathname: "/",
    });
  };

  handleButtonClick() {
    this.setState((prevState) => ({
      downArrow: !prevState.downArrow,
    }));
  }

  handleFilter = (tableName, data, columnKey, criteria) => {
    const { filterCriteria, tableData, currentBatch, itemsPerBatch } =
      this.state;

    const newCriteria = { ...filterCriteria, [columnKey]: criteria };
    this.setState({ filterCriteria: newCriteria });

    // Check if all criteria are empty
    const isCriteriaEmpty = Object.values(newCriteria).every(
      (criteria) => !criteria || !criteria.value1
    );

    if (isCriteriaEmpty) {
      // Reset to the first batch of the full dataset if no criteria are selected
      this.setState({
        displayData: tableData.slice(0, currentBatch * itemsPerBatch),
        filteredClrChangeColumns: {
          ...this.state.filteredClrChangeColumns,
          [columnKey]: false,
        },
      });
      return;
    }

    // Apply filters
    const filtered = tableData.filter((item) => {
      return Object.keys(newCriteria).every((key) => {
        const criteria = newCriteria[key];
        if (!criteria) return true;

        const { condition1, value1, operator, condition2, value2 } = criteria;

        const applyCondition = (value, condition, target) => {
          switch (condition) {
            case "equals":
              return value === target;
            case "less_than":
              return value < target;
            case "greater_than":
              return value > target;
            case "not_equals":
              return value !== target;
            default:
              return false;
          }
        };

        const condition1Met = applyCondition(item[key], condition1, value1);
        const condition2Met = applyCondition(item[key], condition2, value2);

        return operator === "AND"
          ? condition1Met && condition2Met
          : condition1Met || condition2Met;
      });
    });

    console.log({ filtered });

    // Apply sorting after filtering
    const { sortOrder } = this.state;
    const sortedFiltered = [...filtered].sort((a, b) => {
      const valueA = a[sortOrder.columnKey];
      const valueB = b[sortOrder.columnKey];

      if (!isNaN(valueA) && !isNaN(valueB)) {
        return sortOrder.order === "ascend" ? valueA - valueB : valueB - valueA;
      }

      if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
        return sortOrder.order === "ascend"
          ? new Date(valueA) - new Date(valueB)
          : new Date(valueB) - new Date(valueA);
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder.order === "ascend"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0;
    });

    // Update display data to only show the current batch
    this.setState({
      displayData: sortedFiltered.slice(0, currentBatch * itemsPerBatch),
      filteredClrChangeColumns: {
        ...this.state.filteredClrChangeColumns,
        [columnKey]: !!criteria,
      },
    });
  };

  handleSort = (tableName, columnKey, order) => {
    const { tableData, itemsPerBatch } = this.state;

    // Determine the chunk size based on the total dataset size
    const chunkSize = tableData.length; // Max 1000 rows or 10% of the dataset
    const sortedData = [];

    const sortChunk = (start) => {
      const end = Math.min(start + chunkSize, tableData.length);
      const chunk = tableData.slice(start, end).sort((a, b) => {
        const valueA = a[columnKey];
        const valueB = b[columnKey];

        if (!isNaN(valueA) && !isNaN(valueB)) {
          return order === "ascend" ? valueA - valueB : valueB - valueA;
        }

        if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
          return order === "ascend"
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
          return order === "ascend"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        if (valueA == null || valueB == null) {
          return valueA == null ? 1 : -1;
        }

        return 0;
      });

      sortedData.push(...chunk);

      if (end < tableData.length) {
        setTimeout(() => sortChunk(end), 0); // Process the next chunk
      } else {
        // Update state after all chunks are sorted
        this.setState({
          tableData: sortedData,
          displayData: sortedData.slice(0, itemsPerBatch),
        });
      }
    };

    sortChunk(0);
  };

  handlePaginationChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleOverlayHide = () => {
    this.setState({ downArrow: false });
  };

  render() {
    const { displayData, currentPage, pageSize, loading } = this.state;

    // Paginate data manually
    const paginatedData = displayData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    const sortedDeals = [...this.state.getDashboardDeals].sort();

    const columns = [
      {
        title: "Name", // Replaces "label"
        dataIndex: "Name", // Replaces "name"
        key: "name", // Unique identifier for the column
        align: "left",

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Name"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Name")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Name"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div>{value}</div>,
      },
      {
        title: "Value",
        dataIndex: "Value",
        key: "value",
        align: "left",

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Value"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Value")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Value"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => (
          <div>
            <NumberComp2 value={value} />
          </div>
        ),
      },
      {
        title: "Data Type",
        dataIndex: "Data Type",
        key: "dataType",

        hidden: true,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Deal Name"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Deal Name")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Deal Name"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div>{value}</div>,
      },
      {
        title: "Actions",
        key: "actions",
        align: "left",
        render: (_, record) => (
          <div>
            {this.state.isDisable === true ||
            this.state.isLoansProcessed === "No" ? (
              <button
                className="popupbutton23"
                onClick={() => this.handleRowClick(record)} // Use the record object directly
                disabled
              >
                Edit
              </button>
            ) : (
              <button
                className="popupbutton1"
                onClick={() => this.handleRowClick(record)} // Use the record object directly
              >
                Edit
              </button>
            )}
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Deal Recurring"} />
          <div className="content">
            <div className="page-contentofpool1">
              <div className="row1 arrow-dealcontent">
                <div
                  className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1.5rem",
                    width: "90rem",
                  }}
                >
                  <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                    <KeyboardBackspaceIcon
                      onClick={this.goBackToDashboard}
                      className="left-arrow-muis1 left-arrow-servicer"
                    ></KeyboardBackspaceIcon>
                    <h3 className="headerdashboard">DEAL RECURRING</h3>
                  </div>
                </div>
              </div>

              <div className="row1 css-recurring move-dropdowns">
                <div className="recurring_details_new">
                  <div>
                    <label className="dealInfo">Deal Name </label>
                    {/* <h6 className="dealInfo1">{this.state.DealName}</h6> */}
                    <div>
                      <select
                        className="input-select-general-new1-deal"
                        value={this.state?.DealName}
                        onChange={this.handleDealNameChange}
                      >
                        {sortedDeals.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="dealInfo">Payment Date </label>
                    <div>
                      <select
                        className="input-select-general-new1"
                        value={this.state?.NextPaymentDate}
                        onChange={this.handlePaymentDateChange}
                      >
                        {this.state.getDashboardDates.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="dealInfo">Version </label>
                    {/* <h6 className="dealInfo1">{this.state?.Version}</h6> */}
                    <div>
                      <select
                        className="input-select-general-new1"
                        value={
                          this.state.Version === "WIP"
                            ? sessionStorage.getItem("version")
                            : this.state.Version
                        }
                        onChange={this.handleVersionChange}
                      >
                        {this.state.getDashboardVersions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="buttonsverified uw-deal-details-button-container">
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="bottom"
                    overlay={this.popoverBottom()}
                    onExited={this.handleOverlayHide}
                  >
                    <Button variant="outlined" onClick={this.handleButtonClick}>
                      Edit
                      {this.state.downArrow ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </Button>
                  </OverlayTrigger>
                  {this.state.isDisable === true ||
                  this.state.isLoansProcessed === "No" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.CalculatePrePayments}
                      disabled
                      style={{
                        color: "#000", // Change this to your desired text color
                      }}
                    >
                      Calculate
                      {this.state.formLoaderCalulate === true ? (
                        <CircularProgress size="22px" color="primary" />
                      ) : (
                        ""
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.state.formLoaderCalulate}
                      style={{
                        color: "#000", // Change this to your desired text color
                      }}
                      onClick={
                        sessionStorage.getItem("isSecuritisation") !==
                        "Securitisation"
                          ? this.onOpenModalBorrow
                          : this.CalculatePrePayments
                      }
                    >
                      Calculate
                      {this.state.formLoaderCalulate === true ? (
                        <CircularProgress size="22px" color="primary" />
                      ) : (
                        ""
                      )}
                    </Button>
                  )}
                </div>
              </div>

              <div className="row row1 movement-tabs">
                <div>
                  {/* <div className="tablechangebutton"> */}
                  <div className="tablechangebuttonloans">
                    <button
                      type="button"
                      onClick={() => this.handleClickGeneral()}
                      className={
                        this.state.activeInsights1 == true
                          ? "issuerDashboard-table-top-button-insights-active"
                          : "issuerDashboard-table-top-button-insights"
                      }
                    >
                      General
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickTranches()}
                      className={
                        this.state.activeInsights2 == true
                          ? "issuerDashboard-table-top-button-workbench-active"
                          : "issuerDashboard-table-top-button-workbench"
                      }
                    >
                      Tranches
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickFees()}
                      className={
                        this.state.activeInsights3 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Fees
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickExpenses()}
                      className={
                        this.state.activeInsights4 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Expenses
                    </button>
                    <button
                      type="button"
                      // onClick={() => this.handleClickManualInputs()}
                      className={
                        this.state.activeInsightsMI == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Manual Inputs
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickAccounts()}
                      className={
                        this.state.activeInsights5 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Accounts
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickTests()}
                      className={
                        this.state.activeInsights6 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Tests
                    </button>
                    {this.state.isSecuritisation !== "Securitisation" ? (
                      <button
                        type="button"
                        onClick={() => this.handleBorrowingBase()}
                        className={
                          this.state.activeInsightsBB == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Borrowing Base
                      </button>
                    ) : null}
                    <button
                      type="button"
                      onClick={() => this.handleClickVariables()}
                      className={
                        this.state.activeInsights7 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Variables
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickPaymentRules()}
                      className={
                        this.state.activeInsights8 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Payment Rules
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => this.handleClickCollateral()}
                      className={
                        this.state.activeInsights9 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Collateral
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickLoanTape()}
                      className={
                        this.state.activeInsights10 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Loan Tape
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickConsolidated()}
                      className={
                        this.state.activeInsights11 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Consolidated
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickFiles()}
                      className={
                        this.state.activeInsights12 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Files
                    </button> */}
                  </div>
                </div>
                {/* <div className="recurring_details">
                      <div>
                        <label className="dealInfo">Deal Name </label>
                        <h6 className="dealInfo1">{this.state.DealName}</h6>
                      </div>
                      <div>
                        <label className="dealInfo">Payment Date </label>
                        <h6 className="dealInfo1">{this.state?.NextPaymentDate}</h6>
                      </div>
                    </div> */}
              </div>

              <div className="row row1 movement-header">
                <div className="investor-heading-container">
                  <h1 className="headerdashboard1">List of Manual Inputs</h1>
                </div>
                <div></div>
              </div>

              <React.Fragment>
                <div>
                  <Table
                    style={{ position: "relative", bottom: "5rem" }}
                    className="custom-antds-table"
                    dataSource={displayData.map((item, index) => ({
                      ...item,
                      key: index, // Add a unique key for each row
                    }))}
                    columns={columns} // Use the refactored Ant Design-compatible columns
                    pagination={false}
                    loading={this.state.loading}
                    scroll={{ x: "max-content", y: 600 }} // Enable horizontal and vertical scroll
                    rowKey={(record) => record.key} // Use unique key for rows
                  />
                  {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
                overflow: "hidden",
              }}
            >
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={displayData.length}
                onChange={this.handlePaginationChange}
              />
            </div> */}
                  <ReactModal
                    isOpen={this.state.openModalPayment}
                    contentLabel="Minimal Modal Example"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "fixed", // Use fixed positioning for the overlay
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                      },
                      content: {
                        position: "absolute",
                        background: "transparent", // Make the modal content transparent
                        border: "none", // Optional: Remove any borders
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        overflow: "hidden",
                      },
                    }}
                  >
                    <React.Fragment>
                      <img
                        src={BeanEater}
                        style={{
                          width: "8vw",
                          height: "9vh",
                          backgroundColor: "transparent",
                        }}
                      />
                      <h3 className="dot-loader">Loading.Please wait</h3>
                    </React.Fragment>
                  </ReactModal>
                  <ReactModal
                    isOpen={this.state.isBorrow}
                    onRequestClose={this.onCloseModalBorrow}
                    contentLabel="Minimal Modal Example"
                    style={customStylesautosmallmodalpopupBorrow}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h3 className="popupheading">
                          Do you want to run Payment Waterfall?
                        </h3>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                        <div className="modalshiftcontent">
                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-start11">
                                  <button
                                    type="button"
                                    className="popupbutton1"
                                    onClick={this.onCloseModalBorrow}
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <div className="row justify-content-end11">
                                  <button
                                    type="button"
                                    className="popupbutton1"
                                    onClick={this.checkBorrowBaseNo}
                                  >
                                    No
                                  </button>

                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.checkBorrowBaseYes}
                                  >
                                    Yes
                                    {/* {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="25px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )} */}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>
                <div id="modal1">
                  <ReactModal
                    isOpen={this.state.isBorrow}
                    onRequestClose={this.onCloseModalBorrow}
                    contentLabel="Minimal Modal Example"
                    style={customStylesautosmallmodalpopupBorrow}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h3 className="popupheading">
                          Do you want to run Payment Waterfall?
                        </h3>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                        <div className="modalshiftcontent">
                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-start11">
                                  <button
                                    type="button"
                                    className="popupbutton1"
                                    onClick={this.onCloseModalBorrow}
                                  >
                                    Cancel
                                  </button>
                                </div>
                                <div className="row justify-content-end11">
                                  <button
                                    type="button"
                                    className="popupbutton1"
                                    onClick={this.checkBorrowBaseNo}
                                  >
                                    No
                                  </button>

                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.checkBorrowBaseYes}
                                  >
                                    Yes
                                    {/* {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="25px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )} */}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>
              </React.Fragment>
              <>
                <div className="btn_move">
                  <div className="btn_prev" onClick={this.showPrev}>
                    Previous
                  </div>
                  <button className="btn_next" onClick={this.showNext}>
                    Next
                  </button>
                </div>
              </>
            </div>

            <>
              <ReactModal
                isOpen={this.state.open3}
                onRequestClose={this.onCloseModal2}
                contentLabel="Minimal Modal Example"
                style={customStylesauto}
              >
                <React.Fragment>
                  <div className="modalPopup">
                    <h2>Edit Manual Inputs</h2>
                    <button
                      type="button"
                      className="closePopup"
                      style={{ minWidth: "30px" }}
                      onClick={this.onCloseModal2}
                    >
                      <CloseIcon></CloseIcon>{" "}
                    </button>

                    {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                    <div className="modalshiftcontent">
                      <form
                        className="form-container"
                        onSubmit={this.EditSubmit}
                      >
                        <div className="input-container">
                          <label className="label"> Name</label>
                          <input
                            required
                            placeholder="Event of Default"
                            className="input"
                            type="text"
                            disabled
                            value={this.state.formData1.Name}
                          />
                        </div>

                        <div className="input-container">
                          <label className="label">Value</label>
                          <input
                            required
                            placeholder="Type here"
                            className="input"
                            type="text"
                            onChange={(event) => this.handleChange(event)}
                            value={this.state.formData1.Value}
                          />
                        </div>

                        {/* <div className="input-container">
                          <label className="label">Expense Amount Due</label>
                          <input
                            required
                            placeholder="Type here"
                            className="input"
                            type="text"
                            onChange={(e) => {
                              let removeCharC = e.target.value.replace(
                                /[^0-9\.]/g,
                                ""
                              );
                              let formattedValue = removeCharC.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              );
                              this.setState({
                                formData1: {
                                  ...this.state.formData1,
                                  ExpenseAmountDue: formattedValue,
                                },
                              });
                            }}
                            value={this.state.formData1.ExpenseAmountDue}
                          />
                        </div> */}
                        {/* <div className="input-container">
                          <label className="label">Expense Amount Paid</label>
                          <input
                            required
                            placeholder="Type here"
                            className="input"
                            type="text"
                            onChange={(e) => {
                              let removeCharC = e.target.value.replace(
                                /[^0-9\.]/g,
                                ""
                              );
                              let formattedValue = removeCharC.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              );
                              this.setState({
                                formData1: {
                                  ...this.state.formData1,
                                  ExpenseAmountPaid: formattedValue,
                                },
                              });
                            }}
                            value={this.state.formData1.ExpenseAmountPaid}
                          />
                        </div> */}

                        <div className="modalsubmit">
                          <div className="submitbuttonbg">
                            <div className="row">
                              <div className="row justify-content-end1">
                                <button
                                  type="button"
                                  className="popupbutton22"
                                  onClick={this.onCloseModal2}
                                >
                                  Cancel
                                </button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                >
                                  Save
                                  {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="22px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </React.Fragment>
              </ReactModal>
            </>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Expenses_Recurring);
