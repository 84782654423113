import React, { useState, useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import "./ChatPlots.css";

function ChatPlots({ graphData }) {
  const [plots, setPlots] = useState([]); // Initialize with an empty array
  const scatterPlotContainer = useRef({}); // To store refs for multiple plots

  useEffect(() => {
    // Check if graphData is available and valid JSON
    let finalData;
    try {
      finalData = JSON.parse(graphData);
      console.log({ finalData });
    } catch (error) {
      console.error("Error parsing graph data: ", error);
      return; // Exit if JSON parsing fails
    }

    if (finalData) {
      const parsePlotData = (data) => {
        let parsedData = { data: [], layout: {} };

        if (typeof data === "string") {
          try {
            parsedData = JSON.parse(data); // Parse JSON string safely
          } catch (error) {
            console.error("Error parsing plot data: ", error);
          }
        } else if (typeof data === "object" && data !== null) {
          parsedData = data;
        }

        if (!parsedData.layout) {
          parsedData.layout = {};
        }

        return parsedData;
      };

      // If finalData is an array, loop through each item and parse plot data
      const dynamicPlots = Array.isArray(finalData)
        ? finalData.map((plotData) => parsePlotData(plotData))
        : [parsePlotData(finalData)];

      // Update the plots state with the new data and layout for each plot
      setPlots(dynamicPlots);
    }

    // const handleResize = () => {
    //   // Adjust layout width on resize for each plot
    //   setPlots((prevPlots) =>
    //     prevPlots.map((plot) => ({
    //       ...plot,
    //       layout: {
    //         ...plot.layout,
    //         width:
    //           scatterPlotContainer.current?.offsetWidth || window.innerWidth,
    //       },
    //     }))
    //   );
    // };

    // window.addEventListener("resize", handleResize);
    // return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="chat-chart-container">
      {plots.map((plot, index) => (
        <div
          key={index}
          className="plot-borders-bar-chat-charts"
          ref={(el) => (scatterPlotContainer.current[index] = el)}
        >
          <Plot
            data={plot.data}
            layout={plot.layout}
            config={{
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "zoom",
                "resetScale2d",
              ],
            }}
            className="plot-inline-chat-charts padding-graph"
          />
        </div>
      ))}
    </div>
  );
}

export default ChatPlots;
