import React, { Component } from 'react';

export const InvoiceList = [
    {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025501",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    },

    {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025502",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    },
    {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025503",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    },
    {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025504",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025505",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025506",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025507",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025508",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025509",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025510",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025511",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025512",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025513",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }, {
        "_id": "5f60594c5b90b97446bf4448",
        "vendor_ID": "Vendor 1",
        "vendor_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "client_UIC": "Client 1",
        "client_address": "0xbbfdb236331db2b1c1b3f6fcb0b785eef7c35685",
        "invoice_number": "A0619025514",
        "currency_code": "INR",
        "issue_date": 43769,
        "opening_value": 9254.12,
        "cash_allocated": 0,
        "remaining_balance": 9254.12,
        "insured_amount": 9254.12,
        "payment_days": 14,
        "confirmed": "Y",
        "insurer": "0x06dA5219B02f94C66E86A427347536F028e3dA48",
        "date_paid": null,
        "movedtobc": "NO"
    }]

export const PlatformList = [
    {
        "Platform_ID": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "Platform_Name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "Platform_ID": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788adasC",
        "Platform_Name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "Platform_ID": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "Platform_Name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "Platform_ID": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788adasC",
        "Platform_Name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "Platform_ID": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "Platform_Name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "Platform_ID": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788adasC",
        "PlatformName": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }
]


export const InsurerList = [
    {
        "insurer_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "insurer_name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "insurer_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788adasC",
        "insurer_name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "insurer_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "insurer_name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "insurer_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788adasC",
        "insurer_name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "insurer_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "insurer_name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "insurer_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788adasC",
        "insurer_name": "Invoice ABC Platform 1",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }
]

export const CurrencyList = [
    {
        "currency_id": "0x9eE0b0E8",
        "currency_name": "USD",
        "currency_symbol": "$",
        "currency_country": "USA",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "currency_id": "0x9eE0b0E8",
        "currency_name": "USD",
        "currency_symbol": "$",
        "currency_country": "USA",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "currency_id": "0x9eE0b0E8",
        "currency_name": "USD",
        "currency_symbol": "$",
        "currency_country": "USA",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "currency_id": "0x9eE0b0E8",
        "currency_name": "USD",
        "currency_symbol": "$",
        "currency_country": "USA",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "currency_id": "0x9eE0b0E8",
        "currency_name": "USD",
        "currency_symbol": "$",
        "currency_country": "USA",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "currency_id": "0x9eE0b0E8",
        "currency_name": "USD",
        "currency_symbol": "$",
        "currency_country": "USA",
        "created_on": "08/02/2020",
        "user_id": "user1"
    },

]


export const HolidayList = [
    {
        "holiday_id": "0x9eE0b0E8",
        "holiday_name": "Martin Luther King Jr. Day	",
        "holiday_year": "2020",
        "holiday_month": "Jan",
        "holiday_country": "USA",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "holiday_id": "0x9eE0b0E8",
        "holiday_name": "Memorial Day	",
        "holiday_year": "2020",
        "holiday_month": "Jan",
        "holiday_country": "USA",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }
]

export const UserRoleList = [
    {
        "user_role_id": "0x9eE0b0E8",
        "user_role_name": "Vendor",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }, {
        "user_role_id": "0x9eE0b0E8",
        "user_role_name": "Vendor",
        "created_on": "08/02/2020",
        "user_id": "user1"
    }
]

export const PortfolioList = [
    {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    }, {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    }, {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    },
    {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    }, {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    }, {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    }, {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    }, {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    }, {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    }, {
        "porfolio_id": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "porfolio_name": "Invoice ABC Platform 1",
        "no_of_invoices": "10",
        "invoice_balance": "215000",
        "purchase_price": "250000",
        "portfolio_address": "0x9eE0b0E8fB3264ecd806D4F6a72AE4AC788AEa3C",
        "created_date": "08/02/2020",
        "last_update": "08/02/2020",

    }
]



export const VendorList = [
    {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    }, {
        "vendor_id": "vendor5",
        "vendor_name": "v4",
        "vendor_group": "A",
        "vendor_city": "chennai",
        "vendor_country": "India",
        "NACR": "false",
        "credit_line": "122",
        "creditLine_date": "20/12/1992",
        "currency": "INR",
    },
]

export const ClientList = [
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
    {
        "client_id": "C1001",
        "client_name": "Client 1",
        "client_group": "Group 1",
        "client_address": "Postbus 2016",
        "client_city": "Hoofddorp",
        "client_country": "Netherlands",
        "client_post_code": "2130 GE",
        "client_country_code": "NLD",
        "credit_line": "155000",
        "creditline_date": "43852",
        "currency": "INR",
        "nace": "A"
    },
]


export const UserList = [
    {
        "user_id": "5f60594c5b9",
        "user_name": "Abcd",
        "firstname": "Smith",
        "lastname": "Admin",
        "emailid": "email@gmail.com",
        "role": "Vendor",
        "phone": "+112345680",
        "address": "Pune",
        "country": "USA",
        "state": "New York",
        "city": "New York",
        "pincode": "10001",
    },{
        "user_id": "5f60594c5b9",
        "user_name": "Abcd",
        "firstname": "Smith",
        "lastname": "Admin",
        "emailid": "email@gmail.com",
        "role": "Vendor",
        "phone": "+112345680",
        "address": "Pune",
        "country": "USA",
        "state": "New York",
        "city": "New York",
        "pincode": "10001",
    },{
        "user_id": "5f60594c5b9",
        "user_name": "Abcd",
        "firstname": "Smith",
        "lastname": "Admin",
        "emailid": "email@gmail.com",
        "role": "Vendor",
        "phone": "+112345680",
        "address": "Pune",
        "country": "USA",
        "state": "New York",
        "city": "New York",
        "pincode": "10001",
    },{
        "user_id": "5f60594c5b9",
        "user_name": "Abcd",
        "firstname": "Smith",
        "lastname": "Admin",
        "emailid": "email@gmail.com",
        "role": "Vendor",
        "phone": "+112345680",
        "address": "Pune",
        "country": "USA",
        "state": "New York",
        "city": "New York",
        "pincode": "10001",
    },{
        "user_id": "5f60594c5b9",
        "user_name": "Abcd",
        "firstname": "Smith",
        "lastname": "Admin",
        "emailid": "email@gmail.com",
        "role": "Vendor",
        "phone": "+112345680",
        "address": "Pune",
        "country": "USA",
        "state": "New York",
        "city": "New York",
        "pincode": "10001",
    },{
        "user_id": "5f60594c5b9",
        "user_name": "Abcd",
        "firstname": "Smith",
        "lastname": "Admin",
        "emailid": "email@gmail.com",
        "role": "Vendor",
        "phone": "+112345680",
        "address": "Pune",
        "country": "USA",
        "state": "New York",
        "city": "New York",
        "pincode": "10001",
    },{
        "user_id": "5f60594c5b9",
        "user_name": "Abcd",
        "firstname": "Smith",
        "lastname": "Admin",
        "emailid": "email@gmail.com",
        "role": "Vendor",
        "phone": "+112345680",
        "address": "Pune",
        "country": "USA",
        "state": "New York",
        "city": "New York",
        "pincode": "10001",
    },{
        "user_id": "5f60594c5b9",
        "user_name": "Abcd",
        "firstname": "Smith",
        "lastname": "Admin",
        "emailid": "email@gmail.com",
        "role": "Vendor",
        "phone": "+112345680",
        "address": "Pune",
        "country": "USA",
        "state": "New York",
        "city": "New York",
        "pincode": "10001",
    },{
        "user_id": "5f60594c5b9",
        "user_name": "Abcd",
        "firstname": "Smith",
        "lastname": "Admin",
        "emailid": "email@gmail.com",
        "role": "Vendor",
        "phone": "+112345680",
        "address": "Pune",
        "country": "USA",
        "state": "New York",
        "city": "New York",
        "pincode": "10001",
    }
]