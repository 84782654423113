import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Card,
  Typography,
  Row,
  Col,
  Input,
  AutoComplete,
  Button,
  Space,
  Switch,
} from "antd";
import save from "../../images/save.svg";
import download from "../../images/download.svg";
import feedback from "../../images/feedback.svg";
import IDA from "../../images/IDA.svg";
import BETA from "../../images/BETA.svg";
import Curved_Back from "../../images/Curved_Back.svg";
import Curved_Front from "../../images/Curved_Front.svg";
import Send_Btn_Msg from "../../images/Send_Btn_Msg.svg";
import Go_Back from "../../images/Go_Back.svg";
import Delete_Chat from "../../images/Delete_Chat.svg";
import "./ChatWindow.css";
import { Context } from "../../context/Context";
import ChatPlots from "./ChatPlots";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { useSnackbar } from "notistack";
import { w3cwebsocket as W3CWebSocket } from "websocket";

const { Text, Title } = Typography;
const { TextArea } = Input;

const ChatWindow = (props) => {
  const {
    onSent,
    recentPrompt,
    recentDocumentPrompt,
    showResult,
    setShowResult,
    loading,
    resultData,
    setInput,
    input,
    prevPrompts,
    setPrevPrompts,
    prevDocumentPrompts,
    setPrevDocumentPrompts,
    setRecentPrompt,
    setRecentDocumentPrompt,
    currentPromptIndex,
    currentDocumentPromptIndex,
    setCurrentDocumentPromptIndex,
    setCurrentPromptIndex,
    showPrevPrompts,
    showPrevDocumentPrompts,
    handleSaveClick,
    displayedPrompts,
    setDisplayedPrompts,
    displayedDocumentPrompts,
    setDisplayedDocumentPrompts,
    selectedDocumentPrompt,
    selectedPrompt,
    setSelectedPrompt,
    setSelectedDocumentPrompt,
    loadPrompt,
    isModalFeedbackVisible,
    comment,
    setDocid,
    setComment,
    handleOk,
    handleCancelFeedback,
    showModal,
    setIsModalFeedbackVisible,
    setResultData,
    setLoading,
    docid,
  } = useContext(Context);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [askQuestion, setAskQuestion] = useState(null);
  const [unsavedPrompts, setUnsavedPrompts] = useState([]);
  const [client, setClient] = useState(null);
  const [socket, setSocket] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isDocument, setIsDocument] = useState(false);
  const [dataResponse, setDataResponse] = useState(null);
  const [documentResponse, setDocumentResponse] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);

  // Add platform and user info constants
  const getPlatform = sessionStorage.getItem("platform_name");
  const getUserName = sessionStorage.getItem("user_name");
  const getUserId = sessionStorage.getItem("user_id");
  const getEmail = sessionStorage.getItem("emailid");

  const finalUserName = getUserName;
  const finalInvs = {
    platform: getPlatform,
    getUserName: finalUserName,
    getUserId: getUserId,
    email: getEmail,
    dealName: sessionStorage.getItem("dealname"),
    assetClass: sessionStorage.getItem("AssetClass"),
    toggle: isDocument ? "Document" : "Data", // Use state directly
  };
  const stringifiedfinalInvs = JSON.stringify(finalInvs);

  // Function to send user input to WebSocket server
  const checkSuggestion = (value) => {
    if (askQuestion && askQuestion.readyState === WebSocket.OPEN) {
      // console.log("Sending question to WebSocket:", value);
      askQuestion.send(JSON.stringify({ query: value }));
    } else {
      console.log("WebSocket is not open or not ready.");
    }
  };

  // Update main WebSocket connection
  useEffect(() => {
    let mainWsClient = null;
    let pingInterval = null;

    if (isModalVisible) {
      try {
        // Use different endpoint based on toggle state
        const wsEndpoint = `wss://www.ask.intainabs.com/ida/${stringifiedfinalInvs}`;

        mainWsClient = new W3CWebSocket(wsEndpoint);

        mainWsClient.onopen = () => {
          console.log(
            `WebSocket Client Connected (${
              isDocument ? "Document" : "Data"
            } mode)`
          );
          // pingInterval = setInterval(() => {
          //   if (mainWsClient.readyState === WebSocket.OPEN) {
          //     console.log("Ping sent to keep the connection alive.");
          //   }
          // }, 1200000);
        };

        mainWsClient.onmessage = (message) => {
          setResultData(message.data);
          // Store response in the appropriate state based on mode
          if (isDocument) {
            setDocumentResponse(message.data);
          } else {
            setDataResponse(message.data);
          }
          setLoading(false);
        };

        mainWsClient.onerror = (error) => {
          console.error("WebSocket Error:", error);
        };

        mainWsClient.onclose = () => {
          console.log("WebSocket Client Closed");
          clearInterval(pingInterval);
        };

        setClient(mainWsClient);
      } catch (error) {
        console.error("Failed to create WebSocket connection:", error);
      }
    }

    return () => {
      if (mainWsClient) {
        mainWsClient.close();
      }
      if (pingInterval) {
        clearInterval(pingInterval);
      }
    };
  }, [isModalVisible, stringifiedfinalInvs, isDocument]); // Add isDocument as dependency

  useEffect(() => {
    if (resultData) {
      try {
        const parsedData = JSON.parse(resultData);
        // console.log({ parsedData });

        setDocid(parsedData.docId || ""); // Set docid if available
      } catch (error) {
        console.error("Failed to parse resultData:", error);
      }
    }
  }, [resultData]);

  // Update handleSearch to only filter existing suggestions
  const handleSearch = (value) => {
    if (value.trim() !== "") {
      const uniqueSuggestions = Array.from(new Set(suggestions)).filter(
        (suggestion) => suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(uniqueSuggestions);
    } else {
      setSuggestions([]); // Clear suggestions if input is empty
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log({ value });

    setInput(value);

    if (value.trim() !== "" && value.endsWith(" ")) {
      fetchSuggestions(value.trim());
    } else {
      setSuggestions([]);
    }
  };

  const fetchSuggestions = (query) => {
    const toggleState = isDocument ? "Document" : "Data";
    const wsUrl = `wss://www.ask.intainabs.com/idasupport/autocomplete/{"toggle":"${toggleState}"}`;

    const autocompleteWs = new WebSocket(wsUrl);

    autocompleteWs.onopen = () => {
      console.log("Autocomplete WebSocket connection established.");
      autocompleteWs.send(JSON.stringify({ query }));
    };

    autocompleteWs.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.suggestions) {
          const getQuestions = data.suggestions.map((item) => item.Question);
          const uniqueQuestions = Array.from(new Set(getQuestions));
          setSuggestions(uniqueQuestions);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
      autocompleteWs.close();
    };

    autocompleteWs.onerror = (error) => {
      console.error("WebSocket error:", error);
      autocompleteWs.close();
    };
  };

  const handleSelect = (value) => {
    setInput(value);
    setSelectedPrompt(null); // Reset the selected prompt
    // onSent(value); // Call the existing method with the selected value
  };

  const handleDeleteChat = () => {
    setDocid(""); // Reset the selected prompt

    // Determine the selected prompt based on the mode
    const currentSelectedPrompt = isDocument
      ? selectedDocumentPrompt
      : selectedPrompt;

    if (currentSelectedPrompt) {
      // Filter out the selected prompt from the appropriate prompts list
      const updatedPrompts = isDocument
        ? prevDocumentPrompts.filter(
            (prompt) => prompt !== currentSelectedPrompt
          )
        : prevPrompts.filter((prompt) => prompt !== currentSelectedPrompt);

      const updatedDisplayedPrompts = isDocument
        ? displayedDocumentPrompts.filter(
            (prompt) => prompt !== currentSelectedPrompt
          )
        : displayedPrompts.filter((prompt) => prompt !== currentSelectedPrompt);

      // Update the context or state that holds the previous prompts
      if (isDocument) {
        setPrevDocumentPrompts(updatedPrompts);
        setDisplayedDocumentPrompts(updatedDisplayedPrompts);
        setRecentDocumentPrompt("");
        setSelectedDocumentPrompt(null); // Reset the selected document prompt
      } else {
        setPrevPrompts(updatedPrompts);
        setDisplayedPrompts(updatedDisplayedPrompts);
        setRecentPrompt("");
        setSelectedPrompt(null); // Reset the selected prompt
      }

      setShowResult(false);
    } else {
      // If no selectedPrompt, remove the last item from the appropriate prompts list
      if (isDocument && prevDocumentPrompts.length > 0) {
        const updatedPrompts = prevDocumentPrompts.slice(0, -1); // Remove the last item
        setPrevDocumentPrompts(updatedPrompts);
        setRecentDocumentPrompt("");
        setSelectedDocumentPrompt(null); // Reset the selected document prompt
      } else if (prevPrompts.length > 0) {
        const updatedPrompts = prevPrompts.slice(0, -1); // Remove the last item
        setPrevPrompts(updatedPrompts);
        setRecentPrompt("");
        setSelectedPrompt(null); // Reset the selected prompt
      }
      setShowResult(false);
    }
  };

  // Function to check if a string is JSON formatted
  const isJSON = (str) => {
    try {
      const parsed = JSON.parse(str);
      return parsed && typeof parsed === "object";
    } catch (error) {
      return false;
    }
  };

  // Check if resultData contains a valid JSON string
  // ... existing code ...
  let parsedResultData = null;
  if (resultData && typeof resultData === "string") {
    if (isJSON(resultData)) {
      parsedResultData = JSON.parse(resultData);
    } else {
      parsedResultData = resultData; // plain string
    }
  }

  // Safely extract headers and rows if parsedResultData is an object
  const headers = parsedResultData?.response?.[0] || [];
  const rows = parsedResultData?.response?.slice(1) || [];

  const getGraphs = parsedResultData?.graph || [];

  // console.log({ parsedResultData });

  // console.log("repsonse", onSent);

  useEffect(() => {
    if (props.visible) {
      setIsModalVisible(true);
    }
  }, [props.visible]);

  // useEffect(() => {
  //   if (props.visible && prevPrompts.length > 0) {
  //     setCurrentPromptIndex(prevPrompts.length - 1); // Set to the last prompt in history
  //   }
  // }, [prevPrompts]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const handleSaveClick = () => {
  //   const finalPrompt = recentPrompt;
  //   savePromptToHistory(finalPrompt); // Save prompt to history
  //   // if (finalPrompt.trim() !== "") {
  //   //   console.log("Saved prompt to history:", finalPrompt);
  //   // }
  // };
  const handlePrevPrompt = () => {
    // Determine the current index and prompts based on the mode
    const currentIndex = isDocument
      ? currentDocumentPromptIndex
      : currentPromptIndex;
    const prompts = isDocument ? prevDocumentPrompts : prevPrompts;

    if (currentIndex !== null && currentIndex > 0) {
      // Determine the previous prompt based on the mode
      const prevPrompt = prompts[currentIndex - 1];

      // First update the context
      loadPrompt(prevPrompt, currentIndex - 1);

      // Then send through WebSocket
      if (client && client.readyState === WebSocket.OPEN) {
        setResultData("");
        setLoading(true);
        setShowResult(true);

        client.send(JSON.stringify(`${prevPrompt}`));

        // Set recent prompt based on mode
        if (isDocument) {
          setRecentDocumentPrompt(prevPrompt);
          setCurrentDocumentPromptIndex(currentIndex - 1); // Update document index
        } else {
          setRecentPrompt(prevPrompt);
          setCurrentPromptIndex(currentIndex - 1); // Update data index
        }

        setInput("");
      } else {
        console.log("WebSocket is not connected.");
      }
    } else {
      console.log("No previous prompt available");
    }
  };

  const handleNextPrompt = () => {
    // Determine the current index and prompts based on the mode
    const currentIndex = isDocument
      ? currentDocumentPromptIndex
      : currentPromptIndex;
    const prompts = isDocument ? prevDocumentPrompts : prevPrompts;

    if (currentIndex !== null && currentIndex < prompts.length - 1) {
      // Determine the next prompt based on the mode
      const nextPrompt = prompts[currentIndex + 1];

      // First update the context
      loadPrompt(nextPrompt, currentIndex + 1);

      // Then send through WebSocket
      if (client && client.readyState === WebSocket.OPEN) {
        setResultData("");
        setLoading(true);
        setShowResult(true);

        client.send(JSON.stringify(`${nextPrompt}`));

        // Set recent prompt based on mode
        if (isDocument) {
          setRecentDocumentPrompt(nextPrompt);
          setCurrentDocumentPromptIndex(currentIndex + 1); // Update document index
        } else {
          setRecentPrompt(nextPrompt);
          setCurrentPromptIndex(currentIndex + 1); // Update data index
        }

        setInput("");
      } else {
        console.log("WebSocket is not connected.");
      }
    } else {
      console.log("No next prompt available");
    }
  };

  // Update handleOk for feedback
  const handleOkLocal = () => {
    if (!comment.trim()) {
      enqueueSnackbar("Comment cannot be empty. Please enter your feedback.", {
        variant: "warning",
      });
      setComment("");
      return;
    }

    // Check if docid is present
    if (!docid) {
      enqueueSnackbar("Document ID is missing. Cannot submit feedback.", {
        variant: "error",
      });
      return;
    }

    // Establish a new WebSocket connection for feedback
    const platformData = { platform: getPlatform, docId: docid };
    const platformString = encodeURIComponent(JSON.stringify(platformData));
    const wsUrl = `wss://www.ask.intainabs.com/ida/feedback/${platformString}`;
    const feedbackWs = new WebSocket(wsUrl);

    feedbackWs.onopen = () => {
      feedbackWs.send(comment);
    };

    feedbackWs.onmessage = (event) => {
      try {
        const responseData = JSON.parse(event.data);
        enqueueSnackbar(
          responseData.response || "Feedback submitted successfully!",
          {
            variant: "success",
          }
        );
      } catch (error) {
        console.error("Error parsing WebSocket response:", error);
        enqueueSnackbar("Feedback submitted successfully!", {
          variant: "success",
        });
      } finally {
        setIsModalFeedbackVisible(false);
        setComment("");
        feedbackWs.close(); // Close the WebSocket after processing the response
      }
    };

    feedbackWs.onerror = (error) => {
      console.error("WebSocket error:", error);
      enqueueSnackbar(
        "Failed to connect to the server. Please try again later.",
        {
          variant: "error",
        }
      );
      feedbackWs.close(); // Ensure the WebSocket is closed on error
    };
  };

  // Update loadPrompt to use WebSocket
  const handleLoadPrompt = async (prompt, index) => {
    // Then send the loaded prompt through WebSocket
    if (client && client.readyState === WebSocket.OPEN) {
      setResultData("");
      setLoading(true);
      setShowResult(true);

      // Modify the prompt or data sent based on the document mode
      const modeSpecificPrompt = isDocument ? `Document: ${prompt}` : prompt;

      client.send(JSON.stringify(`${modeSpecificPrompt}`));

      // Set recent prompt based on mode
      if (isDocument) {
        loadPrompt(prompt, index); // Call the context loadPrompt
        setSelectedDocumentPrompt(prompt);
        setRecentDocumentPrompt(prompt);
      } else {
        loadPrompt(prompt, index); // Call the context loadPrompt
        setSelectedPrompt(prompt);
        setRecentPrompt(prompt);
      }

      setInput("");
    } else {
      console.log("WebSocket is not connected.");
    }
  };

  const handleSend = async (prompt) => {
    if (client && client.readyState === WebSocket.OPEN) {
      setResultData("");
      setLoading(true);
      setShowResult(true);

      let finalPrompt = prompt !== undefined ? prompt : input;

      if (finalPrompt.trim() !== "") {
        // Add to appropriate history based on isDocument
        if (isDocument) {
          setPrevDocumentPrompts((prev) => {
            if (!prev.includes(finalPrompt)) {
              const updatedPrompts = [...prev, finalPrompt];
              setCurrentDocumentPromptIndex(updatedPrompts.length - 1); // Update document index
              return updatedPrompts;
            }
            return prev;
          });
          // Send through WebSocket
          setRecentDocumentPrompt(finalPrompt);
          client.send(JSON.stringify(`${finalPrompt}`));
          console.log({ finalPrompt });
          setInput("");
        } else {
          setPrevPrompts((prev) => {
            if (!prev.includes(finalPrompt)) {
              const updatedPrompts = [...prev, finalPrompt];
              setCurrentPromptIndex(updatedPrompts.length - 1); // Update data index
              return updatedPrompts;
            }
            return prev;
          });
          // Send through WebSocket
          setRecentPrompt(finalPrompt);
          client.send(JSON.stringify(`${finalPrompt}`));
          setInput("");
        }
      } else {
        console.log("Prompt is empty or undefined.");
      }
    } else {
      console.log("WebSocket is not connected.");
      enqueueSnackbar("Connection not established. Please try again.", {
        variant: "error",
        autoHideDuration: 1000, // Snackbar will disappear after 1 second
      });
    }
  };

  // Add toggle handler
  const handleToggleChange = (checked) => {
    // Store current response before switching
    if (!checked) {
      // Switching to Data mode
      setDocumentResponse(resultData);
      setResultData(dataResponse);
      setRecentPrompt(""); // Clear the recent prompt when switching modes
    } else {
      // Switching to Document mode
      setDataResponse(resultData);
      setResultData(documentResponse);
      setRecentDocumentPrompt(""); // Clear the recent document prompt when switching modes
    }

    setIsDocument(checked);
    setLoading(false);
    setShowResult(false); // Reset showResult when switching modes
    setInput("");

    // Close existing connections
    if (client) client.close();
    if (socket) socket.close();
    if (askQuestion) askQuestion.close();
  };

  return (
    <div>
      <Modal
        title=""
        open={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose={false}
        closable={false}
        style={{ padding: "20px 0" }}
        width="auto"
        height="100vh"
        centered
        footer={null}
        className="modalStyle"
        modalRender={(modalContent) => (
          <div
            className="modal-content"
            style={{
              // minHeight: "95vh",
              minWidth: "95vw",
            }}
          >
            {modalContent}
          </div>
        )}
      >
        {/* <button
          onClick={() =>
            onSent(
              "What is the average loan balance of loans which are 180+ dq for Test P deal?"
            )
          }
        >
          Send Question
        </button>
        <p>Response from server: {response}</p> */}
        {/* Header Content */}
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "16px" }}
        >
          <Col className="top_left_Icons">
            <span className="icon-spacing">
              <img src={Go_Back} alt="Go_Back" onClick={handleCancel} />
            </span>
            {/* <span className="icon-spacing">Credit Capital Corporation</span> */}
          </Col>
          <Col
            justify="space-between"
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {/* DB/Document Toggle */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                right: "10px",
                minWidth: "200px",
                justifyContent: "flex-end",
              }}
            >
              <span
                style={{
                  color: !isDocument ? "#018E82" : "var(--text_Color)",
                  transition: "color 0.3s ease",
                  minWidth: "40px",
                  textAlign: "right",
                  marginRight: "8px",
                }}
              >
                Data
              </span>
              <Switch
                checked={isDocument}
                onChange={handleToggleChange}
                size="small"
                style={{
                  margin: "0 8px",
                  minWidth: "10px",
                  width: "40px",
                }}
                className="custom-switch"
              />
              <span
                style={{
                  color: isDocument ? "#018E82" : "var(--text_Color)",
                  transition: "color 0.3s ease",
                  minWidth: "80px",
                  textAlign: "left",
                  marginLeft: "8px",
                }}
              >
                Document
              </span>
            </div>

            {/* Delete Chat Icon */}
            <span className="icon-spacing" onClick={handleDeleteChat}>
              <img src={Delete_Chat} alt="Delete Chat" />
            </span>

            {/* Download Icon */}
            <span
              className="icon-spacing"
              style={{ position: "relative", top: "2px" }}
            >
              <img src={download} alt="download" />
            </span>
          </Col>
        </Row>

        {/* Bottom Input Section */}
        <Row
          justify="space-between"
          align="start"
          style={{ marginTop: "20px" }}
          // className="BottomChat"
          gutter={24}
        >
          <Col span={6}>
            <Text className="history" strong>
              History
            </Text>
            <div style={{ padding: "20px 0" }} className="chat-msgs">
              {(isDocument ? showPrevDocumentPrompts : showPrevPrompts) &&
                (isDocument
                  ? displayedDocumentPrompts.length > 0
                  : displayedPrompts.length > 0) && (
                  <div>
                    {/* {console.log(
                      "Using prompts:",
                      isDocument
                        ? "displayedDocumentPrompts"
                        : "displayedPrompts"
                    )} */}
                    {(isDocument ? displayedDocumentPrompts : displayedPrompts)
                      .slice()
                      .reverse()
                      .map((prompt, index) => {
                        return (
                          <div key={index}>
                            <Text
                              className={`recent-entry ${
                                (isDocument
                                  ? selectedDocumentPrompt
                                  : selectedPrompt) === prompt
                                  ? "selected-prompt"
                                  : ""
                              }`}
                              onClick={() => handleLoadPrompt(prompt, index)}
                              style={{ cursor: "pointer" }}
                            >
                              {prompt}
                            </Text>
                            <hr className="horizontal_seperator" />
                          </div>
                        );
                      })}
                  </div>
                )}
            </div>
          </Col>

          {/* Middle section - Graphs */}
          <Col
            span={18}
            style={{
              borderRadius: "10px", // Smooth border-radius for the container
              border: "1px solid var(--tableBorder)", // Optional border for visual clarity
              height: "75vh", // Height for the scrollable area (adjust as needed)
              overflowY: "clip",
              position: "relative", // Required for positioning the fixed element
              paddingBottom: "100px", // Bottom padding to ensure the content doesn't overlap with IDA_Virtual
              backgroundColor: "var(--chatWindow_Color)",
            }}
          >
            {/* Scrollable content */}
            <Row style={{ marginBottom: "20px", paddingTop: "10px" }}>
              <Col span={2}>
                <span className="icon-spacing">
                  <img
                    src={Curved_Back}
                    alt="Curved_Back"
                    onClick={handlePrevPrompt}
                  />
                </span>
                <span className="icon-spacing">
                  <img
                    src={Curved_Front}
                    alt="Curved_Front"
                    onClick={handleNextPrompt}
                  />
                </span>
              </Col>

              <Col span={16}>
                <Title level={5}>
                  <span className="as_of_Labels">Interpreted As :</span>
                  <span className="as_of_Labels">
                    {loading ? (
                      // Render loader while loading is true
                      <div className="loader-chat">
                        {/* This is where the loader (like a spinner) appears */}
                        <hr />
                      </div>
                    ) : showResult ? (
                      // Check if the mode is Document
                      isDocument ? (
                        // Render document-specific content
                        parsedResultData?.inferredAs ||
                        recentDocumentPrompt ||
                        "No document interpretation available"
                      ) : (
                        // Render data-specific content
                        parsedResultData?.inferredAs ||
                        recentPrompt ||
                        "No data interpretation available"
                      )
                    ) : // Render nothing if showResult is false
                    null}
                  </span>
                </Title>
              </Col>

              <Col
                justify="space-between"
                span={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <span className="icon-spacing" onClick={handleSaveClick}>
                  <img src={save} alt="save" />
                </span>
                <span className="icon-spacing" onClick={showModal}>
                  <img src={feedback} alt="feedback" />
                </span>
              </Col>
            </Row>
            <div className="scrollable-content-chat">
              <div style={{ padding: "10px" }}>
                {!showResult ? (
                  <div className="suggested-questions">
                    {!isDocument ? (
                      // Database mode suggested questions
                      <>
                        <Text
                          className="suggested-question-needed"
                          onClick={() =>
                            handleSend(
                              "Can you please provide portfolio summary"
                            )
                          }
                        >
                          Can you please provide portfolio summary
                        </Text>

                        <Text
                          className="suggested-question-needed"
                          onClick={() =>
                            handleSend("What is the Pool WAC for my portfolio?")
                          }
                        >
                          What is the Pool WAC for my portfolio?
                        </Text>

                        <Text
                          className="suggested-question-needed"
                          onClick={() =>
                            handleSend(
                              "What is the top 5 property State wise distribution?"
                            )
                          }
                        >
                          What is the top 5 property State wise distribution?
                        </Text>

                        <Text
                          className="suggested-question-needed"
                          onClick={() =>
                            handleSend("Can you please provide Bond Summary")
                          }
                        >
                          Can you please provide Bond Summary
                        </Text>
                      </>
                    ) : (
                      // Document mode - empty or different suggestions
                      <Text className="document-mode-message">
                        Please enter your question about the document
                      </Text>
                    )}
                  </div>
                ) : (
                  <div className="result">
                    <div className="result-title">
                      <img
                        src={IDA}
                        alt="IDA"
                        style={{
                          width: "38px",
                          height: "38px",
                          borderRadius: "50%",
                        }} // Avatar style
                      />
                      <h4 className="as_of_Labels">
                        {isDocument ? recentDocumentPrompt : recentPrompt}
                      </h4>
                    </div>
                    <div className="result-data">
                      {loading ? (
                        <div className="loader-chat">
                          <hr />
                          <hr />
                          <hr />
                        </div>
                      ) : (
                        <div>
                          {isDocument ? (
                            // Document mode - show only response value
                            <p style={{ color: "var(--text_Color)" }}>
                              {parsedResultData?.response ||
                                "No response available."}
                            </p>
                          ) : (
                            // Database mode - existing table and graph display
                            <>
                              {typeof parsedResultData === "string" ? (
                                <p>{parsedResultData}</p>
                              ) : headers.length > 0 ? (
                                <>
                                  {typeof parsedResultData.response ===
                                    "string" && (
                                    <p style={{ color: "var(--text_Color)" }}>
                                      {parsedResultData.response}
                                    </p>
                                  )}
                                  <table className="styled-table specific-table">
                                    <thead>
                                      <tr>
                                        {Array.isArray(headers) &&
                                          headers.map((header, index) => (
                                            <th key={index}>{header}</th>
                                          ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Array.isArray(rows) &&
                                        rows.map((row, rowIndex) => (
                                          <tr key={rowIndex}>
                                            {row.map((cell, cellIndex) => (
                                              <td key={cellIndex}>{cell}</td>
                                            ))}
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>

                                  {parsedResultData?.graph &&
                                    parsedResultData.graph.length > 0 && (
                                      <ChatPlots
                                        graphData={JSON.stringify(
                                          parsedResultData.graph
                                        )}
                                      />
                                    )}
                                </>
                              ) : (
                                <p>No data available.</p>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Fixed row at the bottom of the container */}
            <Row
              className=""
              style={{
                position: "absolute", // Keep the row fixed at the bottom
                bottom: 0, // Align it to the bottom of the container
                left: 0,
                borderRadius: "0 0 10px 10px",
                width: "100%", // Ensure full width
                backgroundColor: "var(--chatWindow_Color)", // White background to match the design
                padding: "10px", // Add padding around the content
                zIndex: 10, // Stay above scrolling content
                // boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)", // Shadow for slight separation
                borderTop: "1px solid var(--tableBorder)",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start", // Proper spacing between content
              }}
            >
              {/* Avatar and Beta tag */}
              <div
                className="IDA_Icons"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px", // Space between icon and input
                  marginLeft: "10px", // Space between icon and input
                  top: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={IDA}
                  alt="VirtualAsst"
                  style={{ width: "38px", height: "38px", borderRadius: "50%" }} // Avatar style
                />
                <img
                  src={BETA}
                  alt="VirtualAsst"
                  className="beta"
                  style={{ height: "18px", width: "38px" }}
                />
              </div>

              {/* AutoComplete Input */}
              <div style={{ position: "relative", width: "93%" }}>
                <AutoComplete
                  value={input}
                  options={
                    input.trim() !== ""
                      ? suggestions.map((suggestion) => {
                          return { value: suggestion };
                        })
                      : []
                  }
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  style={{
                    width: "100%",
                    border: "none",
                    borderRadius: "10px",
                    paddingLeft: "15px",
                  }}
                >
                  <Input
                    placeholder="Get the Accurate Answers and Create Reports"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && input.trim() !== "") {
                        handleSend(input);
                        setSelectedPrompt(null);
                      }
                    }}
                    value={input}
                    className="chatInput-text"
                    onChange={handleInputChange}
                    style={{
                      paddingRight: "40px",
                      borderRadius: "10px",
                      border: "1px solid #8C8C8C",
                      color: "var(--tableText)",
                      backgroundColor: "var(--chatWindow_Color)",
                    }}
                  />
                </AutoComplete>

                {/* Send button inside the input */}
                <img
                  src={Send_Btn_Msg}
                  alt="Send Button"
                  style={{
                    width: "24px",
                    height: "24px",
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  onClick={() => {
                    if (input.trim() !== "") {
                      console.log({ input });

                      handleSend(input);
                      setSelectedPrompt(null);
                    }
                  }}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Leave feedback for Ida"
        visible={isModalFeedbackVisible}
        onCancel={handleCancelFeedback}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancelFeedback}
            style={{ backgroundColor: "var(--dropdownBackgroundColor)" }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{ backgroundColor: "#018e82" }}
            onClick={handleOkLocal}
          >
            Submit
          </Button>,
        ]}
        style={{ backgroundColor: "var(--white)", color: "var(--tableText)" }}
      >
        <Space direction="horizontal">
          <LikeOutlined style={{ fontSize: "24px" }} />
          <LikeOutlined
            style={{ fontSize: "24px", transform: "rotate(180deg)" }}
          />
        </Space>
        <Input.TextArea
          placeholder="Type comment here"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          rows={4}
          style={{ marginTop: "16px" }}
        />
      </Modal>
    </div>
  );
};

export default ChatWindow;
