/* eslint-disable require-jsdoc */
import React, { Component } from "react";
// import { connect } from 'react-redux';
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { withSnackbar } from "notistack";
import "react-input-range/lib/css/index.css";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Spreadsheet from "react-spreadsheet";
import { Category } from "@material-ui/icons";
import ReactModal from "react-modal";
import { customEditSpreadsheet } from "../../../../../components/customscripts/customscript";
import { dealPreviewLoanTape } from "../../../../../servies/services";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import SearchIcon from "@material-ui/icons/Search";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ArrowDownward from "../../../../../images/arrow_downward.png";
import ArrowUpward from "../../../../../images/arrow_upward.png";
import FilterMappedTape from "../../../../../images/FilterMappedTape.svg";
import * as moment from "moment";
import rightArrow from "../../../../../images/RightPointer.png";

import { TableVirtuoso } from "react-virtuoso";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import jsonData from "./data.json";
import ClearIcon from "@mui/icons-material/Clear";

// import ReactDataSheet from "react-datasheet";
// // import DataGridXL from 'datagridxl';
// import DataGridXL from "@datagridxl/datagridxl";
const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

class Preview_LoanTape extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
      loading: false,
      open1: false,
      tableData: [],
      firstKey: "",
      DealName:
        sessionStorage.getItem("dealname") === null
          ? sessionStorage.getItem("servicerdealname")
          : sessionStorage.getItem("dealname"),
      ServicerName:
        sessionStorage.getItem("Servicer") === null
          ? sessionStorage.getItem("servicerdashboardname")
          : sessionStorage.getItem("Servicer"),
      servicerDashboardName:
        this.props.location?.state?.ServicerDashboardName === undefined
          ? sessionStorage.getItem("servicerdashboardname")
          : this.props.location?.state?.ServicerDashboardName,
      servicerDealName:
        this.props.location?.state?.ServicerDealName === undefined
          ? sessionStorage.getItem("servicerdealname")
          : this.props.location?.state?.ServicerDealName,
      detailsDate:
        sessionStorage.getItem("selectdate") === null
          ? sessionStorage.getItem("selectservicerdate")
          : sessionStorage.getItem("selectdate"),
      getdashboarddates: sessionStorage.getItem("getDashboardDates"),
      servicerPaymentDate:
        this.props.location?.state?.ServicerPaymentDate === undefined
          ? sessionStorage.getItem("selectservicerdate")
          : this.props.location?.state?.ServicerPaymentDate,
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      screenloader: false,
      MapDataValues: [],
      MapDataKeys: [],
      data: [],
      searchTerm: "",
      isDataFound: true,
      isSearchOpen: false,
      sortColumn: "", // Track the currently sorted column
      sortAscending: true,
      filterConditions: {},
      filteredColumn: [],
      filteredData: [],
      overlayVisible: false,
    };
  }

  handleButtonClick = (column) => {
    this.setState((prevState) => ({
      activeColumn: column,
      overlayVisible: !prevState.overlayVisible,
    }));
  };

  handleOverlayHide = () => {
    this.setState({ overlayVisible: false });
  };

  sortA = (sortColumn) => {
    console.log("", this.state.MapDataKeys);
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return -1;
      if (a[sortColumn] > b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click();
  };

  sortZ = (sortColumn) => {
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] > b[sortColumn]) return -1;
      if (a[sortColumn] < b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click();
  };

  handleClickCancel = (column) => {
    let temp = this.state.filteredColumn.filter((col) => col !== column);
    console.log("columnname", column, this.state.filteredColumn);
    this.setState((prevState) => {
      const updatedFilterConditions = {
        ...prevState.filterConditions,
        [column]: {
          condition1: "Equals",
          value1: "",
          condition2: "Equals",
          value2: "",
          logic: "OR", // Default logic
        },
      };

      const filteredData = prevState.tableData.filter((row) => {
        return Object.keys(updatedFilterConditions).every((col) => {
          const { condition1, value1, condition2, value2, logic } =
            updatedFilterConditions[col];

          if (value1 || value2) {
            const isFirstConditionMet = this.checkCondition(
              row[col],
              condition1,
              value1,
              col
            );
            const isSecondConditionMet = this.checkCondition(
              row[col],
              condition2,
              value2,
              col
            );

            // Use logic to determine whether to apply AND or OR
            if (logic === "AND") {
              return isFirstConditionMet && isSecondConditionMet;
            }
            return isFirstConditionMet || isSecondConditionMet;
          }
          return true;
        });
      });

      return {
        filterConditions: updatedFilterConditions,
        filteredData: filteredData.length > 0 ? filteredData : [{}], // Restore full data if no matches
      };
    });
    document.body.click();
  };

  handleFilterSubmit = (column, filterConditions) => {
    const { condition1, value1, condition2, value2, logic } = filterConditions;
    console.log("Filter conditions:", filterConditions);
  
    const currentData =
      this.state.filteredData.length > 0
        ? this.state.filteredData
        : this.state.tableData;
  
    const filteredData = currentData.filter((row) => {
      const isFirstConditionMet = this.checkCondition(
        row[column],
        condition1,
        value1,
        column
      );
      const isSecondConditionMet = this.checkCondition(
        row[column],
        condition2,
        value2,
        column
      );
  
      // Apply AND or OR based on logic
      return logic === "AND"
        ? isFirstConditionMet && isSecondConditionMet
        : isFirstConditionMet || isSecondConditionMet;
    });
  
    // Check if filteredData is empty
    if (filteredData.length === 0) {
      this.setState({ filteredData: [{ message: "Data not found" }] });
    } else {
      this.setState({ filteredData });
    }
  
    console.log("Filtered Data:", filteredData);
  
    let temp = [...this.state.filteredColumn];
    if (!temp.includes(column)) {
      temp.push(column);
    }
    this.setState({ filteredColumn: temp });
  
    document.body.click();
  };
  
  checkCondition = (cellValue, condition, value, column) => {
    if (cellValue === null || cellValue === undefined || cellValue === "") {
      return false; // Skip rows with empty values
    }
  
    const parsedCellValue = isNaN(Number(cellValue))
      ? cellValue
      : Number(cellValue);
    const parsedValue = isNaN(Number(value)) ? value : Number(value);
  
    console.log("parsed", parsedCellValue, parsedValue);
    switch (condition) {
      case "Equals":
        return parsedCellValue === parsedValue;
      case "Not Equals":
        return parsedCellValue !== parsedValue;
      case "Greater than":
        return parsedCellValue > parsedValue;
      case "Lesser than":
        return parsedCellValue < parsedValue;
      default:
        return true;
    }
  };

  updateFilterCondition = (column, field, value) => {
    this.setState(
      (prevState) => ({
        filterConditions: {
          ...prevState.filterConditions,
          [column]: {
            ...prevState.filterConditions[column],
            [field]: value,
          },
        },
      }),
      () => {
        console.log("Updated filterConditions:", this.state.filterConditions); // Debug log
      }
    );
  };

  popoverBottom = (column) => {
    const { filterConditions } = this.state;
    const columnFilterConditions = filterConditions[column] || {
      condition1: "",
      value1: "",
      logic: "OR",
      condition2: "",
      value2: "",
    };

    const isFilterActive = this.state.isFilterActive;
    const triangleImage = isFilterActive ? rightArrow : rightArrow;

    // Removed conditions and displaying all filter options
    const filterOptions = [
      "Equals",
      "Greater than",
      "Lesser than",
      "Not Equals",
    ];

    let uniqueValues = [];
    if (this.state.tableData.length > 0) {
      uniqueValues = [
        ...new Set(this.state.tableData.map((row) => row[column])),
      ];
    }

    const renderInputField = (condition, valueField) => {
      return (
        <select
          className="popover-input-wide dropdownicon"
          value={columnFilterConditions[valueField]}
          onChange={(e) =>
            this.updateFilterCondition(column, valueField, e.target.value)
          }
        >
          <option value="">Select value</option>
          {uniqueValues.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </select>
      );
    };

    const popoverClass =
      this.state.popoverPlacement === "left"
        ? "popover-container-sort-left"
        : "popover-container-sort-right";

    return (
      <Popover className={popoverClass}>
        <div className="popover-content">
          <div className="popover-row">
            <img src={ArrowDownward} alt="arrow-downward" />
            <button
              className="popover-sort-btn"
              onClick={() => this.sortA(column)}
            >
              Sort A to Z
            </button>
          </div>
          <div className="popover-row">
          <img src={ArrowUpward} alt="arrow-upward" />
            <button
              className="popover-sort-btn"
              onClick={() => this.sortZ(column)}
            >
              Sort Z to A
            </button>
          </div>
          <div>
            <p>Custom Filter</p>
            <div className="popover-row">
              <select
              
                className="popover-input"
                value={columnFilterConditions.condition1}
                onChange={(e) =>
                  this.updateFilterCondition(
                    column,
                    "condition1",
                    e.target.value
                  )
                }
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {renderInputField(columnFilterConditions.condition1, "value1")}
            </div>
            <div className="popover-radio-group">
              <div className="popover-radio-item">
                <input
                  type="radio"
                  name="filterLogic"
                  value="AND"
                  checked={columnFilterConditions.logic === "AND"}
                  onChange={(e) =>
                    this.updateFilterCondition(column, "logic", e.target.value)
                  }
                />
                <label>AND</label>
              </div>
              <div className="popover-radio-item">
                <input
                  type="radio"
                  name="filterLogic"
                  value="OR"
                  checked={columnFilterConditions.logic === "OR"}
                  onChange={(e) =>
                    this.updateFilterCondition(column, "logic", e.target.value)
                  }
                />
                <label>OR</label>
              </div>
            </div>
            <div className="popover-row">
              <select
                className="popover-input"
                value={columnFilterConditions.condition2}
                onChange={(e) =>
                  this.updateFilterCondition(
                    column,
                    "condition2",
                    e.target.value
                  )
                }
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {renderInputField(columnFilterConditions.condition2, "value2")}
            </div>
            <div className="popover-bottom-buttons">
              <button
                type="button"
                className="popupbutton22"
                onClick={() => this.handleClickCancel(column)}
              >
                Cancel
              </button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  this.handleFilterSubmit(column, columnFilterConditions);
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    );
  };

  handleSort = (column) => {
    const { sortColumn, sortAscending, tableData } = this.state;

    // Determine the new sort order
    let newSortAscending;
    if (sortColumn === column) {
      newSortAscending = !sortAscending;
    } else {
      // Default to ascending order when sorting a new column
      newSortAscending = true;
    }

    // Custom compare function for sorting
    const compareFunc = (a, b) => {
      const valueA = a[column] ? a[column].toLowerCase() : "";
      const valueB = b[column] ? b[column].toLowerCase() : "";
      if (valueA < valueB) return newSortAscending ? -1 : 1;
      if (valueA > valueB) return newSortAscending ? 1 : -1;
      return 0;
    };

    // Sort the table data based on the selected column and sort order
    const sortedTableData = [...tableData].sort(compareFunc);

    this.setState({
      tableData: sortedTableData,
      sortColumn: column,
      sortAscending: newSortAscending,
    });
  };

  handleSearchIconClick = () => {
    this.setState({
      isSearchOpen: true,
    });
  };

  dealPreviewLoanTape = async () => {
    const checkDate =
      sessionStorage.getItem("user_name") === "Trustee"
        ? this.state.detailsDate
        : this.state.servicerPaymentDate || this.state.getdashboarddates;
    let x = moment(checkDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};

    if (sessionStorage.getItem("user_name") === "Trustee") {
      data.DealName = this.state.DealName;
      data.peer = this.state.peer;
    } else {
      data.DealName =
        this.state.servicerDealName || this.state.servicerDashboardName;
      data.peer = this.state.peer;
    }

    data.Month = month;
    data.Year = year;
    data.ServicerName = this.state.ServicerName;
    data.ClosingTape = JSON.parse(sessionStorage.getItem("isClosingTape"))
      ? true
      : false;
    data.peer = this.state.peer;

    console.log("datata", data);
    this.setState({ screenloader: true });

    const APIResponse = await dealPreviewLoanTape(data);

    if (APIResponse.status === 200 && APIResponse.data.isSuccess === true) {
      const tableData = APIResponse.data?.result || [];
      const firstKey =
        tableData.length > 0 ? Object.keys(tableData[0])[0] : "N/A";

      this.setState(
        {
          MapDataKeys: tableData.length > 0 ? Object.keys(tableData[0]) : [],
          MapDataValues: tableData.map((item) =>
            Object.keys(item).map((key) => ({ value: item[key] }))
          ),
          screenloader: false,
          tableData,
          firstKey, // Store the first key in the state
        },
        () => {
          const filterConditions = this.state.MapDataKeys.reduce(
            (acc, column) => {
              acc[column] = {
                condition1: "Equals",
                value1: "",
                condition2: "Equals",
                value2: "",
                logic: "OR",
              };
              return acc;
            },
            {}
          );
          this.setState({ filterConditions });
        }
      );
    } else if (
      APIResponse.status === 200 &&
      APIResponse.data.isSuccess === false
    ) {
      this.setState({ screenloader: false });
      const message = APIResponse.data.result;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      }, () => {
        const filterConditions = this.state.MapDataKeys.reduce(
          (acc, column) => {
            acc[column] = {
              condition1: "Equals",
              value1: "",
              condition2: "Equals",
              value2: "",
              logic: "OR",
            };
            return acc;
          },
          {}
        );
        this.setState({ filterConditions });
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  componentDidMount() {
    this.dealPreviewLoanTape();
  }
  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) => {
      for (let key in row) {
        if (
          row[key] &&
          row[key].toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }
      }
      return false;
    });

    this.setState({
      searchTerm: event.target.value,
      data: filteredData,
      isDataFound: filteredData.length > 0,
    });
  };

  clearSearch = () => {
    this.setState({
      searchTerm: "",
      data: this.state.tableData, // Reset the data to the original dataset
    });
  };

  filterData = (searchTerm) => {
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) =>
      Object.values(row).some((value) =>
        value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    return filteredData;
  };

  handleOpenModal = () => {
    this.setState({ open1: true });
  };

  handleCloseModal = () => {
    this.setState({ open1: false });
  };

  handleClickUploadLoanTapes = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
    });
    this.props.history.push("/admin/uploadloantape");
  };

  handleClickPreviewLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
    });
  };
  handleClickMapFields = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
    });
    this.props.history.push("/admin/mapfields");
  };
  handleClickPreviewMappedFields = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
    });
    this.props.history.push("/admin/previewmapfields");
  };
  handleClickSummarize = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
    });
    this.props.history.push("/admin/summarize");
  };

  goBackToDashBoard = () => {
    if (sessionStorage.getItem("user_name") === "Trustee") {
      this.props.history.push({
        pathname: "/admin/viewdetails",
        state: { details: this.state.detailsDate },
      });
      sessionStorage.setItem("isClosingTape", false);
    } else {
      this.props.history.push({
        pathname: "/dashboard/servicer",
      });
      sessionStorage.setItem("isClosingTape", false);
    }
  };

  // handleSort(column) {
  //   // Toggle sort direction if the same column is clicked
  //   const isAscending =
  //     this.state.sortColumn === column ? !this.state.sortAscending : true;

  //   this.setState({
  //     sortColumn: column,
  //     sortAscending: isAscending,
  //   });

  //   const sortedData = [...this.state.filteredData].sort((a, b) => {
  //     if (a[column] < b[column]) return isAscending ? -1 : 1;
  //     if (a[column] > b[column]) return isAscending ? 1 : -1;
  //     return 0;
  //   });

  //   this.setState({ filteredData: sortedData });
  // }

  render() {
    const { overlayVisible } = this.state;

    const {
      searchTerm,
      isDataFound,
      tableData,
      MapDataKeys,
      data,
      isSearchOpen,
      sortColumn,
      sortAscending,
    } = this.state;

    // const filteredData = searchTerm ? this.filterData(searchTerm) : tableData;
    const customData =
      this.state.filteredData.length > 0
        ? this.state.filteredData
        : this.state.tableData;
    console.log("MapDataKeys1", MapDataKeys);
    //     const MapFieldKeys = this.state.MapDataKeys[0]?.map((value, i) => value.key);
    // console.log("MapFieldKeys",MapFieldKeys);
    return (
      <React.Fragment>
        <div className={`page ${overlayVisible ? "blur-background" : ""}`}>
          <Sidebar1
            activeComponent={
              sessionStorage.getItem("user_name") === "Servicer"
                ? "Servicer Dashboard"
                : "Dashboard"
            }
          />
          <div className="content">
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div className="col-12 col-sm-6 col-md-2 d-flex justigy-content-center align-center hellocard">
                    <KeyboardBackspaceIcon
                      onClick={() => this.goBackToDashBoard()}
                      className="left-arrow-muis1 left-arrow-servicer"
                    ></KeyboardBackspaceIcon>
                    <h3 className="headerdashboard">SERVICER DETAILS</h3>
                  </div>
                  <div>
                    {/* <div className="buttonsverified uw-deal-details-button-container">
                   <div style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width:"50rem",
                      marginTop:"20px"
                    }}>
                        <div style={{"display":"flex"}}>
                          <label className="dealInfo">Deal Name </label>
                          <h6 className="dealInfo1">{this.state.DealName}</h6>
                        </div>
                        <div style={{"display":"flex"}}>
                          <label className="dealInfo">Payment Date </label>
                          <h6 className="dealInfo1">{this.state.detailsDate}</h6>
                        </div>
                      </div>
                   </div> */}
                    <div className="map-fields-details">
                      <div className="map-fields-details-item">
                        <label className="dealInfo">Deal Name : </label>
                        <h6 className="dealInfo1">
                          {sessionStorage.getItem("user_name") === "Trustee"
                            ? this.state.DealName
                            : this.state.servicerDealName ||
                              this.state.servicerDashboardName}
                        </h6>{" "}
                      </div>
                      <div className="map-fields-details-item">
                        <label className="dealInfo">Payment Date : </label>
                        <h6 className="dealInfo1">
                          {sessionStorage.getItem("user_name") === "Trustee"
                            ? this.state.detailsDate
                            : this.state.servicerPaymentDate ||
                              this.state.getdashboarddates}
                        </h6>{" "}
                      </div>
                      <div className="map-fields-details-item">
                        <label className="dealInfo">Servicer : </label>
                        <h6 className="dealInfo1">
                          {this.state.ServicerName}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row1">
                  <div>
                    <div className="tablechangebuttonloans">
                      <button
                        type="button"
                        onClick={() => this.handleClickUploadLoanTapes()}
                        className={
                          this.state.activeInsights1 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        Upload Loan Tapes
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickPreviewLoanTape()}
                        className={
                          this.state.activeInsights2 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Preview Loan Tape
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickMapFields()}
                        className={
                          this.state.activeInsights3 == true
                            ? "issuerDashboard-table-top-button-workbench-active"
                            : "issuerDashboard-table-top-button-workbench"
                        }
                      >
                        Map Fields
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickPreviewMappedFields()}
                        className={
                          this.state.activeInsights4 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Mapped Tapes
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickSummarize()}
                        className={
                          this.state.activeInsights5 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Summarize
                      </button>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <div>
                      <div className="search-container active">
                        <input
                          type="text"
                          value={searchTerm}
                          onChange={this.handleSearch}
                          placeholder="Search..."
                          className="search-input"
                        />
                        <span
                          className="search-icon"
                          onClick={this.handleSearchIconClick}
                        >
                          <SearchIcon />
                        </span>
                      </div>

                      {!isDataFound && <p>No data found.</p>}
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <button style={{ color: "#018e82" }}>
                        <OpenInFullIcon onClick={this.handleOpenModal} />
                      </button>
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ width: "7rem" }}
                      onClick={() => this.handleClickMapFields()}
                    >
                      Next
                    </Button>
                  </div>
                </div>

                <React.Fragment>
                  <div
                    style={{
                      overflow: "auto",
                      borderRadius: "10px",
                      border: "0.5px solid var(--tableBorder)",
                    }}
                  >
                    <TableVirtuoso
                      styles={{ overflowAnchor: "none" }}
                      style={{ height: 800 }}
                      data={customData}
                      components={TableComponents}
                      fixedHeaderContent={() => (
                        <TableRow>
                          {/* First Fixed Column Header (Index Column) */}
                          <TableCell
                            style={{
                              position: "sticky",
                              left: 0,
                              zIndex: 999,
                              width: "50px", // Adjust width as needed
                              color:"var(--headerText)",
                              background: "var(--white)",
                              borderRight: "none !important", // Add a subtle border
                            }}
                          ></TableCell>
                          {/* Second Fixed Column Header (OPID) */}
                          {/* <TableCell
                            style={{
                              position: "sticky",
                              left: "50px", // Same as the width of the first column
                              zIndex: 999,
                              width: "100px", // Adjust width as needed
                              background: "white",
                              borderRight: "1px solid #ddd", // Add a subtle border
                              cursor: "pointer",
                            }}
                            onClick={() => this.handleSort("OPID")}
                          >
                            <div style={{ display: "flex", gap: "6px" }}>
                              OPID
                              {sortColumn === "OPID" && (
                                <span>{sortAscending ? " ▲" : " ▼"}</span>
                              )}
                            </div>
                          </TableCell> */}
                          <TableCell
                            style={{
                              position: "sticky",
                              left: "50px",
                              zIndex: 999,
                              width: "100px",
                              color:"var(--headerText)",
                              background: "var(--white)",
                              borderRight: "none !important",
                              cursor: "pointer",
                            }}
                            onClick={() => this.handleSort(this.state.firstKey)} // Use dynamic sort column
                          >
                            <div style={{ display: "flex", gap: "6px" }}>
                              {this.state.firstKey}
                              {sortColumn === this.state.firstKey && (
                                <span>{sortAscending ? " ▲" : " ▼"}</span>
                              )}
                            </div>
                          </TableCell>

                          {/* Other Columns */}
                          {MapDataKeys.slice(1).map((column, index) => (
                            <TableCell
                              key={index}
                              style={{
                                color:"var(--headerText)",
                                background: "var(--white)",
                                borderRight: "none !important",
                                cursor: "pointer",
                                width: `${column.length + 1 * 5}rem`,
                                whiteSpace: "nowrap",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <span>{column}</span>
                                <OverlayTrigger
                                  trigger="click"
                                  placement="right"
                                  overlay={this.popoverBottom(column)}
                                  onExited={this.handleOverlayHide}
                                  rootClose={true}
                                >
                                  <img
                                    src={FilterMappedTape}
                                    alt="Filter"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "8px",
                                    }}
                                    onClick={this.handleButtonClick}
                                  />
                                </OverlayTrigger>
                              </div>
                            </TableCell>
                          ))}
                        </TableRow>
                      )}
                      itemContent={(index, row) => (
                        <>
                          {/* First Fixed Column (Index Column) */}
                          <TableCell
                            style={{
                              color:"var(--tableText)",
                              background: "var(--white)",
                              borderRight: "none !important",
                              position: "sticky",
                              left: 0,
                              zIndex: 1,
                              width: "50px", // Adjust width as needed                          
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          {/* Second Fixed Column (OPID) */}
                          <TableCell
                            style={{
                              color:"var(--tableText)",
                              background: "var(--white)",
                              borderRight: "none !important",
                              position: "sticky",
                              left: "50px", // Same as the width of the first column
                              zIndex: 1,
                              width: "100px", // Adjust width as needed
                              
                            }}
                          >
                            {row[this.state.firstKey]}
                          </TableCell>
                          {/* Other Columns */}
                          {MapDataKeys.slice(1).map((column, columnIndex) => (
                            <TableCell
                              key={columnIndex}
                              style={{
                                color:"var(--tableText)",
                              background: "var(--white)",
                              borderRight: "none !important",
                              }} // Add a subtle border
                            >
                              {row[column]}
                            </TableCell>
                          ))}
                        </>
                      )}
                    />
                  </div>
                </React.Fragment>
              </div>
            )}
          </div>
        </div>

        <>
          <ReactModal
            isOpen={this.state.open1}
            onRequestClose={this.handleCloseModal}
            style={customEditSpreadsheet}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                type="button"
                className="closePopup1"
                onClick={this.handleCloseModal}
              >
                <CloseIcon></CloseIcon>
              </button>
            </div>
            <React.Fragment>
              <div
                style={{
                  overflow: "auto",
                  borderRadius: "10px",
                  border: "0.5px solid black",
                  marginTop: "10px",
                }}
              >
                <TableVirtuoso
                  styles={{ overflowAnchor: "none" }}
                  style={{ height: 800 }}
                  data={customData}
                  components={TableComponents}
                  fixedHeaderContent={() => (
                    <TableRow>
                      <TableCell
                        style={{ background: "white", width: "100px" }}
                      ></TableCell>
                      {MapDataKeys.map((column, index) => (
                        <TableCell
                          key={index}
                          style={{
                            background: "white",
                            width: `${column.length * 10}px`,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                  itemContent={(index, row) => (
                    <>
                      <TableCell
                        style={{ background: "white", width: "100px" }}
                      >
                        {index + 1}
                      </TableCell>
                      {MapDataKeys.map((column, index, columnIndex) => (
                        <>
                          <TableCell
                            key={columnIndex}
                            style={{ background: "white" }}
                          >
                            {row[column]}
                          </TableCell>
                        </>
                      ))}
                    </>
                  )}
                />
              </div>
              {overlayVisible && (
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0)",
              zIndex: 999,
            }}
            onClick={this.handleOverlayHide}
          />
        )}
            </React.Fragment>
          </ReactModal>
        </>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Preview_LoanTape);
