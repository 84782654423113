import React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';


export default function LayoutRoute({ component: Component, ...rest }) {
  console.log(Component);
  return (
    <Route {...rest} render={(props) => (
      <LoginLayout>
        {Component &&
          <Component {...props} />
        }
      </LoginLayout>
    )} />
  );
}

function LoginLayout({ children }) {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row align-self-center custom-height main-container">

          <div className="left-container" id='signup-container'>
            <div className="logo-container">
            </div>
            <div className="login-content1 left-container-form align-self-center justify-content-center" id='signup-content'>
              {children}
            </div>
          </div>

          <div className="right-container d-flex custom-height login-left-image">
            <div className="login-content align-self-end text-start left-content">
              <div className='futureof'>
              <h1>Welcome to IntainADMIN Structured Finance Platform </h1>
                <p>Access all your deal information and insights with complete access control, privacy and transparency in a distributed ledger environment.</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </React.Fragment>
  );
}
