import React, { Component } from "react";
import { Table, Select, Button, Input, Spin, Pagination } from "antd";
import { withSnackbar } from "notistack";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  getalldeals,
  getDealDetailsByPaymentDate,
} from "../../../../../servies/services";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import { DownOutlined, FilterFilled } from "@ant-design/icons";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import fIlter from "../../../../../images/FilterMappedTape.svg";
import BeanEater from "../../../../../images/BeanEater.gif";
import ReactModal from "react-modal";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Search } = Input; // Import AntD Search component

// Function to get unique values for a column
const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      tableData: [],
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      paymentDates: [],
      versions: [],
      loading: false,
      openModalPayment: false,
      PaymentDate: "",
      singleRowPaymentDate: {},
      filterCriteria: {}, // Stores filter conditions
      sortOrder: {}, // Stores sort column and order
      displayData: [], // Filtered and sorted data for display
      currentBatch: 1, // Current batch of data to display
      itemsPerBatch: 50, // Number of items per batch
      currentPage: 1, // Current page
      pageSize: 10, // Number of rows per page
      filteredClrChangeColumns: {}, // Tracks which columns are filtered
      searchText: "",
    };
  }

  AddDeal = () => {
    sessionStorage.setItem("isAddDeal", true);
    this.props.history.push({
      pathname: "/admin/general",
      // state: { checkDeal: true },
    });
  };

  Recurring = (dealName, record) => {
    const paymentDate =
      this.state.paymentDates[record.key] ||
      record["Payment Date"][record["Payment Date"].length - 1];
    const version = this.state.versions[record.key] || record["Version"][0];

    // Safeguard against undefined values
    const getLastPaymentDates = this.state.getlastPaymentDates || [];
    const paymentDateValue = this.state.PaymentDate;

    // Calculate the last payment date
    const index = Array.isArray(getLastPaymentDates)
      ? getLastPaymentDates.indexOf(paymentDateValue)
      : -1;

    const lastPaymentDate =
      this.state.isDateChanged && index !== -1 && getLastPaymentDates[index + 1]
        ? getLastPaymentDates[index + 1]
        : "";

    // Update sessionStorage with all necessary data
    sessionStorage.setItem("dealname", dealName);
    sessionStorage.setItem("nextpaymentdate", paymentDate);
    sessionStorage.setItem("isdisable", true);
    sessionStorage.setItem("isLoansProcessed", record["Loans Processed"]);
    sessionStorage.setItem("Servicer", record["Servicer"]);
    sessionStorage.setItem("version", version);
    sessionStorage.setItem("lastpaymentdate", lastPaymentDate);
    sessionStorage.setItem(
      "getDashboardDeals",
      JSON.stringify(this.state.tableData.map((item) => item["Deal Name"]))
    );
    sessionStorage.setItem(
      "getDashboardDates",
      JSON.stringify(record["Payment Date"])
    );
    sessionStorage.setItem(
      "getDashboardVersions",
      JSON.stringify(record["Version"])
    );

    this.props.history.push({
      pathname: "/admin/general_recurring",
      state: { checkLoadStatus: true },
    });
  };

  General = (value) => {
    sessionStorage.setItem("dealname", value);
    this.props.history.push({
      pathname: "/admin/general",
      state: { checkDeal: false },
    });
  };

  ViewDetails = (dealName, record) => {
    const paymentDate =
      this.state.paymentDates[record.key] || record["Payment Date"][0];
    const version = this.state.versions[record.key] || record["Version"][0];

    // Update sessionStorage with all necessary data
    sessionStorage.setItem("dealname", dealName);
    sessionStorage.setItem("assettype", record["Asset Class"]);
    sessionStorage.setItem("version", version);
    sessionStorage.setItem("nextpaymentdate", paymentDate);
    sessionStorage.setItem(
      "getDashboardDeals",
      JSON.stringify(this.state.tableData.map((item) => item["Deal Name"]))
    );
    sessionStorage.setItem(
      "getDashboardDates",
      JSON.stringify(record["Payment Date"])
    );
    sessionStorage.setItem(
      "getDashboardVersions",
      JSON.stringify(record["Version"])
    );

    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        details: paymentDate,
        servicerList: record["Servicer"],
      },
    });
  };

  getalldeals = async () => {
    this.setState({ loading: true, tableData: [] });
    try {
      const response = await getalldeals({ peer: this.state.peer });
      if (response.status === 200) {
        const dealData = response.data;
        const { pageSize } = this.state;

        if (dealData.length > 0) {
          const getDealNames = dealData.map((item) => item["Deal Name"]);
          const firstDealName = getDealNames[0];

          // Extract data for the first deal name only
          const firstDeal = dealData.find(
            (item) => item["Deal Name"] === firstDealName
          );
          const firstDealDates = firstDeal?.["Payment Date"] || [];
          const firstDealServicer = firstDeal?.["Servicer"] || "";
          const firstDealVersion = firstDeal?.["Version"] || "";
          const firstDealLoansProcessed = firstDeal?.["Loans Processed"] || "";
          console.log(
            "firstDealLoansProcessed",
            firstDealLoansProcessed,
            firstDeal
          );
          // Convert to a flat array (avoid nested arrays)
          const flattenedVersion = Array.isArray(firstDealVersion)
            ? firstDealVersion[0]
            : firstDealVersion;

          // Set session storage
          sessionStorage.setItem("dealname", firstDealName || "");
          sessionStorage.setItem(
            "isLoansProcessed",
            firstDealLoansProcessed 
          );
          sessionStorage.setItem(
            "nextpaymentdate",
            firstDealDates[0] || ""
          );
          sessionStorage.setItem("lastpaymentdate", "");
          sessionStorage.setItem("Servicer", firstDealServicer || "");
          sessionStorage.setItem("version", flattenedVersion || "");
          sessionStorage.setItem(
            "getDashboardDeals",
            JSON.stringify(getDealNames)
          );
          sessionStorage.setItem(
            "getDashboardDates",
            JSON.stringify(firstDealDates)
          );
          sessionStorage.setItem(
            "getDashboardVersions",
            JSON.stringify([flattenedVersion]) // Ensure it's a flat array
          );
          sessionStorage.setItem("dashChanges", true);
        } else {
          // Handle empty deal data
          console.log("Deal Name or Payment Date is missing.");
          sessionStorage.setItem("dealname", "");
          sessionStorage.setItem("isLoansProcessed", "No");
          sessionStorage.setItem("nextpaymentdate", "");
          sessionStorage.setItem("lastpaymentdate", "");
          sessionStorage.setItem("Servicer", "");
          sessionStorage.setItem("version", "");
          sessionStorage.setItem("getDashboardDeals", "");
          sessionStorage.setItem("getDashboardDates", "");
          sessionStorage.setItem("getDashboardVersions", "");
          sessionStorage.setItem("dashChanges", false);
        }

        this.setState({
          tableData: dealData,
          displayData: dealData,
          paginatedData: dealData.slice(0, pageSize),
          loading: false,
        });
      } else {
        throw new Error("Error fetching data");
      }
    } catch (error) {
      this.setState({ loading: false });
      this.props.enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleSearch = (value) => {
    const filteredData = this.state.tableData.filter((item) =>
      Object.values(item).some((field) =>
        String(field).toLowerCase().includes(value.toLowerCase())
      )
    );
    this.setState({
      searchText: value,
      displayData: filteredData,
      currentPage: 1,
    });
  };

  clearSearch = () => {
    this.setState({
      searchText: "",
      displayData: this.state.tableData, // Reset to original data
    });
    this.inputRef.focus();
  };

  handleFilter = (tableName, data, columnKey, criteria) => {
    const { filterCriteria, tableData, pageSize } = this.state;

    const newCriteria = { ...filterCriteria, [columnKey]: criteria };
    this.setState({ filterCriteria: newCriteria });

    // Check if all criteria are empty
    const isCriteriaEmpty = Object.values(newCriteria).every(
      (criteria) => !criteria || !criteria.value1
    );

    let filtered = tableData;

    if (!isCriteriaEmpty) {
      // Apply filters
      filtered = tableData.filter((item) => {
        return Object.keys(newCriteria).every((key) => {
          const criteria = newCriteria[key];
          if (!criteria) return true;

          const { condition1, value1, operator, condition2, value2 } = criteria;

          const applyCondition = (value, condition, target) => {
            switch (condition) {
              case "equals":
                return value === target;
              case "less_than":
                return value < target;
              case "greater_than":
                return value > target;
              case "not_equals":
                return value !== target;
              default:
                return false;
            }
          };

          const condition1Met = applyCondition(item[key], condition1, value1);
          const condition2Met = applyCondition(item[key], condition2, value2);

          return operator === "AND"
            ? condition1Met && condition2Met
            : condition1Met || condition2Met;
        });
      });
    }

    const startIndex = 0;
    const endIndex = pageSize;

    this.setState({
      displayData: filtered,
      paginatedData: filtered.slice(startIndex, endIndex),
      currentPage: 1,
      filteredClrChangeColumns: {
        ...this.state.filteredClrChangeColumns,
        [columnKey]: !!criteria,
      },
    });
  };

  handleSort = (tableName, columnKey, order) => {
    const { tableData, itemsPerBatch } = this.state;
    const chunkSize = tableData.length; // Max 1000 rows or 10% of the dataset
    const sortedData = [];

    const sortChunk = (start) => {
      const end = Math.min(start + chunkSize, tableData.length);
      const chunk = tableData.slice(start, end).sort((a, b) => {
        const valueA = a[columnKey];
        const valueB = b[columnKey];

        if (!isNaN(valueA) && !isNaN(valueB)) {
          return order === "ascend" ? valueA - valueB : valueB - valueA;
        }

        if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
          return order === "ascend"
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
          return order === "ascend"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        if (valueA == null || valueB == null) {
          return valueA == null ? 1 : -1;
        }

        return 0;
      });

      sortedData.push(...chunk);

      if (end < tableData.length) {
        setTimeout(() => sortChunk(end), 0); // Process the next chunk
      } else {
        // Update state after all chunks are sorted
        this.setState({
          tableData: sortedData,
          displayData: sortedData.slice(0, itemsPerBatch),
        });
      }
    };

    sortChunk(0);
  };

  applyCurrentFilters = (tableData) => {
    const { filterCriteria } = this.state;

    if (!filterCriteria || Object.keys(filterCriteria).length === 0) {
      return tableData;
    }

    return tableData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const criteria = filterCriteria[key];
        if (!criteria || !criteria.value1) return true;

        const { condition1, value1, operator, condition2, value2 } = criteria;

        const applyCondition = (value, condition, target) => {
          switch (condition) {
            case "equals":
              return value === target;
            case "less_than":
              return value < target;
            case "greater_than":
              return value > target;
            case "not_equals":
              return value !== target;
            default:
              return true;
          }
        };

        const condition1Met = applyCondition(item[key], condition1, value1);
        const condition2Met = applyCondition(item[key], condition2, value2);

        return operator === "AND"
          ? condition1Met && condition2Met
          : condition1Met || condition2Met;
      });
    });
  };

  handlePaginationChange = (page) => {
    const { pageSize, displayData } = this.state;

    const startIndex = (page - 1) * pageSize;
    const endIndex = page * pageSize;
    const updatedPaginatedData = displayData.slice(startIndex, endIndex);

    this.setState({
      currentPage: page,
      paginatedData: updatedPaginatedData,
    });
  };

  handlePaymentDateChange = (event, value, rowIndex, tableData) => {
    const newValue = event.target.value;
    const updatedPaymentDates = [...this.state.paymentDates];
    updatedPaymentDates[rowIndex] = newValue;

    this.setState(
      {
        getlastPaymentDates: value,
        isDateChanged: true,
        getPaymentDateIndex: rowIndex,
        PaymentDate: newValue,
        openModalPayment: true,
        paymentDates: updatedPaymentDates,
        singleRowPaymentDate: tableData,
      },
      () => console.log("Updated PaymentDate State", this.state.PaymentDate)
    );
  };

  updatePaymentDateValue = async (newValue, rowData) => {
    console.log("newValue", newValue, rowData);
  
    // Construct the input object
    const input = {
      "Deal Name": rowData["Deal Name"],
      "Payment Date": rowData["Payment Date"],
      Version: rowData["Version"],
      "Loans Processed": rowData["Loans Processed"],
      "Deals Processed": rowData["Deals Processed"],
      "Last Modified": rowData["Last Modified"],
      "Asset Class": rowData["Asset Class"],
      Servicer: rowData["Servicer"],
    };
  
    console.log("input", input);
  
    // Prepare the data object for the API call
    const data = {
      input,
      selectedDate: newValue,
      peers: this.state.peers,
    };
  
    console.log("data", data);
  
    // Set initial state for the single row being updated
    this.setState({ singleRowPaymentDate: rowData, openModalPayment: true });
  
    try {
      // Make the API call
      const APIResponse = await getDealDetailsByPaymentDate(data);
      console.log("APIResponse", APIResponse);
  
      if (APIResponse.status === 200) {
        const updatedRow = APIResponse.data;
  
        // Update only the specific row in tableData
        const modifiedData = this.state.displayData.map((item) =>
          item["Deal Name"] === rowData["Deal Name"] ? updatedRow : item
        );
  
        console.log("modifiedData", modifiedData);
  
        // Update state with modified data
        this.setState(
          {
            getLoansLoader: false,
            displayData: modifiedData,
            loading: false,
            openModalPayment: false,
          },
          () => {
            console.log("Updated Table Data:", this.state.tableData);
          }
        );
  
        // Optionally, display a success message
        this.props.enqueueSnackbar("Payment Date updated successfully", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        throw new Error("API response unsuccessful");
      }
    } catch (error) {
      console.error("Error updating payment date:", error);
  
      // Handle failure scenario
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
  
      this.props.enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  
  
  handleVersionChange = (event, index) => {
    const newValue = event.target.value;
  
    // Create a copy of the versions array and update the specific index
    const updatedVersions = [...this.state.versions];
    updatedVersions[index] = newValue;
  
    console.log("newValue", newValue);
    console.log("updatedVersions", updatedVersions);
  
    // Update the state with the new version selection
    this.setState({
      versionSelected: newValue,
      versions: updatedVersions,
      singleRowVersion: this.state.tableData[index],
      getDashboardVersions: this.state.tableData[index].Version,
    });
  };
  

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.PaymentDate !== this.state.PaymentDate ||
      prevState.getPaymentDateIndex !== this.state.getPaymentDateIndex
    ) {
      const { PaymentDate, singleRowPaymentDate } = this.state;
      this.updatePaymentDateValue(PaymentDate, singleRowPaymentDate);
    }
  }

  componentDidMount() {
    const component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.removeItem("dealname");
    sessionStorage.setItem("isTabChange", false);
    sessionStorage.setItem("isClosingTape", false);
    sessionStorage.setItem("AddedContent", []);
    sessionStorage.setItem("TranchItems", []);
    sessionStorage.setItem("isESMA_Flag", "No");
    this.getalldeals();
  }

  render() {
    const { displayData, currentPage, pageSize , searchText } = this.state;

    const paginatedData = displayData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    const columns = [
      {
        title: "Deal Name",
        dataIndex: "Deal Name",
        key: "Deal Name",
        fixed: "left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Deal Name"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Deal Name")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px",
              height: "10px",
              color: this.state.filteredClrChangeColumns["Deal Name"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Payment Date",
        dataIndex: "Payment Date",
        key: "Payment Date",
        render: (dates, record, index) => (
          <select
          
            className="input-select-general-new2"
            value={this.state.paymentDates[index] || dates[0]}
            onChange={(e) =>
              this.handlePaymentDateChange(
                e,
                record["Payment Date"],
                index,
                record
              )
            }
            style={{
              backgroundColor: "transparent",
              color: "var(--button)",
              border: "0px",
              borderRadius: "2px",
              outline: "none",
              paddingRight: "30px",
            }}
          >
            {dates.map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
        ),
      },

      {
        title: "Version",
        dataIndex: "Version",
        key: "Version",
        render: (versions, record, index) => (
          <select
            className="input-select-general-new2"
            value={this.state.versions[index] || versions[0]} // Default to the first version if not set
            onChange={(event) => this.handleVersionChange(event, index)}
            style={{
              backgroundColor: "transparent",
              color: "var(--button)",
              border: "0px",
              borderRadius: "2px",
              outline: "none",
              paddingRight: "30px",
            }}
          >
            {[...new Set(versions)].map((version) => (
              <option key={version} value={version}>
                {version}
              </option>
            ))}
          </select>
        ),
      },
      
      {
        title: "Loans Processed",
        dataIndex: "Loans Processed",
        key: "Loans Processed",
        align: "center",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Loans Processed"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(
              this.state.tableData,
              "Loans Processed"
            )}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px",
              height: "10px",
              color: this.state.filteredClrChangeColumns["Loans Processed"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Deals Processed",
        dataIndex: "Deals Processed",
        key: "Deals Processed",
        align: "center",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Deals Processed"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(
              this.state.tableData,
              "Deals Processed"
            )}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px",
              height: "10px",
              color: this.state.filteredClrChangeColumns["Deals Processed"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Actions",
        key: "Actions",
        align: "center",
        render: (_, record) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="popupdashboard-trustee"
              onClick={() => this.Recurring(record["Deal Name"], record)}
            >
              Deal
            </button>
            <button
              style={{ paddingRight: "2rem" }}
              className="popupdashboard-trustee"
              onClick={() => this.ViewDetails(record["Deal Name"], record)}
            >
              Loans
            </button>
          </div>
        ),
      },

      {
        title: "Last Modified",
        dataIndex: "Last Modified",
        key: "Last Modified",
        align: "center",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Last Modified"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(
              this.state.tableData,
              "Last Modified"
            )}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px",
              height: "10px",
              color: this.state.filteredClrChangeColumns["Last Modified"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Asset Class",
        dataIndex: "Asset Class",
        key: "Asset Class",
        align: "center",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Asset Class"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Asset Class")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px",
              height: "10px",
              color: this.state.filteredClrChangeColumns["Asset Class"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Servicer",
        dataIndex: "Servicer",
        key: "Servicer",
        align: "center",

        render: (value, record, index) => {
          const handlePopoverOpen = (event, value) => {
            this.setState({
              anchorEl: event.currentTarget,
              servicerValues: value || [],
              open: true,
            });
          };

          const handlePopoverClose = () => {
            this.setState({
              anchorEl: null,
              open: false,
            });
          };

          const open = this.state?.open || false;

          return (
            <React.Fragment>
              <div
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={(e) => handlePopoverOpen(e, value)}
                onMouseLeave={handlePopoverClose}
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: "3rem",
                  cursor: "pointer",
                }}
              >
                <ul className="servicer_items">
                  <li>
                    {[value[0], value[1], value[2]]
                      .filter((item) => item !== undefined)
                      .join(", ")}
                  </li>
                </ul>
              </div>

              {this.state?.servicerValues?.length > 3 ? (
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: "none",
                  }}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    style: { backgroundColor: "var(--white)", color: "var(--tableText)" }, // Add custom styles
                  }}
                >
                  {this.state.servicerValues
                    .map((item, idx) => (
                      <Typography
                        sx={{ p: 2 }}
                        key={idx}
                        style={{ margin: "1rem" }}
                      >
                        {item}
                      </Typography>
                    ))
                    .filter((_, idx) => idx > 2)}
                </Popover>
              ) : null}
            </React.Fragment>
          );
        },
      },
    ];

    return (
      <div className="page">
        <Sidebar1 activeComponent={"Dashboard"} />
        <div className="content">
          <div className="page-content-Dashboard">
            <div className="row row1">
              <h1 className="headerdashboard-trustee">DASHBOARD</h1>
              <div className="dashboard-top-right-container1">
              <Search
              ref={(input) => (this.inputRef = input)}
          placeholder="Search..."
          enterButton={false}
          className="custom-search"
          value={searchText}
          onChange={(e) => this.handleSearch(e.target.value)}
          onSearch={this.handleSearch}
          style={{ width: 300 }}
          autoFocus
          suffix={
            searchText && (
              <CloseCircleOutlined
                onClick={this.clearSearch}
                style={{ cursor: "pointer" }}
              />
            )
          }
        />
                <div className="search-bar-outer-container uw-search-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={this.AddDeal}
                  >
                    Add Deal
                  </Button>
                </div>
              </div>
            </div>
            <Table
              dataSource={paginatedData.map((item, index) => ({
                ...item,
                key: index,
              }))}
              columns={columns}
              className="custom-antds-dash-table"
              pagination={false}
              loading={this.state.loading}
              scroll={{ x: "max-content", y: 600 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
                overflow: "hidden",
              }}
            >
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={displayData.length}
                onChange={this.handlePaginationChange}
                itemRender={(page, type, originalElement) => {
                  if (type === "page") {
                    return (
                      <div
                        className={`custom-pagination-item ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        {page}
                      </div>
                    );
                  }
                  return originalElement;
                }}
              />
            </div>

            <ReactModal
              isOpen={this.state.openModalPayment}
              contentLabel="Minimal Modal Example"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "fixed", // Use fixed positioning for the overlay
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                },
                content: {
                  position: "absolute",
                  background: "transparent", // Make the modal content transparent
                  border: "none", // Optional: Remove any borders
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  overflow: "hidden",
                },
              }}
            >
              <React.Fragment>
                <img
                  src={BeanEater}
                  style={{
                    width: "8vw",
                    height: "9vh",
                    backgroundColor: "transparent",
                  }}
                />
                <h3 className="dot-loader">Loading.Please wait</h3>
              </React.Fragment>
            </ReactModal>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(Dashboard);
