/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FormLoader from "../../../components/formLoader";
import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";
import LinkItem from "../../../components/linkItem";
import {
  CustomFieldTemplate,
  widgets,
} from "../../../components/customscripts/customscript";
import { withSnackbar } from "notistack";
import $ from "jquery";
import {
  registerUser,
  authenticate,
  getAllUserRoles,
} from "../../../servies/services";

// import Footer from '../../../components/footer/footer'

const Form = withTheme(MuiTheme);
// const schema = require('./schema.json');
// const uiSchema = {
//   'password': {
//     'ui:widget': 'password',
//   }
// };

class register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   schema: schema,
      // uiSchema: uiSchema,
      formData: {
        UserName: "",
        EmailId: "",
        OrgName: "",
      },
      token: localStorage.getItem("token"),
      peers: JSON.parse(localStorage.getItem("peers")),
      userid: localStorage.getItem("userid"),
      OrgLoading: false,
    };
  }

  // onSubmit = (value) => {
  //   console.log('onSubmit:', value.formData)
  //   let data = value.formData;
  //   this.register(data);
  // };

  async componentDidMount() {
    localStorage.clear();
    this.getAllUserRoles();

    if (sessionStorage.getItem("OrgName") === null) {
      this.getAllUserRoles();
    } else {
      const OrgName = JSON.parse(sessionStorage.getItem("OrgName"));
      this.setState({ OrgLoading: true });
      this.setState({ role_list: OrgName });
    }
  }

  onChange = (e) => {
    const formData = this.state.formData;
    formData[e.target.name] = e.target.value;
    this.setState({
      formData,
    });
    console.log("formData", this.state.formData);
  };

  getAllUserRoles = async () => {
    // this.setState({ OrgLoading: false })
    // const APIResponse = await getAllUserRoles()
    // console.log("getAllUserRoles", APIResponse.data)

    // let orgname_id = []
    // let orgname_name = []
    // if (APIResponse.data.length !== 0) {
    //   APIResponse.data.forEach(function (key, value) {
    //     console.log("key", key.UserRoleID)
    //     console.log("value", value)
    //     // var obj = { name: key, label: key }
    //     orgname_id.push(key.UserRoleID);
    //     orgname_name.push(key.UserRoleName);
    //   });
    // }
    // console.log("APIResponse.data", APIResponse.data);
    // let oldSchema = this.state.schema;
    // console.log("oldstagedata", oldSchema);
    // oldSchema.properties.OrgName.enum = orgname_name;
    // oldSchema.properties.OrgName.enumNames = orgname_name;
    // const newSchema = Object.assign({}, oldSchema);
    // console.log("WRITE oldSchema", newSchema);
    // this.setState({ schema: newSchema });
    // this.setState({ OrgLoading: true, OrgDetails: APIResponse.data })

    const APIResponse = await getAllUserRoles();
    if (APIResponse != null) {
      console.log("getAllUserRoles APIResponse.data", APIResponse.data);
      this.setState({
        OrgLoading: true,
        role_list: APIResponse.data,
        OrgDetails: APIResponse.data,
      });
      sessionStorage.setItem("OrgName", JSON.stringify(APIResponse.data));
    } else {
      this.setState({ OrgLoading: true, OrgDetails: APIResponse.data });
    }

    // sessionStorage.setItem("OrgName", JSON.stringify(newSchema))
  };

  onSubmit = async (value) => {
    // console.log('onSubmit:', value.formData)
    // let data = value.formData;

    // const UserRoleID = this.state.OrgDetails.filter(item => item.UserRoleName == value.formData.OrgName)
    // console.log("++++++++++" + JSON.stringify(UserRoleID[0].UserRoleID))
    // data.UserRoleID = UserRoleID[0].UserRoleID
    // const APIResponse = await registerUser(data)
    console.log("this.state.formData", this.state.formData);
    if (
      !this.state.formData.UserName ||
      !this.state.formData.EmailId ||
      !this.state.formData.OrgName
    ) {
      const message = "Please fill in all required fields.";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      return; // Exit the function to prevent further execution
    }

    // Check if the email is in a valid format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(this.state.formData.EmailId)) {
      const message = "Please enter a valid email address.";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      return; // Exit the function
    }

    // // Check if the username is already present (you need to implement this logic)
    // const isUsernameUnique = await this.checkUsernameUniqueness(
    //   this.state.formData.UserName
    // );

    // if (!isUsernameUnique) {
    //   const message = "Username is already taken. Please choose another.";
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 5000,
    //   });
    //   return; // Exit the function
    // }
     else {
      const UserRole = $("#userroleid option:selected").attr("data-type");
      const UserRoleID = $("#userroleid option:selected").attr(
        "data-userroleid"
      );

      this.setState({ loading: true });

      var data = this.state.formData;
      console.log("DATA", data);
      data.UserRoleID = UserRoleID;

      console.log("RegisterDATA", data);
      const APIResponse = await registerUser(UserRole, data);
      console.log("registerUser", APIResponse, APIResponse.status);
      this.setState({ loading: false });
      if (APIResponse !== null) {
        if (APIResponse.status == 204) {
          const message = "Missing Parameter or No content";
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 4000,
          });
        } else if (APIResponse.status !== 200) {
          const message = "Something went wrong, please try again";
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          if (APIResponse.data.isSuccess == false) {
            this.setState({ formLoader: false });
            const message = "Credentials Incorrect";
            this.props.enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 2000,
            });
          } else {
            this.setState({ formLoader: false });
            const message = "Registered successfully!";
            this.props.enqueueSnackbar(message, {
              variant: "info",
              autoHideDuration: 2000,
            });

            this.props.history.push("/");
          }
        }
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <h3>Sign up to your account to Access IntainADMIN</h3>
        <div className="form_row_single">
          {this.state.OrgLoading === false ? (
            <React.Fragment>
              <FormLoader></FormLoader>
              <p className="loading_text">Loading, Please wait...</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="loginForm">
                <div className="input-container">
                  <label className="label">Username</label>
                  <input
                    id=""
                    name="UserName"
                    onChange={this.onChange}
                    className="input"
                    type="text"
                    value={this.state.formData.UserName}
                    required
                  />
                </div>
                <div className="input-container">
                  <label className="label">Email Address</label>
                  <input
                    name="EmailId"
                    type="email"
                    onChange={this.onChange}
                    className="input"
                    value={this.state.formData.EmailId}
                    required
                  />
                </div>
                <p>
                  <label>Role</label>
                  <select
                    name="OrgName" // Change the name to "OrgName"
                    id="userroleid"
                    onChange={this.onChange}
                    className="form-control"
                    required
                    value={this.state.formData.OrgName} // Set the selected value to formData.OrgName
                  >
                    <option value={""}>{"Select OrgName"}</option>
                    {this.state.role_list.length !== 0
                      ? this.state.role_list.map((key, value) => {
                          return key.UserRoleName.includes("inv") ||
                            key.UserRoleName.includes("issuer") ||
                            key.UserRoleName.includes("trustee") ||
                            key.UserRoleName.includes("servicer") ? (
                            <option
                              data-type={key.UserRoleName}
                              data-userroleid={key.UserRoleID}
                              key={key.UserRoleID}
                              value={key.UserRoleName}
                            >
                              {key.UserRoleName}
                            </option>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </select>
                </p>

                <p>
                  <Button
                    className="col-md-12"
                    onClick={this.onSubmit}
                    variant="contained"
                    size="large"
                    color="primary"
                    id="signinbutton"
                    type="submit"
                    disabled={this.state.loading === true ? true : false}
                  >
                    {" "}
                    Sign Up{" "}
                  </Button>
                </p>
              </div>
            </React.Fragment>
          )}
          <div className="loginCheckbox">
            <p className="lineDivider">
              <span>Have Account?</span>
            </p>
            <LinkItem
              to={"/"}
              variant="contained"
              className="loginBtn"
              title={"Login"}
            >
              {" "}
            </LinkItem>

            {/* <div class="text-center Poweredby">Powered by <img id="wsfs_logo_first" style={{ width: "70px", height: "30px", marginTop:"-15px" }}
                                // src={"http://in-d.ai/wp-content/uploads/2020/11/WSFSLogo.png"
                                          src={"https://wsfs.intainabs.com/logo.jpg"
                                }
                            /></div> */}
          </div>
        </div>
        {/* <Footer></Footer> */}
      </React.Fragment>
    );
  }
}
export default withSnackbar(register);
