/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import {
  dealCreationAddSave,
  dealCreationTable,
  dealCreationCreateUpdate,
  dealCreationDelete,
  dealCreationSaveForNow,
  dealCreation_BC_Status,
  dealCreationAutoSave,
  dealCreationEdit,
  savedealservicerdate,
} from "../../../../../servies/services";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import ReactModal from "react-modal";
import {
  customStylesServicer,
  customStylesauto,
} from "../../../../../components/customscripts/customscript";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import NumberComp4 from "../../../../../components/NumberComp4";
import { customStylesautosmallmodal1 } from "../../../../../components/customscripts/customscript";
import FormLoader from "../../../../../components/loader/formLoader";
import { Table, Tooltip, Typography, Checkbox } from "antd";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import { Pagination } from "antd"; // Import AntD Pagination
import fIlter from "../../../../../images/FilterMappedTape.svg"

// Function to get unique values for a column
const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};


class Test_DealCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      rowsSelected: null,
      open1: false,
      open2: false,
      open3: false,
      viewModal: false,
      screenloader: false,
      TableName: "Tests",
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      dealid: sessionStorage.getItem("dealid"),
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      isRecurring: sessionStorage.getItem("isAddDeal"),
      isSecuritisation: sessionStorage.getItem("isSecuritisation"),
      formData: {
        Name: "",
        Label: "",
        Expression: "",
        "Pass Fail": "",
        "Limit Type": "",
        Limit: "",
        "Consequence for Breach": "",
        "Report Flag": "",
        "Data Type": "",
      },
      formData1: {
        Name: "",
        Label: "",
        Expression: "",
        "Pass Fail": "",
        "Limit Type": "",
        Limit: "",
        "Consequence for Breach": "",
        "Report Flag": "",
        "Data Type": "",
      },
      deleteRowVal: {
        Name: "",
      },
      ViewStatus: false,
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
      filterCriteria: {},
      sortOrder: {},
      displayData: [],
      currentBatch: 1,
      itemsPerBatch: 50,
      currentPage: 1,
      pageSize: 10,
      filteredClrChangeColumns: {}, 
    };
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  handleClickGeneral = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/general");
  };

  handleClickTranches = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/tranches");
  };
  handleClickFees = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/fees");
  };
  handleClickExpenses = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/expenses");
  };
  handleClickAccounts = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/account");
  };

  handleClickTests = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
  };
  handleClickBorrowingBase = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: true,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/tests");
  };
  handleClickVariables = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: true,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/variables");
  };
  handleClickPaymentRules = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: true,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/paymentrules");
  };

  handleClickManualInputs = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: true,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/paymentrules");
  };
  onOpenModal = () => {
    console.log("add Test modal");
    this.setState({
      open1: true,
      formData: {
        Name: "",
        Label: "",
        Expression: "",
        "Pass Fail": "",
        "Limit Type": "",
        Limit: "",
        "Consequence for Breach": "NA",
        "Report Flag": "No",
        "Data Type": "",
      },
    });
  };

  onViewModal = (value, record) => {
    console.log("value:", value);
    console.log("record:", record);
  
    // Safely extract values from record, with fallback defaults
    this.setState({
      ViewStatus: true,
      viewModal: true,
      EditPreviousName: record.Name,
      formData1: {
        Name: record.Name,
        Label: record.Label,
        Expression: record.Expression ,
        "Pass Fail": record["Pass Fail"] ,
        "Limit Type": record["Limit Type"] ,
        Limit: record.Limit ,
        "Consequence for Breach": record["Consequence for Breach"],
        "Report Flag": record["Report Flag"],
        "Data Type": record["Data Type"] ,
      },
    });
  };
  
  
  onOpenModal1 = (value, editValue) => {
    console.log("value: ", value, editValue);
    console.log("edit test modal");
  
    // Prepare form data with safe defaults
    const formData = {
      Name: editValue?.Name || "",
      Label: editValue?.Label || "",
      Expression: editValue?.Expression || "",
      "Pass Fail": editValue?.["Pass Fail"] || "",
      "Limit Type": editValue?.["Limit Type"] || "",
      Limit: editValue?.Limit || "",
      "Consequence for Breach": editValue?.["Consequence for Breach"] || "NA",
      "Report Flag": editValue?.["Report Flag"] || "No",
      "Data Type": editValue?.["Data Type"] || "",
    };
  
    if (!this.state.ViewStatus) {
      // When ViewStatus is false
      this.setState({
        ViewStatus: false, // Optional: Ensuring consistency if ViewStatus must be explicitly false
        EditPreviousName: value,
        open2: true,
        viewModal: false,
        formData1: formData,
      }, () => {
        console.log("ViewStatus is false.");
      });
    } else {
      // When ViewStatus is true
      this.setState({
        open2: true,
        viewModal: false,
      }, () => {
        console.log("ViewStatus is true.");
      });
    }
  };
  
  
  onCloseModal = () => {
    this.setState({ open1: false });
  };

  onCloseModalView = () => {
    this.setState({ viewModal: false });
  };

  onCloseModal1 = () => {
    this.setState({ open2: false, viewModal: false });
  };

  onCloseModal2 = () => {
    this.setState({ open3: false });
  };

  AddSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.formData);
    this.dealCreationAddSave();
    console.log("hello we have clicked the button");
  };
  EditSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.formData1);
    this.dealCreationEdit();
    console.log("hello we have clicked the Edit button");
  };

  showNext = (e) => {
    e.preventDefault();
    const isSecuritisation = this.state.isSecuritisation;
    const activeInsights7Value = isSecuritisation === "Securitisation";
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: activeInsights7Value,
      activeInsights8: false,
      activeInsightsBB: !activeInsights7Value,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/variables");
  };
  showPrev = (e) => {
    e.preventDefault();
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/account");
  };

  onOpenModal2 = (value, deleteValue) => {
    console.log("value: ", value, deleteValue);
    console.log("delete test modal");
  
    // Prepare delete row data with safe defaults
    const deleteRowVal = {
      Name: deleteValue.Name,
      Label: deleteValue.Label,
      Expression: deleteValue.Expression,
      "Pass Fail": deleteValue["Pass Fail"],
      "Limit Type": deleteValue["Limit Type"],
      Limit: deleteValue.Limit,
      "Consequence for Breach": deleteValue["Consequence for Breach"],
      "Report Flag": deleteValue["Report Flag"],
      "Data Type": deleteValue["Data Type"],
    };
  
    // Update the state for the delete modal
    this.setState({
      open3: true,
      deleteRowVal: deleteRowVal,
    });
  };

  dealCreationDelete = async () => {
    this.setState({ formLoader: true });
    let data = {};
    data.peers = this.state.peers;
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    const NameAdd1 = this.state.deleteRowVal.Name;
    const Obj = {
      [`${NameAdd1}`]: {
        ...this.state.deleteRowVal,
      },
    };
    data.Deleterow = Obj;
    data.peers = this.state.peers;
    console.log("dataDelete", data);
    const APIResponse = await dealCreationDelete(data);

    if (APIResponse.status === 200) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal2();
      this.savedealservicerdate();
      this.dealCreationTable();
    } else {
      this.setState({ formLoader: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  dealCreationAddSave = async () => {
    this.setState({ formLoader: true });
    let data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    const NameAdd = this.state.formData.Name;
    const ObjAdd = {
      [`${NameAdd}`]: {
        ...this.state.formData,
      },
    };
    data.TableData = ObjAdd;
    data.peers = this.state.peers;
    console.log("dataAddddd", data);

    const APIResponse = await dealCreationAddSave(data);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.savedealservicerdate();
        this.dealCreationTable();
        this.onCloseModal();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal();
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal();
    }
  };

  dealCreationEdit = async () => {
    this.setState({ formLoader: true });
    let data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    data.EditKey = this.state.EditPreviousName;
    const NameAdd = this.state.formData1.Name;
    const ObjAdd = {
      [`${NameAdd}`]: {
        ...this.state.formData1,
      },
    };
    data.TableData = ObjAdd;
    data.peers = this.state.peers;
    console.log("dataAddddd", data);

    const APIResponse = await dealCreationEdit(data);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.savedealservicerdate();
        this.dealCreationTable();
        this.onCloseModal1();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal1();
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal1();
    }
  };

  dealCreationAutoSave = async () => {
    var data = {};
    data.DealName = this.state.DealName;
    data.peers = this.state.peers;
    data.TableName = this.state.TableName;
    let Obj = {};
    this.state.tableData.forEach((item) => {
      let modifiedItem = { ...item };
      Object.keys(this.state.formData1).forEach((key) => {
        if (typeof modifiedItem[key] === "undefined") {
          modifiedItem[key] =
            this.state.formData1[key] === undefined
              ? ""
              : this.state.formData1[key];
        }
      });

      Obj = {
        ...Obj,
        [`${item.Name}`]: modifiedItem,
      };
    });
    data.TableData = Obj;
    console.log(data);
    const APIResponse = await dealCreationAutoSave(data);
    this.savedealservicerdate();
    console.log("ress", APIResponse);
  };

  dealCreationCreateUpdate = async () => {
    this.setState({ formLoader2: true });
    var data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    data.peers = this.state.peers;
    let Obj = {};
    this.state.tableData.forEach((item) => {
      // Create a new object for each item
      let modifiedItem = { ...item };

      // Apply the condition to each key in formData1
      Object.keys(this.state.formData1).forEach((key) => {
        if (typeof modifiedItem[key] === "undefined") {
          modifiedItem[key] =
            this.state.formData1[key] === undefined
              ? ""
              : this.state.formData1[key];
        }
      });

      Obj = {
        ...Obj,
        [`${item.Name}`]: modifiedItem,
      };
    });
    data.TableData = Obj;
    console.log(data);
    const APIResponse = await dealCreationCreateUpdate(data);
    console.log("ress", APIResponse);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader2: false });
        this.dealCreation_BC_Status();
        this.savedealservicerdate();
        this.dealCreationTable();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader2: false });
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader2: false });
    }
  };

  dealCreationSaveForNow = async () => {
    this.setState({ formLoader1: true });
    var data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    data.peers = this.state.peers;
    let Obj = {};
    this.state.tableData.forEach((item) => {
      // Create a new object for each item
      let modifiedItem = { ...item };

      // Apply the condition to each key in formData1
      Object.keys(this.state.formData1).forEach((key) => {
        if (typeof modifiedItem[key] === "undefined") {
          modifiedItem[key] =
            this.state.formData1[key] === undefined
              ? ""
              : this.state.formData1[key];
        }
      });

      Obj = {
        ...Obj,
        [`${item.Name}`]: modifiedItem,
      };
    });
    data.TableData = Obj;
    console.log(data);
    const APIResponse = await dealCreationSaveForNow(data);
    console.log("ress", APIResponse);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.savedealservicerdate();
        this.setState({ formLoader1: false });
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader1: false });
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader1: false });
    }
  };

  dealCreationTable = async () => {
    this.setState({
      getLoansLoader: true,
      tableData: [],
      loading: true,
      screenloader: true,
    });
    var data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    data.peer = this.state.peer;

    console.log("datata", data);
    this.setState({ screenloader: true });
    const APIResponse = await dealCreationTable(data);

    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        displayData:APIResponse.data,
        loading: false,
      });
      this.dealCreation_BC_Status();
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  dealCreation_BC_Status = async () => {
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;
    console.log(data);
    const APIResponse = await dealCreation_BC_Status(data);
    console.log("ress", APIResponse);
    this.setState({
      createStatus: APIResponse.data.status,
      screenloader: false,
    });
  };

  savedealservicerdate = async () => {
    let data = {};
    data.DealName = this.state.DealName;
    data.peers = this.state.peers;
    console.log(data);
    const APIResponse = await savedealservicerdate(data);
    console.log("ress", APIResponse);
    if (this.state.activeInsights1 === true) {
      this.props.history.push({
        pathname: "/admin/general",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights2 === true) {
      this.props.history.push({
        pathname: "/admin/tranches",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights3 === true) {
      this.props.history.push({
        pathname: "/admin/fees",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights4 === true) {
      this.props.history.push({
        pathname: "/admin/expenses",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights5 === true) {
      this.props.history.push({
        pathname: "/admin/account",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights6 === true) {
      this.props.history.push({
        pathname: "/admin/tests",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsightsBB === true) {
      this.props.history.push({
        pathname: "/admin/borrowingBase",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights7 === true) {
      this.props.history.push({
        pathname: "/admin/variables",
      });
    } else if (this.state.activeInsights8 === true) {
      this.props.history.push({
        pathname: "/admin/paymentrules",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsightsMI === true) {
      this.props.history.push({
        pathname: "/admin/manualinput",
        state: { checkDeal: false },
      });
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    this.dealCreationTable();
  }
  goBackToDashBoard = () => {
    this.state.isRecurring
      ? this.props.history.push({
          isRecurring: sessionStorage.removeItem("isAddDeal"),
          pathname: "/dashboard",
        })
      : this.props.history.push({
          pathname: "/admin/tests_recurring",
        });
  };

     handleFilter = (tableName, data, columnKey, criteria) => {

      const { filterCriteria, tableData, currentBatch, itemsPerBatch } =
        this.state;
  
      const newCriteria = { ...filterCriteria, [columnKey]: criteria };
      this.setState({ filterCriteria: newCriteria });
  
      // Check if all criteria are empty
      const isCriteriaEmpty = Object.values(newCriteria).every(
        (criteria) => !criteria || !criteria.value1
      );
  
      if (isCriteriaEmpty) {
        // Reset to the first batch of the full dataset if no criteria are selected
        this.setState({
          displayData: tableData.slice(0, currentBatch * itemsPerBatch),
          filteredClrChangeColumns: {
            ...this.state.filteredClrChangeColumns,
            [columnKey]: false,
          },
        });
        return;
      }
  
      // Apply filters
      const filtered = tableData.filter((item) => {
        return Object.keys(newCriteria).every((key) => {
          const criteria = newCriteria[key];
          if (!criteria) return true;
  
          const { condition1, value1, operator, condition2, value2 } = criteria;
  
          const applyCondition = (value, condition, target) => {
            switch (condition) {
              case "equals":
                return value === target;
              case "less_than":
                return value < target;
              case "greater_than":
                return value > target;
              case "not_equals":
                return value !== target;
              default:
                return false;
            }
          };
  
          const condition1Met = applyCondition(item[key], condition1, value1);
          const condition2Met = applyCondition(item[key], condition2, value2);
  
          return operator === "AND"
            ? condition1Met && condition2Met
            : condition1Met || condition2Met;
        });
      });
  
      console.log({ filtered });
  
      // Apply sorting after filtering
      const { sortOrder } = this.state;
      const sortedFiltered = [...filtered].sort((a, b) => {
        const valueA = a[sortOrder.columnKey];
        const valueB = b[sortOrder.columnKey];
  
        if (!isNaN(valueA) && !isNaN(valueB)) {
          return sortOrder.order === "ascend" ? valueA - valueB : valueB - valueA;
        }
  
        if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
          return sortOrder.order === "ascend"
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
        }
  
        if (typeof valueA === "string" && typeof valueB === "string") {
          return sortOrder.order === "ascend"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }
  
        return 0;
      });
  
      // Update display data to only show the current batch
      this.setState({
        displayData: sortedFiltered.slice(0, currentBatch * itemsPerBatch),
        filteredClrChangeColumns: {
          ...this.state.filteredClrChangeColumns,
          [columnKey]: !!criteria,
        },
      });
    };

    handleSort = (tableName, columnKey, order) => {
      const { tableData, itemsPerBatch } = this.state;
  
      // Determine the chunk size based on the total dataset size
      const chunkSize = tableData.length; // Max 1000 rows or 10% of the dataset
      const sortedData = [];
  
      const sortChunk = (start) => {
        const end = Math.min(start + chunkSize, tableData.length);
        const chunk = tableData.slice(start, end).sort((a, b) => {
          const valueA = a[columnKey];
          const valueB = b[columnKey];
  
          if (!isNaN(valueA) && !isNaN(valueB)) {
            return order === "ascend" ? valueA - valueB : valueB - valueA;
          }
  
          if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
            return order === "ascend"
              ? new Date(valueA) - new Date(valueB)
              : new Date(valueB) - new Date(valueA);
          }
  
          if (typeof valueA === "string" && typeof valueB === "string") {
            return order === "ascend"
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          }
  
          if (valueA == null || valueB == null) {
            return valueA == null ? 1 : -1;
          }
  
          return 0;
        });
  
        sortedData.push(...chunk);
  
        if (end < tableData.length) {
          setTimeout(() => sortChunk(end), 0); // Process the next chunk
        } else {
          // Update state after all chunks are sorted
          this.setState({
            tableData: sortedData,
            displayData: sortedData.slice(0, itemsPerBatch),
          });
        }
      };
  
      sortChunk(0);
    };
  
    handlePaginationChange = (page) => {
      this.setState({ currentPage: page });
    };

  render() {
    const { displayData, currentPage, pageSize, loading } = this.state;
    const paginatedData = displayData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    const columns = [
      {
        title: "Name",
        dataIndex: "Name",
        key: "name",
        fixed:"left",
        align:"left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Name"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Name")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Name"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Label",
        dataIndex: "Label",
        key: "Label",
        align:"left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Label"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Label")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Label"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Expression",
        dataIndex: "Expression",
        key: "Expression",
        align:"left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Expression"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Expression")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Expression"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) =>
          typeof value === "string" && value.length > 25 && !/^\d+$/.test(value) ? (
            <Tooltip title={<Typography.Text>{value}</Typography.Text>}>
              <span style={{ cursor: "pointer" }}>
                {value.substring(0, 25)}...
              </span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          ),
      },
      {
        title: "Pass/Fail",
        dataIndex: "Pass Fail",
        key: "Pass/Fail",
        align:"left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Pass/Fail"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Pass/Fail")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Pass/Fail"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div>{value}</div>,
      },
      {
        title: "Limit Type",
        dataIndex: "Limit Type",
        key: "Limit Type",
        align:"left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Limit Type"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Limit Type")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Limit Type"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div>{value}</div>,
      },
      {
        title: "Limit",
        dataIndex: "Limit",
        key: "Limit",
        align:"left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Limit"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Limit")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Limit"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => (
          <div>
            {/* Assuming NumberComp4 is a React component */}
            <NumberComp4 value={value} />
          </div>
        ),
      },
      {
        title: "Consequence for Breach",
        dataIndex: "Consequence for Breach",
        key: "Consequence for Breach",
        align:"left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Consequence for Breach"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Consequence for Breach")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Consequence for Breach"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div>{value}</div>,
      },
      {
        title: "Report Flag",
        dataIndex: "Report Flag",
        key: "reportFlag",
        align:"left",
        render: (value) => (
          <div>
<input
                  type="checkbox"
                  checked={value === "Yes" ? true : false}
                  disabled={value === "No" ? true : false}
                  // style={{ cursor: "not-allowed", color: "#ffc000" }}
                />            </div>
        ),
      },
      {
        title: "Data Type",
        dataIndex: "Data Type",
        key: "Data Type",
        align:"left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Data Type"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Data Type")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Data Type"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div>{value}</div>,
      },
      {
        title: "Actions",
        dataIndex: "Actions",
        key: "actions",
        align:"left",
        render: (value, record) => (
          <div className="CreateActions">
            {/* Ant Design Button for View */}
            <button
              type="default"
              className="popupbutton1"
              onClick={() => this.onViewModal(value, record)}
            >
              View
            </button>
            
            {/* Ant Design Button for Edit */}
            <button
              type="primary"
              className="popupbutton1"
              onClick={() => this.onOpenModal1(value, record)}
            >
              /Edit
            </button>
            
            {/* Ant Design Button for Delete */}
            <button
              type="primary"
              danger
              className="popupbuttons1"
              onClick={() => this.onOpenModal2(value,record)}
            >
              Delete
            </button>
          </div>
        ),
      }
      
    ];
    const DealName = sessionStorage.getItem("dealname");

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Deal Creation"} />
          {this.state.screenloader === true ? (
            <LinearLoader msg={""} />
          ) : (
            <div className="content">
             

              <div className="page-contentofpool1">
                <div className="row1 arrow-dealcontent">
                  <div className="col-12 col-sm-6 col-md-8 d-flex justigy-content-center align-center hellocard">
                    <KeyboardBackspaceIcon
                      onClick={() => this.goBackToDashBoard()}
                      className="left-arrow-muis1 left-arrow-servicer"
                    ></KeyboardBackspaceIcon>
                    <h3 className="headerdashboard">DEAL CREATION</h3>
                    <div className="deal-name-display">
                      {DealName && (
                        <div className="deal-name-box">{DealName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 hellocard">
                    <div className="buttonsverified uw-deal-details-button-container">
                      {this.state.createStatus === "Update" ? null : (
                        <Button
                          variant="outlined"
                          color="black"
                          onClick={this.dealCreationSaveForNow}
                        >
                          Save for Now
                          {this.state.formLoader1 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      )}
                      {this.state.createStatus === "Update" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.dealCreationCreateUpdate}
                          style={{
                            color: "#000", // Change this to your desired text color
                          }}
                        >
                          Update
                          {this.state.formLoader2 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.dealCreationCreateUpdate}
                          style={{
                            color: "#000", // Change this to your desired text color
                          }}
                        >
                          Create
                          {this.state.formLoader2 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row row1 movement-tabs-creation">
                  <div>
                    {/* <div className="tablechangebutton"> */}
                    <div className="tablechangebuttonloans">
                      <button
                        type="button"
                        onClick={() => this.handleClickGeneral()}
                        className={
                          this.state.activeInsights1 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        General
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickTranches()}
                        className={
                          this.state.activeInsights2 == true
                            ? "issuerDashboard-table-top-button-workbench-active"
                            : "issuerDashboard-table-top-button-workbench"
                        }
                      >
                        Tranches
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickFees()}
                        className={
                          this.state.activeInsights3 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Fees
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickExpenses()}
                        className={
                          this.state.activeInsights4 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Expenses
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickManualInputs()}
                        className={
                          this.state.activeInsightsMI == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Manual Inputs
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickAccounts()}
                        className={
                          this.state.activeInsights5 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Accounts
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickTests()}
                        className={
                          this.state.activeInsights6 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Tests
                      </button>
                      {this.state.isSecuritisation !== "Securitisation" ? (
                        <button
                          type="button"
                          onClick={() => this.handleClickBorrowingBase()}
                          className={
                            this.state.activeInsightsBB == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Borrowing Base
                        </button>
                      ) : null}
                      <button
                        type="button"
                        onClick={() => this.handleClickVariables()}
                        className={
                          this.state.activeInsights7 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Variables
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickPaymentRules()}
                        className={
                          this.state.activeInsights8 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Payment Rules
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row row1 movement-header-creation">
                  <div className="investor-heading-container">
                    <h1 className="headerdashboard1">List of Tests</h1>
                  </div>
                  <div>
                    <div className="dashboard-top-right-container1">
                      <div className="search-bar-outer-container uw-search-button-container-creation">
                        {
                          <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={() => this.onOpenModal()}
                          >
                            Add Test
                          </Button>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <React.Fragment>
                  <div>
                  <Table
                      className="custom-antds-table"
                      dataSource={displayData.map((item, index) => ({
                        ...item,
                        key: index, // Add a unique key for each row
                      }))}
                      columns={columns} // Use the refactored Ant Design-compatible columns
                      pagination={false}
                      scroll={{ x: "max-content", y: 600 }} // Enable horizontal and vertical scroll
                      rowKey={(record) => record.key} // Use unique key for rows
                    />
                    {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
                overflow: "hidden",
              }}
            >
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={displayData.length}
                onChange={this.handlePaginationChange}
              />
            </div> */}
                  </div>
                </React.Fragment>
                <>
                  <div className="btn_move">
                    <div className="btn_prev" onClick={(e) => this.showPrev(e)}>
                      Previous
                    </div>
                    <button
                      className="btn_next"
                      onClick={(e) => this.showNext(e)}
                    >
                      Next
                    </button>
                  </div>
                </>
              </div>

              <>
                <ReactModal
                  isOpen={this.state.open1}
                  onRequestClose={this.onCloseModal}
                  contentLabel="Minimal Modal Example"
                  style={customStylesauto}
                >
                  <React.Fragment>
                    <div className="modalPopup">
                      <h2>Add Tests</h2>
                      <button
                        type="button"
                        className="closePopup"
                        style={{ minWidth: "30px" }}
                        onClick={this.onCloseModal}
                      >
                        <CloseIcon></CloseIcon>{" "}
                      </button>

                      {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                      <div className="modalshiftcontent">
                        <form
                          className="form-container"
                          onSubmit={this.AddSubmit}
                        >
                          <div className="input-container">
                            <label className="label"> Name</label>
                            <input
                              required
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    Name: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData.Name}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Label</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    Label: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData.Label}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Expression</label>
                            <input
                              required
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    Expression: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData.Expression}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Pass/Fail?</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Pass Fail": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Pass Fail"]}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Limit Type</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Limit Type": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Limit Type"]}
                            >
                              <option value="">Select any one</option>
                              <option value="Max">Max</option>
                              <option value="Min">Min</option>
                              <option value="EQ">EQ</option>
                              <option value="NOTEQ">NOTEQ</option>
                            </select>
                          </div>

                          <div className="input-container">
                            <label className="label">Limit</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              // onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                // let removeCharC = e.target.value.replace(
                                //   /[^0-9\.]/g,
                                //   ""
                                // );
                                // let formattedValue = removeCharC.replace(
                                //   /\B(?=(\d{3})+(?!\d))/g,
                                //   ","
                                // );
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    Limit: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData.Limit}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Consequence for Breach
                            </label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Consequence for Breach": e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData["Consequence for Breach"]
                              }
                            >
                              <option value="NA">Select any one</option>
                              <option value="CHPP">CHPP</option>
                              <option value="CHCP">CHCP</option>
                              <option value="BOTH">BOTH</option>
                              <option value="OTHR">OTHR</option>
                            </select>
                          </div>

                          <div className="checkbox-container">
                            <label className="label">Report Flag</label>
                            <input
                              className="checkbox-input"
                              type="checkbox"
                              onChange={(e) => {
                                const reportFlagValue = e.target.checked
                                  ? "Yes"
                                  : "No";

                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Report Flag": reportFlagValue,
                                  },
                                });
                              }}
                              checked={
                                this.state.formData["Report Flag"] === "Yes" ||
                                this.state.formData["Report Flag"] === undefined
                              } // Check for 'Yes' or undefined (default)
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Data Type</label>
                            <select
                              className="input-select"
                              value={this.state.formData["Data Type"]}
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Data Type": e.target.value,
                                  },
                                });
                              }}
                            >
                              <option value="">Select any one</option>
                              <option value="Double">Double</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Integer">Integer</option>
                              <option value="Date">Date</option>
                            </select>
                          </div>

                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-end1">
                                  <button
                                    type="button"
                                    className="popupbutton22"
                                    onClick={this.onCloseModal}
                                  >
                                    Cancel
                                  </button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                  >
                                    Add
                                    {this.state.formLoader === true ? (
                                      <CircularProgress
                                        size="22px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </React.Fragment>
                </ReactModal>
              </>

              <>
                <ReactModal
                  isOpen={this.state.viewModal}
                  onRequestClose={this.onCloseModalView}
                  contentLabel="Minimal Modal Example"
                  style={customStylesServicer}
                >
                  <React.Fragment>
                    <div className="modalPopup">
                      <h2>View Test</h2>
                      <button
                        type="button"
                        className="closePopup"
                        style={{ minWidth: "30px" }}
                        onClick={this.onCloseModalView}
                      >
                        <CloseIcon></CloseIcon>{" "}
                      </button>
                    </div>

                    <div className="modalshiftcontent">
                      <form className="form-container">
                        <div className="Modal-Data">
                          <div className="headingspaces1">
                            <p className="sizeofp">Name</p>
                            <h6 className="headingspace1">
                              {!this.state.formData1.Name
                                ? "-"
                                : this.state.formData1.Name}
                            </h6>
                          </div>
                          <div className="headingspaces1">
                            <p className="sizeofp">Label</p>
                            <h6 className="headingspace1">
                              {!this.state.formData1.Label
                                ? "-"
                                : this.state.formData1.Label}
                            </h6>
                          </div>
                          <div className="headingspaces1">
                            <p className="sizeofp">Expression</p>
                            <h6 className="headingspace1">
                              {!this.state.formData1.Expression
                                ? "-"
                                : this.state.formData1.Expression}
                            </h6>
                          </div>
                          <div className="headingspaces1">
                            <p className="sizeofp">Pass Fail</p>
                            <h6 className="headingspace1">
                              {!this.state.formData1["Pass Fail"]
                                ? "-"
                                : this.state.formData1["Pass Fail"]}
                            </h6>
                          </div>
                          <div className="headingspaces1">
                            <p className="sizeofp">Limit Type</p>
                            <h6 className="headingspace1">
                              {!this.state.formData1["Limit Type"]
                                ? "-"
                                : this.state.formData1["Limit Type"]}
                            </h6>
                          </div>
                          <div className="headingspaces1">
                            <p className="sizeofp">Limit</p>
                            <h6 className="headingspace1">
                              {!this.state.formData1["Limit"]
                                ? "-"
                                : this.state.formData1["Limit"]}
                            </h6>
                          </div>
                          <div className="headingspaces1">
                            <p className="sizeofp">Consequence for Breach</p>
                            <h6 className="headingspace1">
                              {!this.state.formData1["Consequence for Breach"]
                                ? "-"
                                : this.state.formData1[
                                    "Consequence for Breach"
                                  ]}
                            </h6>
                          </div>
                          <div className="headingspaces1">
                            <p className="sizeofp">Report Flag</p>
                            <h6 className="headingspace1">
                              {!this.state.formData1["Report Flag"]
                                ? "-"
                                : this.state.formData1["Report Flag"]}
                            </h6>
                          </div>
                          <div className="headingspaces1">
                            <p className="sizeofp">Data Type</p>
                            <h6 className="headingspace1">
                              {!this.state.formData1["Data Type"]
                                ? "-"
                                : this.state.formData1["Data Type"]}
                            </h6>
                          </div>
                        </div>

                        <div className="modalsubmit">
                          <div className="submitbuttonbg">
                            <div className="row">
                              <div className="row justify-content-end1">
                                <button
                                  type="button"
                                  className="popupbutton22"
                                  onClick={this.onCloseModal1}
                                >
                                  {" "}
                                  Cancel{" "}
                                </button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => this.onOpenModal1()}
                                >
                                  Edit
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </React.Fragment>
                </ReactModal>
              </>

              <>
                <ReactModal
                  isOpen={this.state.open2}
                  onRequestClose={this.onCloseModal1}
                  contentLabel="Minimal Modal Example"
                  style={customStylesauto}
                >
                  <React.Fragment>
                    <div className="modalPopup">
                      <h2>Edit Test</h2>
                      <button
                        type="button"
                        className="closePopup"
                        style={{ minWidth: "30px" }}
                        onClick={this.onCloseModal1}
                      >
                        <CloseIcon></CloseIcon>{" "}
                      </button>

                      {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                      <div className="modalshiftcontent">
                        <form
                          className="form-container"
                          onSubmit={this.EditSubmit}
                        >
                          <div className="input-container">
                            <label className="label"> Name</label>
                            <input
                              required
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    Name: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1.Name}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Label</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    Label: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1.Label}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Expression</label>
                            <input
                              required
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    Expression: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1.Expression}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Pass/Fail?</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Pass Fail": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Pass Fail"]}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Limit Type</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Limit Type": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Limit Type"]}
                            >
                              <option value="">Select any one</option>
                              <option value="Max">Max</option>
                              <option value="Min">Min</option>
                              <option value="EQ">EQ</option>
                              <option value="NOTEQ">NOTEQ</option>
                            </select>
                          </div>

                          <div className="input-container">
                            <label className="label">Limit</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              // onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                // let removeCharC = e.target.value.replace(
                                //   /[^0-9\.]/g,
                                //   ""
                                // );
                                // let formattedValue = removeCharC.replace(
                                //   /\B(?=(\d{3})+(?!\d))/g,
                                //   ","
                                // );
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    Limit: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1.Limit}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Consequence for Breach
                            </label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Consequence for Breach": e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1["Consequence for Breach"]
                              }
                            >
                              <option value="NA">Select any one</option>
                              <option value="CHPP">CHPP</option>
                              <option value="CHCP">CHCP</option>
                              <option value="BOTH">BOTH</option>
                              <option value="OTHR">OTHR</option>
                            </select>
                          </div>

                          <div className="checkbox-container">
                            <label className="label">Report Flag</label>
                            <input
                              className="checkbox-input"
                              type="checkbox"
                              onChange={(e) => {
                                const reportFlagValue = e.target.checked
                                  ? "Yes"
                                  : "No";

                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Report Flag": reportFlagValue,
                                  },
                                });
                              }}
                              checked={
                                this.state.formData1["Report Flag"] === "Yes" ||
                                this.state.formData1["Report Flag"] ===
                                  undefined
                              } // Check for 'Yes' instead of true
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Data Type</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Data Type": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Data Type"]}
                            >
                              <option value="">Select any one</option>
                              <option value="Double">Double</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Integer">Integer</option>
                              <option value="Date">Date</option>
                            </select>
                          </div>

                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-end1">
                                  <button
                                    type="button"
                                    className="popupbutton22"
                                    onClick={this.onCloseModal1}
                                  >
                                    Cancel
                                  </button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                  >
                                    Save
                                    {this.state.formLoader === true ? (
                                      <CircularProgress
                                        size="22px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </React.Fragment>
                </ReactModal>
                <div id="modal1">
                  <ReactModal
                    isOpen={this.state.open3}
                    onRequestClose={this.onCloseModal2}
                    contentLabel="Minimal Modal Example"
                    style={customStylesautosmallmodal1}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h3 className="popupheading">
                          Are you sure, you want to Delete this Test "
                          {this.state.deleteRowVal.Name}"?
                        </h3>
                        <div className="modalshiftcontent">
                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-end11">
                                  <button
                                    type="button"
                                    className="popupbutton1"
                                    onClick={this.onCloseModal2}
                                  >
                                    No
                                  </button>

                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.dealCreationDelete}
                                  >
                                    Yes, Delete it
                                    {this.state.formLoader === true ? (
                                      <CircularProgress
                                        size="25px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.getLoansLoader === false ? (
                            ""
                          ) : (
                            <FormLoader></FormLoader>
                          )}
                          {/* {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Processor addded Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>
              </>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Test_DealCreation);
