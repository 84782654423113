import React, { useState, useEffect } from "react";
import { Table, Typography, Button, Spin, Dropdown, Menu } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { v4 as uuidv4 } from "uuid";
import { ViewStratsTab as fetchViewStratsTab } from "../../../../../servies/services.js";
import {
  CaretDownOutlined,
  FilterFilled,
  CaretUpOutlined,
} from "@ant-design/icons";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import * as XLSX from "xlsx";

const { Title } = Typography;

const Strats = ({
  selectedPrimary,
  selectedSecondary,
  selectedTertiary,
  selectedAsOf,
  selectedDeals,
  downloadExcelPrimary,
}) => {
  const [tableData, setTableData] = useState({});
  const [tableDataExcel, setTableDataExcel] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [pagination, setPagination] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({});
  const [filteredClrChangeColumns, setFilteredClrChangeColumns] = useState({});
  const [currentBatch, setCurrentBatch] = useState(1);
  const [sortOrder, setSortOrder] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const itemsPerBatch = 15;

  const pageSize = 5;

  const getUniqueValues = (data, columnKey) => {
    // console.log({ data, columnKey });

    return [...new Set(data.map((item) => item[columnKey]))];
  };

  const generateColumns = (data, tableName) => {
    const baseColumns = [
      {
        title: tableName || "",
        dataIndex: "primVar",
        key: "primVar",
        fixed: "left",
        width: 200,
        onHeaderCell: () => ({
          style: {
            textAlign: "center", // Custom header alignment for primary column
          },
        }),
        render: (text, record) => {
          if (record.isTotal) {
            // Render "Total:" for summary rows
            return <strong>Total</strong>;
          }
          if (record.primVar) return <strong>{record.primVar}</strong>;
          if (record.secVar) return <strong>{record.secVar}</strong>;
          if (record.thrVar) return <strong>{record.thrVar}</strong>;
          return null;
        },
      },
    ];

    const dataKeys = Object.keys(data?.[0] || {});

    const dynamicColumns = dataKeys
      .filter(
        (key) => key !== "primVar" && key !== "uniqueKey" && key !== "children"
      )
      .map((key) => ({
        title: key.replace(/_/g, " "), // Format key names for display
        dataIndex: key,
        key,
        width: 150,
        onHeaderCell: () => ({
          style: {
            textAlign: "center", // Custom header alignment
          },
        }),
        // filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
        //   <CustomFilterComponent
        //     columnKey={key}
        //     onSort={handleSort}
        //     onFilter={handleFilter}
        //     columnValues={getUniqueValues(data, key)}
        //     closeDropdown={close}
        //   />
        // ),
        // filterIcon: () => (
        //   <FilterFilled
        //     style={{
        //       color: filteredClrChangeColumns[key] ? "green" : undefined,
        //     }}
        //   />
        // ),
        render: (text, record) => {
          const isDollarColumn = key.toLowerCase().includes("($)");
          const isWholeOrIntegerWithZeroDecimal =
            /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text);
          const isCountColumn = key.toLowerCase().includes("count");
          const isPercentColumn = key.toLowerCase().includes("%");
          const isNA = text === "NA";

          if (record.isTotal) {
            if (isNA) {
              return <div style={{ textAlign: "center" }}>{text}</div>;
            }
            if (isDollarColumn) {
              return (
                <strong style={{ textAlign: "right", display: "block" }}>
                  {text}
                </strong>
              );
            }
            if (isWholeOrIntegerWithZeroDecimal || isCountColumn) {
              return (
                <strong style={{ textAlign: "center", display: "block" }}>
                  {text}
                </strong>
              );
            }
            if (isPercentColumn) {
              return (
                <strong style={{ textAlign: "center", display: "block" }}>
                  {text}
                </strong>
              );
            }
            return (
              <strong style={{ textAlign: "left", display: "block" }}>
                {text}
              </strong>
            );
          }

          if (isNA) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }
          if (isDollarColumn) {
            return <div style={{ textAlign: "right" }}>{text}</div>;
          }
          if (isWholeOrIntegerWithZeroDecimal || isCountColumn) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }
          if (isPercentColumn) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }
          return <div style={{ textAlign: "left" }}>{text}</div>;
        },
      }));

    return [...baseColumns, ...dynamicColumns];
  };

  const handleFilter = (tableName, data, columnKey, criteria) => {
    // Update filter criteria
    const updatedCriteria = { ...filterCriteria };

    if (criteria) {
      // Set or update the filter for the column
      updatedCriteria[columnKey] = criteria;
      setFilteredClrChangeColumns((prev) => ({
        ...prev,
        [columnKey]: true,
      }));
    } else {
      // Remove the filter for the column
      delete updatedCriteria[columnKey];
      setFilteredClrChangeColumns((prev) => ({
        ...prev,
        [columnKey]: false,
      }));
    }

    setFilterCriteria(updatedCriteria);

    // Apply the filters to the data
    const filteredData = displayData.filter((row) =>
      Object.entries(updatedCriteria).every(([key, filter]) => {
        const { condition1, value1, operator, condition2, value2 } = filter;
        const rowValue = row[key];

        const matchCondition = (value, condition, target) => {
          switch (condition) {
            case "equals":
              return value?.toString() === target?.toString();
            case "not_equals":
              return value?.toString() !== target?.toString();
            case "greater_than":
              return parseFloat(value) > parseFloat(target);
            case "less_than":
              return parseFloat(value) < parseFloat(target);
            default:
              return false;
          }
        };

        const condition1Match = matchCondition(rowValue, condition1, value1);
        const condition2Match = matchCondition(rowValue, condition2, value2);

        return operator === "AND"
          ? condition1Match && condition2Match
          : condition1Match || condition2Match;
      })
    );

    console.log({ filteredData });

    // Update the state with the filtered data
    setTableData(filteredData);
  };

  const handleSort = (tableName, columnKey, order) => {
    const sortedData = [...tableData].sort((a, b) => {
      const vA = a[columnKey];
      const vB = b[columnKey];

      if (!isNaN(vA) && !isNaN(vB))
        return order === "ascend" ? vA - vB : vB - vA;
      if (!isNaN(Date.parse(vA)) && !isNaN(Date.parse(vB)))
        return order === "ascend"
          ? new Date(vA) - new Date(vB)
          : new Date(vB) - new Date(vA);

      return vA?.localeCompare(vB) || 0;
    });

    // setTableData(sortedData);
    setTableData(sortedData.slice(0, itemsPerBatch));
  };

  const getViewStratsTab = async (primaryStrat) => {
    const data = {
      dealId: selectedDeals?.[0]?.value || "",
      userName: sessionStorage.getItem("user_name"),
      startDate: selectedAsOf?.label,
      prim: selectedPrimary.map((strat) => strat.label),
      sec: selectedSecondary?.value || "",
      thr: selectedTertiary?.value || "",
    };

    setLoading(true);

    try {
      const response = await fetchViewStratsTab(data);
      const result = response.data.result;

      const newTableData = {};
      const newPagination = {};
      const newExpandedRowKeys = {};

      result.forEach((resItem, index) => {
        // Parse data with unique keys
        const parsedData = resItem.results.map((item, idx) => ({
          ...item,
          uniqueKey: `row-${idx}-${uuidv4()}`,
        }));

        // Group data by dynamic flags
        const groupedData = groupDataByDynamicFlag(
          parsedData,
          selectedPrimary,
          selectedSecondary,
          selectedTertiary
        );

        // Prepare final structured data
        const finalData = Object.values(groupedData).map((primEntry) => {
          const { children: secChildren, ...restOfPrimEntry } = primEntry;

          const primData = {
            primVar: primEntry.primVar,
            uniqueKey: `row-prim-${uuidv4()}`,
            ...Object.keys(restOfPrimEntry).reduce((acc, key) => {
              if (key === "primVar" || key === "uniqueKey") {
                acc[key] = primEntry[key];
              } else {
                acc[key] = selectedSecondary?.value ? "" : restOfPrimEntry[key];
              }
              return acc;
            }, {}),
            ...(selectedSecondary?.value && {
              children: secChildren.map((secEntry) => {
                const { children: thrChildren, ...restOfSecEntry } = secEntry;

                const secData = {
                  ...restOfSecEntry,
                  uniqueKey: `row-sec-${uuidv4()}`,
                  secVar: secEntry.secVar,
                  ...Object.keys(restOfSecEntry).reduce((acc, key) => {
                    if (key === "secVar" || key === "uniqueKey") {
                      acc[key] = secEntry[key];
                    } else {
                      acc[key] = selectedTertiary?.value
                        ? ""
                        : restOfSecEntry[key];
                    }
                    return acc;
                  }, {}),
                  ...(selectedTertiary?.value && {
                    children: thrChildren.map((thrEntry) => ({
                      ...thrEntry,
                      uniqueKey: `row-thr-${uuidv4()}`, // Unique key for tertiary level
                    })),
                  }),
                };

                return secData;
              }),
            }),
          };

          return primData;
        });

        // Add totalCalculations as the last row
        const totalRow = {
          ...resItem.totalCalculations,
          uniqueKey: `row-total-${uuidv4()}`,
          isTotal: true, // Mark as total row for styling
        };

        finalData.push(totalRow); // Append the total row

        // Prepare expanded row keys for all levels
        const allKeys = finalData.reduce((keys, item) => {
          keys.push(item.uniqueKey); // Add primary key
          if (item.children) {
            item.children.forEach((secItem) => {
              keys.push(secItem.uniqueKey); // Add secondary key
              if (secItem.children && selectedTertiary?.value) {
                secItem.children.forEach((thrItem) => {
                  keys.push(thrItem.uniqueKey); // Add tertiary key
                });
              }
            });
          }
          return keys;
        }, []);

        // Store data and expanded keys
        newTableData[selectedPrimary[index].value] = finalData;
        newPagination[selectedPrimary[index].value] = { current: 1, pageSize };
        newExpandedRowKeys[selectedPrimary[index].value] = allKeys;
      });

      // Update the state
      setTableData((prev) => ({ ...prev, ...newTableData }));
      setPagination((prev) => ({ ...prev, ...newPagination }));
      setExpandedRowKeys((prev) => ({ ...prev, ...newExpandedRowKeys }));
      setDisplayData(result.slice(0, itemsPerBatch));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const groupDataByDynamicFlag = (data) => {
    return data.reduce((acc, curr) => {
      const { primVar, secVar, thrVar, ...rest } = curr;

      // Create unique keys for each level
      const primUniqueKey = `prim-${primVar}-${uuidv4()}`;
      const secUniqueKey = `sec-${secVar}-${uuidv4()}`;
      const thrUniqueKey = `thr-${thrVar}-${uuidv4()}`;

      // console.log({ primVar });

      // Initialize primary level
      if (!acc[primVar]) {
        acc[primVar] = {
          primVar,
          ...rest,
          uniqueKey: primUniqueKey,
          children: [],
        };
      }

      const primChild = acc[primVar];

      // Check if the secondary level exists and create it if not
      let secChild = primChild.children.find(
        (child) => child.secVar === secVar
      );
      if (!secChild) {
        secChild = {
          secVar,
          ...rest,
          uniqueKey: secUniqueKey,
          children: [],
        };
        // console.log({ secChild });

        primChild.children.push(secChild);
      }

      // Push the tertiary data to the secondary child
      secChild.children.push({
        thrVar,
        ...rest,
        uniqueKey: thrUniqueKey,
      });

      return acc;
    }, {});
  };

  // Scroll event handler
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth } =
      event.target;

    // Only proceed if we are at the bottom of the vertical scroll
    const isVerticalScrollBottom = scrollTop + clientHeight >= scrollHeight - 5;
    const isAtHorizontalStart = scrollLeft === 0; // Optional: Use this if you want to avoid loading on horizontal scrolls

    if (isVerticalScrollBottom && !loading && isAtHorizontalStart) {
      loadMoreData();
    }
  };

  // Load next batch of data
  const loadMoreData = () => {
    if (displayData.length >= tableData.length) return; // Stop if no more data

    setLoading(true);
    const nextBatch = tableData.slice(
      displayData.length,
      displayData.length + itemsPerBatch
    );
    setDisplayData((prevData) => [...prevData, ...nextBatch]);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedPrimary.length > 0) {
      const newTableData = {};
      selectedPrimary.forEach((primary) => {
        newTableData[primary.value] = [];
      });
      setTableData(newTableData);
      getViewStratsTab(selectedPrimary);
    }
  }, [selectedPrimary, selectedSecondary, selectedTertiary]);

  const handlePageChange = (primaryStrat, page) => {
    setPagination((prev) => ({
      ...prev,
      [primaryStrat]: { ...prev[primaryStrat], current: page },
    }));
  };

  const exportDynamicPDF = async (e) => {
    e.domEvent.stopPropagation();
    setLoadingPDF(true);

    try {
      // 1. API Call to fetch data
      const dataPayload = {
        dealId: selectedDeals?.[0]?.value || "",
        userName: sessionStorage.getItem("user_name"),
        startDate: selectedAsOf?.label,
        prim: downloadExcelPrimary,
        sec: "",
        thr: "",
      };

      const response = await fetchViewStratsTab(dataPayload);

      if (!response?.data?.result) {
        console.error("Invalid API response structure.");
        return;
      }

      const result = response.data.result;

      const doc = new jsPDF();

      // 2. Transform API Data -> PDF Data
      result.forEach((resItem, index) => {
        const primaryData = resItem.results || [];
        const sheetName = resItem?.primVar || "Unnamed-Sheet";

        // Add a new page for each primary group except the first
        if (index > 0) doc.addPage();

        doc.setFontSize(8);
        doc.text(sheetName, 14, 20); // Add the title for the group
        doc.setFontSize(10);

        const rows = [];
        const columns = [];

        // Flatten hierarchical data
        const flattenData = (data, level = 0) => {
          data.forEach((item) => {
            const row = {
              ...item,
              firstColumnValue:
                level === 0
                  ? item.primVar
                  : level === 1
                  ? `  ${item.secVar}`
                  : `    ${item.thrVar}`,
              level,
              children: undefined,
            };
            rows.push(row);

            if (item.children && item.children.length > 0) {
              flattenData(item.children, level + 1);
            }
          });
        };

        flattenData(primaryData);

        // Extract columns from the first data row
        if (rows.length > 0) {
          const firstRow = rows[0];
          Object.keys(firstRow).forEach((key) => {
            if (
              ![
                "uniqueKey",
                "level",
                "children",
                "primVar",
                "secVar",
                "thrVar",
                "firstColumnValue",
              ].includes(key)
            ) {
              columns.push({ header: key, dataKey: key });
            }
          });
        }

        // Add the primaryKey as the first column
        columns.unshift({
          header: resItem?.primVar,
          dataKey: "firstColumnValue",
        });

        // Add Total Row
        if (rows.length > 0) {
          const totalRow = {
            ...resItem.totalCalculations,
            firstColumnValue: "Total",
          };
          rows.push(totalRow);
        }

        // Calculate page width and adjust scale
        const pageWidth = doc.internal.pageSize.getWidth();
        const tableWidth = columns.length * 65;
        const scaleFactor = tableWidth > pageWidth ? pageWidth / tableWidth : 1;

        // Add the table to the PDF
        doc.autoTable({
          startY: 25,
          head: [columns.map((col) => col.header)],
          body: rows.map((row) => columns.map((col) => row[col.dataKey] || "")),
          theme: "striped",
          headStyles: {
            fillColor: [0, 77, 64],
            textColor: [255, 255, 255],
            fontSize: 20 * scaleFactor, // Adjust font size based on scale
            cellPadding: 8 * scaleFactor,
            overflow: "linebreak", // Enable text wrapping for headers
            minCellHeight: 16 * scaleFactor,
          },
          styles: {
            fontSize: 24 * scaleFactor, // Adjust font size for table body
            cellPadding: 4 * scaleFactor,
            overflow: "linebreak", // Enable text wrapping
            halign: "center",
          },
          columnStyles: {
            0: { cellWidth: "wrap", halign: "left" },
          },
          bodyStyles: {
            minCellHeight: 2, // Adjust cell height for better spacing
          },
          rowPageBreak: "avoid", // Avoid splitting rows across pages

          willDrawCell: (data) => {
            if (data.row.index === rows.length - 1) {
              doc.setFont("helvetica", "bold");
              doc.setTextColor("#19191A"); // Set text color to black
            }
          },
        });
      });

      // 3. Save the PDF
      doc.save("Strats.pdf");
    } catch (error) {
      console.error("Error exporting PDF:", error);
    } finally {
      setLoadingPDF(false);
    }
  };

  // const exportDynamicPDF = () => {
  //   const doc = new jsPDF();

  //   Object.entries(tableData).forEach(([primaryKey, primaryData], index) => {
  //     // Add a new page for each primary group
  //     if (index > 0) doc.addPage();

  //     doc.setFontSize(8); // Decrease the title font size
  //     doc.text(primaryKey, 14, 20); // Title for the primary group
  //     doc.setFontSize(10); // Reset font size for the table

  //     // Prepare rows and columns dynamically
  //     const rows = [];
  //     const columns = [];

  //     // Helper function to flatten hierarchical data
  //     const flattenData = (data, level = 0) => {
  //       data.forEach((item) => {
  //         const row = {
  //           ...item,
  //           firstColumnValue:
  //             level === 0
  //               ? item.primVar // Primary level
  //               : level === 1
  //               ? `  ${item.secVar}` // Indented for secondary level
  //               : `    ${item.thrVar}`, // Further indented for tertiary level
  //           level,
  //           children: undefined, // Remove children to prevent recursive issues
  //         };
  //         rows.push(row);

  //         // Recursively process children if available
  //         if (item.children && item.children.length > 0) {
  //           flattenData(item.children, level + 1);
  //         }
  //       });
  //     };

  //     // Flatten the data
  //     flattenData(primaryData);

  //     // Extract columns from the first data row
  //     if (rows.length > 0) {
  //       const firstRow = rows[0];
  //       Object.keys(firstRow).forEach((key) => {
  //         if (
  //           key !== "uniqueKey" &&
  //           key !== "level" &&
  //           key !== "children" &&
  //           key !== "firstColumnValue" &&
  //           key !== "primVar" // Exclude the "primVar" column
  //         ) {
  //           columns.push({ header: key, dataKey: key });
  //         }
  //       });
  //     }

  //     // Add the primaryKey as the first column
  //     columns.unshift({
  //       header: primaryKey,
  //       dataKey: "firstColumnValue",
  //     });

  //     // Modify the last row to include "Total" in the first column
  //     if (rows.length > 0) {
  //       rows[rows.length - 1].firstColumnValue = "Total"; // Set "Total" for the last row's first column
  //     }

  //     // Calculate page width and adjust scale
  //     const pageWidth = doc.internal.pageSize.getWidth();
  //     const tableWidth = columns.length * 65; // Approximate column width
  //     const scaleFactor = tableWidth > pageWidth ? pageWidth / tableWidth : 1;

  //     // Add the table
  //     doc.autoTable({
  //       startY: 25, // Start below the title
  //       head: [columns.map((col) => col.header)], // Generate headers dynamically
  //       body: rows.map(
  //         (row) => columns.map((col) => row[col.dataKey]) // Map each cell to the corresponding column
  //       ),
  //       theme: "striped", // Table styling (options: grid, striped, plain)
  //       headStyles: {
  //         fillColor: [0, 77, 64], // Light gray background for header
  //         textColor: [255, 255, 255],
  //         // fontStyle: "bold",
  //         fontSize: 20 * scaleFactor, // Adjust font size based on scale
  //         cellPadding: 8 * scaleFactor,
  //         overflow: "linebreak", // Enable text wrapping for headers
  //         minCellHeight: 16 * scaleFactor,
  //       },
  //       styles: {
  //         fontSize: 24 * scaleFactor, // Adjust font size for table body
  //         cellPadding: 4 * scaleFactor,
  //         overflow: "linebreak", // Enable text wrapping
  //         halign: "center", // Center align text
  //       },
  //       columnStyles: {
  //         0: { cellWidth: "wrap", halign: "left" }, // Align the first column to the left
  //       },
  //       bodyStyles: {
  //         minCellHeight: 2, // Adjust cell height for better spacing
  //       },
  //       rowPageBreak: "avoid", // Avoid splitting rows across pages
  //       willDrawCell: (data) => {
  //         // Apply styling for the last row
  //         if (data.row.index === rows.length - 1) {
  //           doc.setFont("helvetica", "bold"); // Set font to bold
  //           doc.setTextColor("#19191A"); // Set text color to black
  //         }
  //       },
  //     });
  //   });

  //   // Save the PDF
  //   doc.save("Strats.pdf");
  // };

  const getViewStratsTabForExcel = async (primaryStrat) => {
    const data = {
      dealId: selectedDeals?.[0]?.value || "",
      userName: sessionStorage.getItem("user_name"),
      startDate: selectedAsOf?.label,
      prim: downloadExcelPrimary,
      sec: selectedSecondary?.value || "",
      thr: selectedTertiary?.value || "",
    };

    setLoading(true);

    try {
      const response = await fetchViewStratsTab(data);
      const result = response.data.result;

      const newTableData = {};
      const newPagination = {};
      const newExpandedRowKeys = {};

      result.forEach((resItem, index) => {
        // Parse data with unique keys
        const parsedData = resItem.results.map((item, idx) => ({
          ...item,
          uniqueKey: `row-${idx}-${uuidv4()}`,
        }));

        // Group data by dynamic flags
        const groupedData = groupDataByDynamicFlag(
          parsedData,
          selectedPrimary,
          selectedSecondary,
          selectedTertiary
        );

        // Prepare final structured data
        const finalData = Object.values(groupedData).map((primEntry) => {
          const { children: secChildren, ...restOfPrimEntry } = primEntry;

          const primData = {
            primVar: primEntry.primVar,
            uniqueKey: `row-prim-${uuidv4()}`,
            ...Object.keys(restOfPrimEntry).reduce((acc, key) => {
              if (key === "primVar" || key === "uniqueKey") {
                acc[key] = primEntry[key];
              } else {
                acc[key] = selectedSecondary?.value ? "" : restOfPrimEntry[key];
              }
              return acc;
            }, {}),
            ...(selectedSecondary?.value && {
              children: secChildren.map((secEntry) => {
                const { children: thrChildren, ...restOfSecEntry } = secEntry;

                const secData = {
                  ...restOfSecEntry,
                  uniqueKey: `row-sec-${uuidv4()}`,
                  secVar: secEntry.secVar,
                  ...Object.keys(restOfSecEntry).reduce((acc, key) => {
                    if (key === "secVar" || key === "uniqueKey") {
                      acc[key] = secEntry[key];
                    } else {
                      acc[key] = selectedTertiary?.value
                        ? ""
                        : restOfSecEntry[key];
                    }
                    return acc;
                  }, {}),
                  ...(selectedTertiary?.value && {
                    children: thrChildren.map((thrEntry) => ({
                      ...thrEntry,
                      uniqueKey: `row-thr-${uuidv4()}`, // Unique key for tertiary level
                    })),
                  }),
                };

                return secData;
              }),
            }),
          };

          return primData;
        });

        // Add totalCalculations as the last row
        const totalRow = {
          ...resItem.totalCalculations,
          uniqueKey: `row-total-${uuidv4()}`,
          isTotal: true, // Mark as total row for styling
        };

        finalData.push(totalRow); // Append the total row

        // Prepare expanded row keys for all levels
        const allKeys = finalData.reduce((keys, item) => {
          keys.push(item.uniqueKey); // Add primary key
          if (item.children) {
            item.children.forEach((secItem) => {
              keys.push(secItem.uniqueKey); // Add secondary key
              if (secItem.children && selectedTertiary?.value) {
                secItem.children.forEach((thrItem) => {
                  keys.push(thrItem.uniqueKey); // Add tertiary key
                });
              }
            });
          }
          return keys;
        }, []);

        // Store data and expanded keys
        newTableData[selectedPrimary[index].value] = finalData;
        newPagination[selectedPrimary[index].value] = { current: 1, pageSize };
        newExpandedRowKeys[selectedPrimary[index].value] = allKeys;
      });

      // Update the state
      setTableDataExcel((prev) => ({ ...prev, ...newTableData }));
      setPagination((prev) => ({ ...prev, ...newPagination }));
      setExpandedRowKeys((prev) => ({ ...prev, ...newExpandedRowKeys }));
      setDisplayData(result.slice(0, itemsPerBatch));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportDynamicExcel = async (e) => {
    e.domEvent.stopPropagation();
    setLoadingExcel(true);

    try {
      // 1. API Call to fetch data
      const dataPayload = {
        dealId: selectedDeals?.[0]?.value || "",
        userName: sessionStorage.getItem("user_name"),
        startDate: selectedAsOf?.label,
        prim: downloadExcelPrimary,
        sec: "",
        thr: "",
      };

      const response = await fetchViewStratsTab(dataPayload);

      if (!response?.data?.result) {
        console.error("Invalid API response structure.");
        return;
      }

      const result = response.data.result;

      // 2. Transform API Data -> tableDataExcel
      const allData = [];

      result.forEach((resItem) => {
        const primaryData = resItem.results || [];
        const sheetName = resItem?.primVar || "Unnamed-Sheet"; // Use primVar as the table name

        // Flatten the hierarchy
        const flattenData = (data, level = 0) => {
          return data.flatMap((item) => {
            const baseRow = {
              ...item,
              level,
              firstColumnValue:
                level === 0
                  ? item.primVar
                  : level === 1
                  ? `  ${item.secVar}`
                  : `    ${item.thrVar}`,
              children: undefined, // Remove children to prevent recursion
            };

            if (item.children && item.children.length > 0) {
              return [baseRow, ...flattenData(item.children, level + 1)];
            }
            return baseRow;
          });
        };

        const flattenedData = flattenData(primaryData);

        // Add Total Row
        const totalRow = {
          ...resItem.totalCalculations,
          firstColumnValue: "Total",
        };
        flattenedData.push(totalRow);

        // Add the table name row and data to the combined array
        allData.push({ tableName: sheetName, data: flattenedData });
      });

      // 3. Excel Export Logic
      const wb = XLSX.utils.book_new();
      const wsData = [];

      allData.forEach(({ tableName, data }) => {
        // Add Table Name as a Row with Background Color
        const tableNameRow = [
          { v: tableName, s: { fill: { fgColor: { rgb: "B3CAC7" } } } },
        ];

        // Add the table name row to the worksheet data
        wsData.push(tableNameRow); // First row is the table name with background color

        // Add Column Headers
        const columns = Object.keys(data[0]).filter(
          (key) =>
            ![
              "uniqueKey",
              "children",
              "level",
              "primVar",
              "firstColumnValue",
              "secVar",
              "thrVar",
            ].includes(key)
        );

        // Push the column headers to the first row
        const headerRow = [tableName, ...columns]; // Add table name as the first column header
        wsData.push(headerRow);

        // Add Data with Dynamic Columns
        data.forEach((row) => {
          const rowData = {};
          rowData[tableName] = row.firstColumnValue; // Add first column dynamically
          columns.forEach((col) => {
            if (col !== "firstColumnValue") {
              rowData[col] = row[col] || "";
            }
          });
          wsData.push(Object.values(rowData)); // Add row values to the worksheet data
        });

        // Add a row for separation between tables
        wsData.push([""]); // Empty row for separation
      });

      // Convert data to worksheet and append to workbook
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, "Strats");

      // 4. Download the Excel file
      XLSX.writeFile(wb, "Strats.xlsx");
    } catch (error) {
      console.error("Error exporting Excel:", error);
    } finally {
      setLoadingExcel(false);
    }
  };

  const exportDynamicCSV = async (e) => {
    e.domEvent.stopPropagation();
    setLoadingCSV(true);

    try {
      // 1. API Call to fetch data
      const dataPayload = {
        dealId: selectedDeals?.[0]?.value || "",
        userName: sessionStorage.getItem("user_name"),
        startDate: selectedAsOf?.label,
        prim: downloadExcelPrimary,
        sec: "",
        thr: "",
      };

      const response = await fetchViewStratsTab(dataPayload);

      if (!response?.data?.result) {
        console.error("Invalid API response structure.");
        return;
      }

      const result = response.data.result;

      // 2. Transform API Data -> tableDataExcel
      let csvData = [];

      result.forEach((resItem) => {
        const primaryData = resItem.results || [];
        const sheetName = resItem?.primVar || "Unnamed-Sheet"; // Use primVar as the table name

        // Flatten the hierarchy
        const flattenData = (data, level = 0) => {
          return data.flatMap((item) => {
            const baseRow = {
              ...item,
              level,
              firstColumnValue:
                level === 0
                  ? item.primVar
                  : level === 1
                  ? `  ${item.secVar}`
                  : `    ${item.thrVar}`,
              children: undefined, // Remove children to prevent recursion
            };

            if (item.children && item.children.length > 0) {
              return [baseRow, ...flattenData(item.children, level + 1)];
            }
            return baseRow;
          });
        };

        const flattenedData = flattenData(primaryData);

        // Add Total Row
        const totalRow = {
          ...resItem.totalCalculations,
          firstColumnValue: "Total",
        };
        flattenedData.push(totalRow);

        // Prepare columns dynamically
        const excludedColumns = [
          "uniqueKey",
          "children",
          "level",
          "primVar",
          "secVar",
          "thrVar",
          "firstColumnValue",
        ];
        const columns = Object.keys(flattenedData[0]).filter(
          (key) => !excludedColumns.includes(key)
        );

        // Add table name as the first row
        csvData.push([sheetName]); // First row is the table name

        // Map data for CSV export, ensuring 'firstColumnValue' is included correctly
        const sheetData = flattenedData.map((row) => {
          const mappedRow = { [sheetName]: row.firstColumnValue };
          columns.forEach((col) => {
            let value = row[col] || "";

            // Wrap date-like strings in double quotes to force text format in Excel
            if (
              typeof value === "string" &&
              (value.includes("-") || value.includes("/"))
            ) {
              value = `"${value}"`; // Wrap the value in double quotes
            }

            // Wrap values with commas or special characters in double quotes
            if (typeof value === "string" && value.includes(",")) {
              value = `"${value}"`;
            }

            mappedRow[col] = value;
          });
          return mappedRow;
        });

        // Add the header and rows to the CSV data
        const header = [sheetName, ...columns];
        csvData.push(
          header,
          ...sheetData.map((row) => [
            row[sheetName],
            ...columns.map((col) => row[col]),
          ])
        );

        // Add separator to differentiate sheets (Optional, as CSV doesn't support multiple tabs)
        csvData.push([]); // Empty row for separation
      });

      // Convert the data into CSV format
      let csvString = csvData.map((row) => row.join(",")).join("\n");

      // Create a Blob from the CSV string and download it
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Strats.csv";
      link.click();
    } catch (error) {
      console.error("Error exporting CSV:", error);
    } finally {
      setLoadingCSV(false);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={exportDynamicPDF}>
        PDF {loadingPDF && <Spin size="small" style={{ marginLeft: 2 }} />}
      </Menu.Item>
      <hr className="custom-inv-ddHr" />
      <Menu.Item key="2" onClick={exportDynamicExcel}>
        Excel {loadingExcel && <Spin size="small" style={{ marginLeft: 2 }} />}
      </Menu.Item>
      <hr className="custom-inv-ddHr" />
      <Menu.Item key="3" onClick={exportDynamicCSV}>
        CSV {loadingCSV && <Spin size="small" style={{ marginLeft: 2 }} />}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="table-container strats-table-container">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          // marginBottom: 2,
          position: "relative",
          right: "0.1rem",
          top: "2rem",
        }}
      >
        {/* <Button
          type="primary"
          onClick={exportDynamicPDF}
          style={{
            backgroundColor: "#194d40",
            width: 150,
          }}
        >
          Download PDF
          {loadingPDF && (
            <Spin
              size="small"
              // style={{ marginLeft: "8px" }} // Ensure space between text and spinner
            />
          )}
        </Button>
        <Button
          type="primary"
          onClick={exportDynamicExcel}
          style={{
            backgroundColor: "#194d40",
            width: 150,
            marginLeft: "10px",
          }}
        >
          Download Excel
          {loadingExcel && (
            <Spin
              size="small"
              // style={{ marginLeft: "8px" }} // Ensure space between text and spinner
            />
          )}
        </Button>
        <Button
          type="primary"
          onClick={exportDynamicCSV}
          style={{
            backgroundColor: "#194d40",
            width: 150,
            marginLeft: "10px",
          }}
        >
          Download CSV
          {loadingCSV && (
            <Spin
              size="small"
              // style={{ marginLeft: "8px" }} // Ensure space between text and spinner
            />
          )}
        </Button> */}

        <Dropdown
          overlay={menu}
          trigger={["click"]}
          visible={dropdownVisible}
          onVisibleChange={(visible) => setDropdownVisible(visible)}
          overlayClassName="custom-inv-ddDownload"
        >
          <Button
            type="primary"
            style={{
              backgroundColor: "#ffc000",
              width: 120,
              color: "#000",
            }}
          >
            <span>Download</span>
            {dropdownVisible ? (
              <CaretUpOutlined
                style={{
                  color: "#000",
                }}
              />
            ) : (
              <CaretDownOutlined
                style={{
                  color: "#000",
                }}
              />
            )}
          </Button>
        </Dropdown>
      </div>
      {selectedPrimary && selectedPrimary.length > 0 ? (
        selectedPrimary.map((primary) => (
          <div key={primary.value}>
            <Title level={3} style={{ color: "var(--headerText)" }}>
              {primary.label}
            </Title>
            <Table
              className="performance-table"
              columns={generateColumns(tableData[primary.value], primary.label)}
              dataSource={tableData[primary.value] || []}
              rowKey={(record) => record.uniqueKey}
              rowClassName={(record) => (record.isTotal ? "total-row" : "")}
              // pagination={{
              //   current: pagination[primary.value]?.current || 1,
              //   pageSize,
              //   total: tableData[primary.value]?.length || 0,
              //   onChange: (page) => handlePageChange(primary.value, page),
              // }}
              pagination={false}
              bordered
              loading={loading}
              expandable={{
                expandRowByClick: true,
                childrenColumnName: "children",
                expandedRowKeys: expandedRowKeys[primary.value] || [],
                onExpand: (expanded, record) => {
                  setExpandedRowKeys((prev) => {
                    const currentKeys = prev[primary.value] || [];
                    return {
                      ...prev,
                      [primary.value]: expanded
                        ? [...currentKeys, record.uniqueKey]
                        : currentKeys.filter((key) => key !== record.uniqueKey),
                    };
                  });
                },
                rowExpandable: (record) =>
                  record.children && record.children.length > 0,
              }}
              scroll={{ x: "max-content", y: "calc(100vh - 100px)" }}
            />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default Strats;
