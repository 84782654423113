import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import DoubleRing1 from "../../images/DoubleRing1.svg";

export default class Loader extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="LinearProgress">
          {this.props.msg === undefined ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "var(--light)",
                }}
              >
                {/* <img
                  src={DoubleRing1}
                  style={{
                    width: "5vw",
                    height: "10vh",
                  }}
                  alt="Loading Animation"
                /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  style={{
                    margin: "auto",
                    background: "var(--light)",
                    display: "block",
                    shapeRendering: "auto",
                  }}
                  width="80px"
                  height="80px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="32"
                    strokeWidth="8"
                    stroke="#e5f3f2"
                    strokeDasharray="50.26548245743669 50.26548245743669"
                    fill="none"
                    strokeLinecap="round"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      dur="1s"
                      repeatCount="indefinite"
                      keyTimes="0;1"
                      values="0 50 50;360 50 50"
                    />
                  </circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="23"
                    strokeWidth="8"
                    stroke="#e5f3f2"
                    strokeDasharray="36.12831551628262 36.12831551628262"
                    strokeDashoffset="36.12831551628262"
                    fill="none"
                    strokeLinecap="round"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      dur="1s"
                      repeatCount="indefinite"
                      keyTimes="0;1"
                      values="0 50 50;-360 50 50"
                    />
                  </circle>
                </svg>
                {/* <h4 className="commonLoading">Loading</h4> */}
                <h4 className="loadingMapField">Please wait </h4>
              </div>
            </>
          ) : this.props.msg === "" ? (
            <LinearProgress thickness={2} color="secondary" />
          ) : (
            <div className="loadingTextLinear">
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "var(--light)",
                }}
              >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  style={{
                    margin: "auto",
                    background: "var(--light)",
                    display: "block",
                    shapeRendering: "auto",
                  }}
                  width="80px"
                  height="80px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="32"
                    strokeWidth="8"
                    stroke="#e5f3f2"
                    strokeDasharray="50.26548245743669 50.26548245743669"
                    fill="none"
                    strokeLinecap="round"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      dur="1s"
                      repeatCount="indefinite"
                      keyTimes="0;1"
                      values="0 50 50;360 50 50"
                    />
                  </circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="23"
                    strokeWidth="8"
                    stroke="#e5f3f2"
                    strokeDasharray="36.12831551628262 36.12831551628262"
                    strokeDashoffset="36.12831551628262"
                    fill="none"
                    strokeLinecap="round"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      dur="1s"
                      repeatCount="indefinite"
                      keyTimes="0;1"
                      values="0 50 50;-360 50 50"
                    />
                  </circle>
                </svg>
              <h4>{this.props.msg}</h4>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
