import React, { useEffect, useState } from "react";
import { Table } from "antd";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import fIlter from "../../../../../images/FilterMappedTape.svg";
import { GetVintageData } from "../../../../../servies/services.js";

const getUniqueValues = (data, columnKey) => {
  return [
    ...new Set(
      data.map((item) => item[columnKey]).filter((val) => val !== undefined)
    ),
  ];
};

const VintageDash = ({
  Deals,
  IssuerGroup,
  AssetClass,
  startDate,
  endDate,
  selectedDelinquencies_Vintage,
}) => {
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [displayData, setDisplayData] = useState([]); // Store unfiltered data
  const [sortOrder, setSortOrder] = useState({ columnKey: null, order: null });
  const [filterCriteria, setFilterCriteria] = useState({});
  const [filteredClrChangeColumns, setFilteredClrChangeColumns] = useState({});
  const [loading, setLoading] = useState(false);

  const itemsPerBatch = 10; // Simulate pagination batch size

  const transformData = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return { rows: [], columns: [] };
    }

    const table = data;

    // Ensure "Vintage" is the first column in the rows
    const rows = table.map((row) => {
      const { Vintage, ...rest } = row;
      return { Vintage, ...rest };
    });

    // Create columns, ensuring "Vintage" is the first
    const columns = [
      {
        title: "Vintage",
        dataIndex: "Vintage",
        key: "Vintage",
        fixed: "left",
        width: 150,
      },
      ...Object.keys(table[0])
        .filter((key) => key !== "Vintage")
        .map((key) => ({
          title: key,
          dataIndex: key,
          key,
          align: "right",
        })),
    ];

    return { rows, columns };
  };

  const handleSort = (columnKey, order) => {
    setSortOrder({ columnKey, order });

    const sortedData = [...tableData].sort((a, b) => {
      const vA = a[columnKey];
      const vB = b[columnKey];

      if (!isNaN(parseFloat(vA)) && !isNaN(parseFloat(vB))) {
        return order === "ascend"
          ? parseFloat(vA) - parseFloat(vB)
          : parseFloat(vB) - parseFloat(vA);
      }

      if (!isNaN(Date.parse(vA)) && !isNaN(Date.parse(vB))) {
        return order === "ascend"
          ? new Date(vA) - new Date(vB)
          : new Date(vB) - new Date(vA);
      }

      return order === "ascend"
        ? vA?.localeCompare(vB) || 0
        : vB?.localeCompare(vA) || 0;
    });

    setTableData(sortedData);
  };

  const handleFilter = (columnKey, criteria) => {
    const updatedCriteria = { ...filterCriteria };

    if (criteria) {
      updatedCriteria[columnKey] = criteria;
      setFilteredClrChangeColumns((prev) => ({ ...prev, [columnKey]: true }));
    } else {
      delete updatedCriteria[columnKey];
      setFilteredClrChangeColumns((prev) => ({ ...prev, [columnKey]: false }));
    }

    setFilterCriteria(updatedCriteria);

    const filteredData = displayData.filter((item) =>
      Object.entries(updatedCriteria).every(([key, condition]) => {
        const { condition1, value1, operator, condition2, value2 } = condition;

        const applyCondition = (val, cond, target) => {
          switch (cond) {
            case "equals":
              return val === target;
            case "less_than":
              return val < target;
            case "greater_than":
              return val > target;
            case "not_equals":
              return val !== target;
            default:
              return true;
          }
        };

        const c1 = applyCondition(item[key], condition1, value1);
        const c2 = condition2
          ? applyCondition(item[key], condition2, value2)
          : true;

        return operator === "AND" ? c1 && c2 : c1 || c2;
      })
    );

    setTableData(filteredData);
  };

  const fetchData = async () => {
    const selectSpecificDelinquenciesVintage =
      selectedDelinquencies_Vintage.map((item) => item.label);
    const requestData = {
      asset_class: AssetClass,
      issuer_group: IssuerGroup,
      deal_type: Deals,
      start_date: startDate,
      end_date: endDate,
      loanStatus: selectSpecificDelinquenciesVintage,
    };
    setLoading(true);

    try {
      const APIResponse = await GetVintageData(requestData);

      const data = APIResponse.data.table || [];
      console.log({ data });

      setDisplayData(data);
      const { rows, columns } = transformData(data);
      setTableData(rows);
      setColumns(columns);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    Deals,
    IssuerGroup,
    AssetClass,
    startDate,
    endDate,
    selectedDelinquencies_Vintage,
  ]);
  return (
    <div style={{ padding: "20px" }}>
      <Table
        dataSource={tableData}
        columns={columns}
        className="performance-table"
        rowKey="Vintage"
        bordered
        pagination={false}
        loading={loading}
        scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
      />
    </div>
  );
};

export default VintageDash;
