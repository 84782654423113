/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import Sidebar from "../../../../../components/sidebar";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import * as XLSX from "xlsx";
import "react-input-range/lib/css/index.css";
import {
  ViewEsmaReport,
  getAllVersionsByPaymentDate,
  getAllPaymentDatesByDealName,
  generateAnnex2XML,
  download2XML
} from "../../../../../servies/services";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import * as moment from "moment";
import { TableVirtuoso } from "react-virtuoso";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import CircularProgress from "@material-ui/core/CircularProgress";
import BeanEater from "../../../../../images/BeanEater.gif";
import ReactModal from "react-modal";
import { customStylesautosmallmodalpopupBorrow } from "../../../../../components/customscripts/customscript";
import { debounce } from "lodash";
import FilterMappedTape from "../../../../../images/FilterMappedTape.svg";
import borderLeft from "../../../../../images/border-left.png";
import borderBottom from "../../../../../images/border-bottom.png";
import rightArrow from "../../../../../images/RightPointer.png";
import ArrowDownward from "../../../../../images/arrow_downward.png";
import ArrowUpward from "../../../../../images/arrow_upward.png";
import { Spin } from 'antd';

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};
class Annex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
      loading: false,
      tableData: [],
      downArrow: false,
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      ServicerName:
        sessionStorage.getItem("Servicer") === null
          ? sessionStorage.getItem("servicerdashboardname")
          : sessionStorage.getItem("Servicer"),
      detailsDate:
        sessionStorage.getItem("selectdate") === null
          ? sessionStorage.getItem("selectservicerdate")
          : sessionStorage.getItem("selectdate"),
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Version: sessionStorage.getItem("version"),
      VersionsChange: [],
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      isSecuritisation: sessionStorage.getItem("isSecuritisation"),
      Assetclass: sessionStorage.getItem("Assetclass"),
      isESMA_Flag: sessionStorage.getItem("isESMA_Flag"),
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      openModalPayment: false,
      formLoader1: false,
      screenloader: false,
      MapDataValues: [],
      MapDataKeys: [],
      data: [],
      searchTerm: "",
      isBorrow: false,
      BorrowingBase: "False",
      isDataFound: true,
      isSearchOpen: false,
      sortColumn: "", // Track the currently sorted column
      sortAscending: true,
      filterConditions: {},
      filteredColumn: [],
      filteredData: [],
      loadingExcel: false,
      loadingXML: false,
      loadingCSV: false,
    };
    this.handleButtonClick = debounce(this.handleButtonClick.bind(this), 100);
  }

  sortA = (sortColumn) => {
    console.log("", this.state.MapDataKeys);
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return -1;
      if (a[sortColumn] > b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click();
  };

  sortZ = (sortColumn) => {
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] > b[sortColumn]) return -1;
      if (a[sortColumn] < b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click();
  };

  toggleFilter = () => {
    this.setState((prevState) => ({
      isFilterActive: !prevState.isFilterActive,
    }));
  };

  handleClickCancel = (column) => {
    let temp = this.state.filteredColumn.filter((col) => col !== column);
    console.log("columnname", column, this.state.filteredColumn);
    this.setState((prevState) => {
      const updatedFilterConditions = {
        ...prevState.filterConditions,
        [column]: {
          condition1: "Equals",
          value1: "",
          condition2: "Equals",
          value2: "",
          logic: "OR", // Default logic
        },
      };

      const filteredData = prevState.tableData.filter((row) => {
        return Object.keys(updatedFilterConditions).every((col) => {
          const { condition1, value1, condition2, value2, logic } =
            updatedFilterConditions[col];

          if (value1 || value2) {
            const isFirstConditionMet = this.checkCondition(
              row[col],
              condition1,
              value1,
              col
            );
            const isSecondConditionMet = this.checkCondition(
              row[col],
              condition2,
              value2,
              col
            );

            // Use logic to determine whether to apply AND or OR
            if (logic === "AND") {
              return isFirstConditionMet && isSecondConditionMet;
            }
            return isFirstConditionMet || isSecondConditionMet;
          }
          return true;
        });
      });

      return {
        filterConditions: updatedFilterConditions,
        filteredData: filteredData.length > 0 ? filteredData : [{}], // Restore full data if no matches
      };
    });
    document.body.click();
  };

  handleFilterSubmit = (column, filterConditions) => {
    const { condition1, value1, condition2, value2, logic } = filterConditions;
    console.log("Filter conditions:", filterConditions);

    const currentData =
      this.state.filteredData.length > 0
        ? this.state.filteredData
        : this.state.tableData;

    const filteredData = currentData.filter((row) => {
      const isFirstConditionMet = this.checkCondition(
        row[column],
        condition1,
        value1,
        column
      );
      const isSecondConditionMet = this.checkCondition(
        row[column],
        condition2,
        value2,
        column
      );

      // Apply AND or OR based on logic
      return logic === "AND"
        ? isFirstConditionMet && isSecondConditionMet
        : isFirstConditionMet || isSecondConditionMet;
    });

    // Check if filteredData is empty
    if (filteredData.length === 0) {
      this.setState({ filteredData: [{ message: "Data not found" }] });
    } else {
      this.setState({ filteredData });
    }

    console.log("Filtered Data:", filteredData);

    let temp = [...this.state.filteredColumn];
    if (!temp.includes(column)) {
      temp.push(column);
    }
    this.setState({ filteredColumn: temp });

    document.body.click();
  };

  checkCondition = (cellValue, condition, value, column) => {
    if (cellValue === null || cellValue === undefined || cellValue === "") {
      return false; // Skip rows with empty values
    }

    const parsedCellValue = isNaN(Number(cellValue))
      ? cellValue
      : Number(cellValue);
    const parsedValue = isNaN(Number(value)) ? value : Number(value);

    console.log("parsed", parsedCellValue, parsedValue);
    switch (condition) {
      case "Equals":
        return parsedCellValue === parsedValue;
      case "Not Equals":
        return parsedCellValue !== parsedValue;
      case "Greater than":
        return parsedCellValue > parsedValue;
      case "Lesser than":
        return parsedCellValue < parsedValue;
      default:
        return true;
    }
  };
  updateFilterCondition = (column, field, value) => {
    this.setState(
      (prevState) => ({
        filterConditions: {
          ...prevState.filterConditions,
          [column]: {
            ...prevState.filterConditions[column],
            [field]: value,
          },
        },
      }),
      () => {
        console.log("Updated filterConditions:", this.state.filterConditions); // Debug log
      }
    );
  };

  popoverBottom = (column) => {
    const { filterConditions } = this.state;
    const columnFilterConditions = filterConditions[column] || {
      condition1: "",
      value1: "",
      logic: "OR",
      condition2: "",
      value2: "",
    };

    const isFilterActive = this.state.isFilterActive;
    const triangleImage = isFilterActive ? rightArrow : rightArrow;

    // Removed conditions and displaying all filter options
    const filterOptions = [
      "Equals",
      "Greater than",
      "Lesser than",
      "Not Equals",
    ];

    let uniqueValues = [];
    if (this.state.tableData.length > 0) {
      uniqueValues = [
        ...new Set(this.state.tableData.map((row) => row[column])),
      ];
    }

    const renderInputField = (condition, valueField) => {
      return (
        <select
          className="popover-input-wide dropdownicon"
          value={columnFilterConditions[valueField]}
          onChange={(e) =>
            this.updateFilterCondition(column, valueField, e.target.value)
          }
        >
          <option value="">Select value</option>
          {uniqueValues.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </select>
      );
    };

    const popoverClass =
      this.state.popoverPlacement === "left"
        ? "popover-container-sort-left"
        : "popover-container-sort-right";

    return (
      <Popover className={popoverClass}>
        <div className="popover-content">
          <div className="popover-row">
            <img src={ArrowDownward} alt="arrow-downward" />
            <button
              className="popover-sort-btn"
              onClick={() => this.sortA(column)}
            >
              Sort A to Z
            </button>
          </div>
          <div className="popover-row">
            <img src={ArrowUpward} alt="arrow-upward" />
            <button
              className="popover-sort-btn"
              onClick={() => this.sortB(column)}
            >
              Sort Z to A
            </button>
          </div>
          <div>
            <p>Custom Filter</p>
            <div className="popover-row">
              <select
                className="popover-input"
                value={columnFilterConditions.condition1}
                onChange={(e) =>
                  this.updateFilterCondition(
                    column,
                    "condition1",
                    e.target.value
                  )
                }
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {renderInputField(columnFilterConditions.condition1, "value1")}
            </div>
            <div className="popover-radio-group">
              <div className="popover-radio-item">
                <input
                  type="radio"
                  name="filterLogic"
                  value="AND"
                  checked={columnFilterConditions.logic === "AND"}
                  onChange={(e) =>
                    this.updateFilterCondition(column, "logic", e.target.value)
                  }
                />
                <label style={{ color: "var(--tableText)" }}>AND</label>
              </div>
              <div className="popover-radio-item">
                <input
                  type="radio"
                  name="filterLogic"
                  value="OR"
                  checked={columnFilterConditions.logic === "OR"}
                  onChange={(e) =>
                    this.updateFilterCondition(column, "logic", e.target.value)
                  }
                />
                <label style={{ color: "var(--tableText)" }}>OR</label>
              </div>
            </div>
            <div className="popover-row">
              <select
                className="popover-input"
                value={columnFilterConditions.condition2}
                onChange={(e) =>
                  this.updateFilterCondition(
                    column,
                    "condition2",
                    e.target.value
                  )
                }
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {renderInputField(columnFilterConditions.condition2, "value2")}
            </div>
            <div className="popover-bottom-buttons">
              <button
                type="button"
                className="popupbutton22"
                onClick={() => this.handleClickCancel(column)}
              >
                Cancel
              </button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  this.handleFilterSubmit(column, columnFilterConditions);
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    );
  };

  handleSearchIconClick = () => {
    this.setState({
      isSearchOpen: true,
    });
  };

  toggleFullScreen = () => {
    this.setState((prevState) => ({
      isFullScreen: !prevState.isFullScreen,
    }));

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  popoverBottomDownload = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={this.handleDownloadExcel}>
          Excel {this.state.loadingExcel && <Spin size="small" />}
        </button>
        <hr className="servicer-popover-hr" />
        <button onClick={this.handleGenerateAnnex2XML}>
          XML {this.state.loadingXML && <Spin size="small" />}
        </button>
        <hr className="servicer-popover-hr" />
        <button onClick={this.handleDownloadCSV}>
          CSV {this.state.loadingCSV && <Spin size="small" />}
        </button>
      </Popover>
    );
  };

  handleDownloadExcel = async () => {
    this.setState({ loadingExcel: true });
    try {
      await this.DownloadExcel();
    } finally {
      this.setState({ loadingExcel: false });
    }
  };

  handleGenerateAnnex2XML = async () => {
    this.setState({ loadingXML: true });
    try {
      await this.generateAnnex2XML();
    } finally {
      this.setState({ loadingXML: false });
    }
  };

  handleDownloadCSV = async () => {
    this.setState({ loadingCSV: true });
    try {
      await this.DownloadCSV();
    } finally {
      this.setState({ loadingCSV: false });
    }
  };

  generateAnnex2XML = async () => {
    try {
      this.setState({ formLoader1: true }); // Show loader while API is being called

      // Ensure NextPaymentDate is defined
      const x = this.state?.NextPaymentDate;
      if (!x) {
        this.props.enqueueSnackbar("Next Payment Date is missing!", {
          variant: "error",
        });
        this.setState({ formLoader1: false });
        return;
      }

      // Extract month and year safely
      const month = x.length >= 2 ? x.slice(0, 2) : "";
      const year = x.length >= 10 ? x.slice(6, 10) : "";

      // Ensure DealName exists
      const dealName = this.state.DealName || "UnknownDeal";

      // Prepare the request data
      const requestData = {
        DealName: dealName,
        Month: month,
        Year: year,
        Version: this.state.Version,
        peer: this.state.peer,
      };

      let APIResponse;
      try {
        APIResponse = await generateAnnex2XML(requestData);
      } catch (error) {
        APIResponse = error;
      }

      console.log("APIResponse", APIResponse);

      // Check the isSuccess flag in the response
      if (APIResponse?.data?.isSuccess) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "success",
          autoHideDuration: 2000,
        });

        // Call download function since XML generation was successful
        await this.download2XML({
          DealName: dealName,
          Month: month,
          Year: year,
        });
      } else {
        // Handle API response errors
        let errorMessages = [];
        if (APIResponse?.data?.errors?.length > 0) {
          errorMessages = APIResponse.data.errors.map(
            (err) =>
              `Message: ${err.message}\nLine: ${err.line || "-"}\nColumn: ${
                err.column || "-"
              }`
          );
        } else if (APIResponse?.data?.error) {
          errorMessages.push(`Message: ${APIResponse.data.error}`);
        } else if (APIResponse?.data?.message) {
          errorMessages.push(`Message: ${APIResponse.data.message}`);
        }

        console.log("errorMessages", errorMessages);

        // Save errors to a text file
        if (errorMessages.length > 0) {
          const errorText = errorMessages.join("\n\n");
          const filename = `ValidationErr-Annex2-${dealName}-${month}-${year}.txt`;

          console.log("errorText", errorText);

          try {
            const blob = new Blob([errorText], { type: "text/plain" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => URL.revokeObjectURL(link.href), 100);
            const message = APIResponse.data.message;
            this.props.enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 2000,
            });
            await this.download2XML({
              DealName: dealName,
              Month: month,
              Year: year,
            });
          } catch (error) {
            console.error("Error creating or downloading the file:", error);
          }
        }
      }
    } catch (error) {
      this.props.enqueueSnackbar("An error occurred while generating XML", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      this.setState({ formLoader1: false }); // Hide loader
    }
  };

  download2XML = async (data) => {
    try {
      this.setState({ formLoader1: true });

      const fileName = `Annex2-${data.DealName}-${data.Month}-${data.Year}.xml`;
      const APIResponse = await download2XML(data);
      console.log({ APIResponse });

      this.props.enqueueSnackbar("Download successful!", {
        variant: "success",
        autoHideDuration: 3000,
      });

      if (APIResponse.data) {
        // Create a Blob from the XML string
        const blob = new Blob([APIResponse.data], { type: "application/xml" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }, 100);
      } else {
        throw new Error("File content not provided");
      }
    } catch (error) {
      this.props.enqueueSnackbar(
        error.message || "An error occurred while downloading XML",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
    } finally {
      this.setState({ formLoader1: false });
    }
  };

  handleButtonClick() {
    this.setState((prevState) => ({
      downArrow: !prevState.downArrow,
    }));
  }

  handleOverlayHide = () => {
    this.setState({ downArrow: false });
  };

  DownloadExcel = () => {
    const { tableData } = this.state;
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Annex2_data.xlsx");
  };

  DownloadCSV = () => {
    const { tableData } = this.state;
    const csvData = tableData.map((row) => Object.values(row).join(","));
    const csvHeaders = Object.keys(tableData[0]).join(",");
    const csvContent = `${csvHeaders}\n${csvData.join("\n")}`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Annex2_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Your browser does not support downloading files.");
    }
  };

  handleClickAnnex = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
    });
    this.props.history.push("/admin/annex");
  };

  handleClickAnnex12 = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
    });
    this.props.history.push("/admin/annex12");
  };

  handleClickAnnex14 = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
    });
    this.props.history.push("/admin/annex14");
  };
  showNext = () => {
    this.props.history.push("/admin/annex12");
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  ViewEsmaReport = async () => {
    this.setState({
      getLoansLoader: true,
      tableData: [],
      loading: true,
      screenloader: true,
    });
    let x = this.state.NextPaymentDate;
    console.log();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;

    data.peer = this.state.peer;
    data.Version = this.state.Version;

    console.log("datatatata", data);
    const APIResponse = await ViewEsmaReport(data);
    console.log("ViewEsmaReport", APIResponse);
    if (APIResponse.status === 200 && APIResponse.data.isSuccess === true) {
      // const annexData = APIResponse.data.data["Annex"];
      const Asset_Class = sessionStorage.getItem("Assetclass");
      let annexNumber;

      // Set annex number based on asset class
      if (this.state.isESMA_Flag === "Yes") {
        switch (Asset_Class) {
          case "Residential Real Estate":
            annexNumber = 2;
            break;
          case "Commercial Mortgage":
            annexNumber = 3;
            break;
          case "Corporate Loans":
            annexNumber = 4;
            break;
          case "Auto Loans":
            annexNumber = 5;
            break;
          case "Consumer Loans":
            annexNumber = 6;
            break;
          case "Credit Cards":
            annexNumber = 7;
            break;
          case "Leasing":
            annexNumber = 8;
            break;
          case "Esoteric":
            annexNumber = 9;
            break;
          case "Non-Performing Loans":
            annexNumber = 10;
            break;
          case "Asset Backed Commercial Papers":
            annexNumber = 11;
            break;
          default:
            annexNumber = "";
            break;
        }
      } else {
        annexNumber = "";
      }
      const buttonName = `Annex ${annexNumber}`;
      const annex2data = APIResponse.data.data[buttonName];
      const tableData = annex2data;
      this.setState(
        {
          MapDataKeys: tableData.length > 0 ? Object.keys(tableData[0]) : [],
          MapDataValues: APIResponse.data.data[buttonName].map((item) =>
            Object.keys(item).map((key) => ({ value: item[key] }))
          ),
          getLoansLoader: false,
          loading: false,
          tableData: annex2data,
          screenloader: false,
          openModalPayment: false,
          buttonName: buttonName,
        },
        () => {
          const filterConditions = this.state.MapDataKeys.reduce(
            (acc, column) => {
              acc[column] = {
                condition1: "Equals",
                value1: "",
                condition2: "Equals",
                value2: "",
                logic: "OR",
              };
              return acc;
            },
            {}
          );
          this.setState({ filterConditions });
        }
      );
    } else if (
      APIResponse.status === 200 &&
      APIResponse.data.isSuccess === false
    ) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleDealNameChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("dealname", newValue);
    this.setState({ DealName: newValue, isDisable: true }, async () => {
      console.log("pppppp", this.state.DealName);
      await this.getAllPaymentDatesByDealName();
      await this.getAllVersionsByPaymentDate();

      if (this.state.isLoansProcessed === "No") {
        this.props.history.push("/admin/general_recurring");
      }

      const { Version, getDashboardVersions } = this.state;

      if (
        (Version === "WIP" || Version === "V1") &&
        getDashboardVersions.length === 1
      ) {
        this.setState({ isDisable: false, Version: "WIP" }, async () => {
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      } else {
        function getMaxVersion(versions) {
          let highestNumber = -1;
          let prefix = "";

          for (const version of versions) {
            const numberMatch = version.match(/\d+/); // Extract the number using regex
            if (numberMatch) {
              const number = parseInt(numberMatch[0]);
              if (number > highestNumber) {
                highestNumber = number;
                prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
              }
            }
          }

          if (highestNumber >= 0) {
            return prefix + highestNumber;
          } else {
            // Handle the case where no valid version numbers were found
            return null;
          }
        }

        const latestVersion = getMaxVersion(getDashboardVersions); // Assuming the latest version is at the beginning of the array

        if (Version !== latestVersion) {
          // If the selected version is not the latest, call DealRecurring
          this.setState({ isDisable: this.state.isDisable });
          await this.ViewEsmaReport();
        } else {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.ViewEsmaReport();
            this.setState({ checkLoadStatus: false });
          });
        }
      }
    });
  };

  getAllPaymentDatesByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    const { NextPaymentDate } = this.state;

    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllPaymentDatesByDealName(data);

    console.log("getAllPaymentDatesByDealName", APIResponse.data);
    if (APIResponse.status === 200) {
      const paymentDates = APIResponse.data.PaymentDates;
      console.log(paymentDates, "payment");

      const [nextMonth, nextDay, nextYear] = NextPaymentDate.split("/");

      // Find the first payment date in the same month and year
      const sameMonthYearDate = paymentDates.find((date) => {
        const [month, day, year] = date.split("/");
        return month === nextMonth && year === nextYear;
      });

      const nextPaymentDate = sameMonthYearDate || paymentDates[0]; // Default to the first available date if none matches

      console.log("nextPaymentDate", nextPaymentDate);
      // // Determine the value for nextpaymentdate
      // const nextPaymentDate = paymentDates.includes(NextPaymentDate)
      //   ? NextPaymentDate
      //   : paymentDates[0];

      // Update sessionStorage with the determined nextpaymentdate
      sessionStorage.setItem("nextpaymentdate", nextPaymentDate);

      // Update sessionStorage with the dashboard dates
      sessionStorage.setItem("getDashboardDates", JSON.stringify(paymentDates));

      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("nextpaymentdate", newValue);
    this.setState({ NextPaymentDate: newValue, isDisable: true }, async () => {
      console.log("pppppp", this.state.NextPaymentDate);
      await this.getAllVersionsByPaymentDate();

      if (this.state.isLoansProcessed === "No") {
        this.props.history.push("/admin/general_recurring");
      }
      const { Version, getDashboardVersions } = this.state;

      if (
        (Version === "WIP" || Version === "V1") &&
        getDashboardVersions.length === 1
      ) {
        this.setState({ isDisable: false }, async () => {
          sessionStorage.setItem("version", "WIP");
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      } else {
        function getMaxVersion(versions) {
          let highestNumber = -1;
          let prefix = "";

          for (const version of versions) {
            const numberMatch = version.match(/\d+/); // Extract the number using regex
            if (numberMatch) {
              const number = parseInt(numberMatch[0]);
              if (number > highestNumber) {
                highestNumber = number;
                prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
              }
            }
          }

          if (highestNumber >= 0) {
            return prefix + highestNumber;
          } else {
            // Handle the case where no valid version numbers were found
            return null;
          }
        }

        const latestVersion = getMaxVersion(getDashboardVersions); // Assuming the latest version is at the beginning of the array

        if (Version !== latestVersion) {
          // If the selected version is not the latest, call DealRecurring
          this.setState({ isDisable: this.state.isDisable });
          await this.ViewEsmaReport();
        } else {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.ViewEsmaReport();
            this.setState({ checkLoadStatus: false });
          });
        }
      }
    });
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the Version state with the selected value
    sessionStorage.setItem("version", newValue);
    this.setState(
      {
        Version: newValue,
        isDisable: true,
      },
      async () => {
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.ViewEsmaReport();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            // If the selected version is not the latest, call ViewEsmaReport
            this.setState({ isDisable: this.state.isDisable });
            this.ViewEsmaReport();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.ViewEsmaReport();
            });
          }
        }
      }
    );
  };

  componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.setItem("isTabChange", false);
    sessionStorage.setItem("AddedContent", []);
    sessionStorage.setItem("TranchItems", []);
    sessionStorage.setItem("isClosingTape", false);

    if (
      this.state.isLoansProcessed === "No" ||
      sessionStorage.getItem("isESMA_Flag") === "No"
    ) {
      this.props.history.push("/admin/general_recurring");
    }
    const { Version, getDashboardVersions } = this.state;

    if (
      (Version === "WIP" || Version === "V1") &&
      getDashboardVersions.length === 1
    ) {
      this.setState({ isVersionChange: false }, async () => {
        sessionStorage.setItem("version", "WIP");
        await this.ViewEsmaReport();
        this.setState({ checkLoadStatus: false });
      });
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          const numberMatch = version.match(/\d+/); // Extract the number using regex
          if (numberMatch) {
            const number = parseInt(numberMatch[0]);
            if (number > highestNumber) {
              highestNumber = number;
              prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }
      const latestVersion = getMaxVersion(getDashboardVersions);
      if (Version !== latestVersion) {
        // If the selected version is not the latest, call DealRecurring
        this.setState({ isDisable: this.state.isDisable });
      } else {
        this.setState({ isDisable: false, Version: "WIP" }, async () => {
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      }
    }
  }
  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) => {
      for (let key in row) {
        if (
          row[key] &&
          row[key].toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }
      }
      return false;
    });

    this.setState({
      searchTerm: event.target.value,
      data: filteredData,
      isDataFound: filteredData.length > 0,
    });
  };

  clearSearch = () => {
    this.setState({
      searchTerm: "",
      data: this.state.tableData, // Reset the data to the original dataset
    });
  };

  filterData = (searchTerm) => {
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) =>
      Object.values(row).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      })
    );

    return filteredData;
  };

  render() {
    const { overlayVisible } = this.state;
    const {
      searchTerm,
      isDataFound,
      tableData,
      MapDataKeys,
      data,
      isSearchOpen,
      sortColumn,
      sortAscending,
    } = this.state;

    const customData =
      this.state.filteredData.length > 0
        ? this.state.filteredData
        : this.state.tableData;
    const sortedDeals = [...this.state.getDashboardDeals].sort();

    return (
      <React.Fragment>
        <div className={`page ${overlayVisible ? "blur-background" : ""}`}>
          <Sidebar activeComponent={"ESMA Reporting"} />
          <div className="content">
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div
                    className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1.5rem",
                      width: "90rem",
                    }}
                  >
                    <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                      {this.state.buttonName ? (
                        <h3 className="headerdashboard">
                          {this.state.buttonName}
                        </h3>
                      ) : (
                        <h3 className="headerdashboard">ANNEX</h3>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row1 css-recurring move-dropdowns-annexs">
                  <div className="recurring_details_new_Reporting">
                    <div>
                      <label className="dealInfo">Deal Name </label>
                      {/* <h6 className="dealInfo1">{this.state.DealName}</h6> */}
                      <div>
                        <select
                          className="input-select-general-new1-deal"
                          value={this.state?.DealName}
                          onChange={this.handleDealNameChange}
                        >
                          {sortedDeals.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="dealInfo">Payment Date </label>
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={this.state?.NextPaymentDate}
                          onChange={this.handlePaymentDateChange}
                        >
                          {this.state.getDashboardDates.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="dealInfo">Version </label>
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={
                            this.state.Version === "WIP"
                              ? sessionStorage.getItem("version")
                              : this.state.Version
                          }
                          onChange={this.handleVersionChange}
                        >
                          {this.state.getDashboardVersions?.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row1 movement-tabs-reporting">
                  <div>
                    {/* <div className="tablechangebutton"> */}
                    <div className="tablechangebuttonloans">
                      {this.state.buttonName ? (
                        <button
                          type="button"
                          onClick={() => this.handleClickAnnex()}
                          className={
                            this.state.activeInsights1 == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          {this.state.buttonName}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => this.handleClickAnnex()}
                          className={
                            this.state.activeInsights1 == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Annex
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => this.handleClickAnnex12()}
                        className={
                          this.state.activeInsights2 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Annex 12
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickAnnex14()}
                        className={
                          this.state.activeInsights3 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Annex 14
                      </button>
                    </div>
                  </div>
                  <div>
                    {" "}
                    {this.state.createStatus === "Update" ? null : (
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="bottom"
                        overlay={this.popoverBottomDownload()}
                        onExited={this.handleOverlayHide}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleButtonClick}
                        >
                          Download
                          {this.state.downArrow ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </Button>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {this.state.screenloader == true ? (
                  <LinearLoader></LinearLoader>
                ) : (
                  <React.Fragment>
                    <div
                      style={{
                        overflow: "auto",
                        borderRadius: "10px",
                        border: "0.5px solid var(--tableBorder)",
                      }}
                      className="movement-table-reporting-annexs"
                    >
                      <TableVirtuoso
                        styles={{ overflowAnchor: "none" }}
                        style={{ height: 800 }}
                        data={customData}
                        components={TableComponents}
                        fixedHeaderContent={() => (
                          <TableRow>
                            <>
                              <TableCell
                                style={{
                                  background: "var(--white)",
                                  color: "var(--headerText)",
                                  width: "100px",
                                }}
                              ></TableCell>
                              {MapDataKeys.map((column, index) => (
                                <TableCell
                                  key={index}
                                  style={{
                                    cursor: "pointer",
                                    width: `${column.length * 10}px`,
                                    whiteSpace: "nowrap",
                                    color: "var(--headerText)",
                                    background: "var(--white)",
                                    borderRight: "none !important",
                                    // filter: this.state.activeColumn && this.state.activeColumn !== column ? "blur(5px)" : "none",
                                  }}
                                >
                                  <div>
                                    {column}
                                    <div>
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="right"
                                        overlay={this.popoverBottom(column)}
                                        onExited={this.handleOverlayHide}
                                        rootClose={true}
                                      >
                                        <img
                                          src={FilterMappedTape}
                                          alt="Filter"
                                          style={{ cursor: "pointer" }}
                                          onClick={this.handleButtonClick}
                                        />
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </TableCell>
                              ))}
                            </>
                          </TableRow>
                        )}
                        itemContent={(index, row) => (
                          <>
                            <TableCell
                              style={{
                                color: "var(--tableText)",
                                // Same as the width of the first column

                                width: "150px", // Adjust width as needed
                                background: "var(--white)",
                                borderRight: "none !important",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            {MapDataKeys.map((column, columnIndex) => (
                              <TableCell
                                key={columnIndex}
                                style={{
                                  width: `${column.length * 10}px`,
                                  whiteSpace: "nowrap",
                                  background: "var(--white)",
                                  color: "var(--tableText)",
                                  borderRight: "none !important",
                                }}
                              >
                                {row[column]}
                              </TableCell>
                            ))}
                          </>
                        )}
                      />
                    </div>
                    <div></div>
                    <ReactModal
                      isOpen={this.state.openModalPayment}
                      contentLabel="Minimal Modal Example"
                      style={{
                        overlay: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "fixed", // Use fixed positioning for the overlay
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                        },
                        content: {
                          position: "absolute",
                          background: "transparent", // Make the modal content transparent
                          border: "none", // Optional: Remove any borders
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          overflow: "hidden",
                        },
                      }}
                    >
                      <React.Fragment>
                        <img
                          src={BeanEater}
                          style={{
                            width: "8vw",
                            height: "9vh",
                            backgroundColor: "transparent",
                          }}
                        />
                        <h3 className="dot-loader">Loading.Please wait</h3>
                      </React.Fragment>
                    </ReactModal>
                    <div id="modal1">
                      <ReactModal
                        isOpen={this.state.isBorrow}
                        onRequestClose={this.onCloseModalBorrow}
                        contentLabel="Minimal Modal Example"
                        style={customStylesautosmallmodalpopupBorrow}
                      >
                        <React.Fragment>
                          <div className="modalPopup">
                            <h3 className="popupheading">
                              Do you want to run Payment Waterfall?
                            </h3>

                            {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                            <div className="modalshiftcontent">
                              <div className="modalsubmit">
                                <div className="submitbuttonbg">
                                  <div className="row">
                                    <div className="row justify-content-start11">
                                      <button
                                        type="button"
                                        className="popupbutton1"
                                        onClick={this.onCloseModalBorrow}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <div className="row justify-content-end11">
                                      <button
                                        type="button"
                                        className="popupbutton1"
                                        onClick={this.checkBorrowBaseNo}
                                      >
                                        No
                                      </button>

                                      <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={this.checkBorrowBaseYes}
                                      >
                                        Yes
                                        {/* {this.state.formLoader === true ? (
<CircularProgress
size="25px"
color="primary"
/>
) : (
""
)} */}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      </ReactModal>
                    </div>
                  </React.Fragment>
                )}
                <>
                  <div className="btn_move_Needed">
                    <button className="btn_next" onClick={this.showNext}>
                      Next
                    </button>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
        {overlayVisible && (
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0)",
              zIndex: 999,
            }}
            onClick={this.handleOverlayHide}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withSnackbar(Annex);
