import React, { useEffect, useRef, useState } from "react";
import Back_Icon from "../../../../../images/Back_Icon.svg";
import Plot from "react-plotly.js";

function LoanDetailsAnalytics({ loanData, handleBack, graphData }) {
  console.log({ loanData });
  console.log({ graphData });
  const [plots, setPlots] = useState([]); // Initialize with an empty array
  const [tables, setTables] = useState([]); // For storing table data
  const plotContainerRef = useRef(null);

  useEffect(() => {
    if (Array.isArray(graphData)) {
      const updatedPlots = [];
      const updatedTables = [];

      graphData.forEach((graph) => {
        Object.keys(graph).forEach((key) => {
          const dataString = graph[key];

          if (typeof dataString === "string") {
            try {
              const parsedData = JSON.parse(dataString);

              // Check if it's a Plotly plot or table data
              if (parsedData.data && parsedData.layout) {
                // Deep clone each parsed plot data to avoid reference issues
                const deepClonedData = JSON.parse(JSON.stringify(parsedData));
                updatedPlots.push(deepClonedData);
              } else if (Array.isArray(parsedData)) {
                // Assuming it's table data in array format
                updatedTables.push(parsedData);
              }
            } catch (error) {
              console.error(`Error parsing data for key "${key}":`, error);
            }
          }
        });
      });

      // Set the plots and tables after parsing data
      setPlots([...updatedPlots]);
      setTables([...updatedTables]);

      // Handle resizing
      const handleResize = () => {
        setPlots((prevPlots) =>
          prevPlots.map((plot) => ({
            ...plot,
            layout: {
              ...plot.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          }))
        );
      };

      window.addEventListener("resize", handleResize);

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [graphData]);

  // Convert each table data to a format compatible with antd Table

  return (
    <div className="loan-details-graphs">
      <div className="loan-details-container">
        <div>
          <img
            src={Back_Icon}
            alt="Back_Icon"
            style={{ marginRight: "4px", cursor: "pointer" }}
            onClick={() => handleBack()}
          />
          <span className="info-header">
            Last Updated: {loanData["Last Updated"]}
          </span>
        </div>

        <div className="loan-header">
          <div className="loan-info-row">
            <div className="info-item">
              <span className="info-header">Loan ID</span>
              <span className="as_of_Labels">{loanData["Loan ID"]}</span>
            </div>
            <div className="info-item">
              <span className="info-header">Original Principal Balance</span>
              <span className="as_of_Labels">
                {loanData["Original Principal Balance"]}
              </span>
            </div>
            <div className="info-item">
              <span className="info-header">Current Principal Balance</span>
              <span className="as_of_Labels">
                {loanData["Current Principal Balance"]}
              </span>
            </div>
            <div className="info-item">
              <span className="info-header">Property State</span>
              <span className="as_of_Labels">{loanData["Property State"]}</span>
            </div>
            <div className="info-item">
              <span className="info-header">Maturity Date</span>
              <span className="as_of_Labels">{loanData["Maturity Date"]}</span>
            </div>
            {/* <div className="info-item">
              <span>Property City</span>
              <span>{loanData["Property City"]}</span>
            </div>
            <div className="info-item">
              <span>Property State</span>
              <span>{loanData["Property State"]}</span>
            </div>
            <div className="info-item">
              <span>Loan Modification Type</span>
              <span>{loanData["Loan Modification Type"]}</span>
            </div>
            <div className="info-item">
              <span>HouseCanary One Year HPA</span>
              <span>{loanData["HouseCanary One Year HPA"]}</span>
            </div> */}
          </div>

          {/* <div className="loan-financial-row">
            <span>
              <strong>Current Balance:</strong>
            </span>
            <div className="progress-bar yellow"></div>
            <span>{loanData["Current Balance"]}</span>

            <span>
              <strong>Installment:</strong>
            </span>
            <div className="progress-bar red"></div>
            <span>{loanData["Installment"]}</span>

            <span>
              <strong>Product:</strong> {loanData["Product"]}
            </span>
          </div> */}
          {/* <div className="loan-financial-row">
            <div className="bar-section">
              <div
                className="current-balance"
                style={{ width: `${loanData["Current Principal Balance"]}%` }}
              >
                CPB (
                {loanData["Current Principal Balance"]})
              </div>
              <div
                className="original-balance"
                style={{ width: `${loanData["Original Principal Balance"]}%` }}
              >
                OPB(
                {loanData["Original Principal Balance"]})
              </div>
            </div>
          </div> */}

          <div className="loan-financial-row">
            {/* Current Balance */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
                width: "50%",
              }}
            >
              <span
                style={{ marginRight: "10px", whiteSpace: "nowrap" }}
                className="info-header"
              >
                Current Principal Balance:
              </span>
              <div
                className="original-balance"
                style={{
                  width: "280px", // Fixed width for consistent bar size
                  maxWidth: "300px", // Fixed width for consistent bar size
                  backgroundColor: "#e5f3f2",
                  height: "10px",
                  position: "relative",
                  marginRight: "10px",
                }}
              >
                <div
                  className="current-balance"
                  style={{
                    width: `${(() => {
                      // Helper function to parse values as numbers
                      const parseValue = (value) => {
                        if (typeof value === "number") return value;
                        if (typeof value === "string")
                          return parseFloat(value.replace(/,/g, ""));
                        return 0; // Fallback for null, undefined, or unexpected values
                      };

                      // Parse current and original balances
                      const currentBalance = parseValue(
                        loanData["Current Principal Balance"]
                      );
                      const originalBalance = parseValue(
                        loanData["Original Principal Balance"]
                      );

                      // Calculate width as a percentage, with a fallback to 0 if originalBalance is 0
                      return (currentBalance / originalBalance) * 100;
                    })()}%`,
                    maxWidth: "100%", // Ensure it doesn't exceed the container width
                    backgroundColor: "#004d40",
                    height: "100%",
                  }}
                ></div>
              </div>
              <span>
                {loanData["Current Principal Balance"].toLocaleString()}
              </span>
            </div>

            {/* Original Balance */}
            <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <span
                style={{ marginRight: "10px", whiteSpace: "nowrap" }}
                className="info-header"
              >
                Original Principal Balance:
              </span>
              <div
                className="original-balance"
                style={{
                  width: "280px", // Fixed width for consistent bar size
                  maxWidth: "300px", // Fixed width for consistent bar size
                  backgroundColor: "#e5f3f2",
                  height: "10px",
                  position: "relative",
                  marginRight: "10px",
                }}
              >
                <div
                  className="current-balance"
                  style={{
                    width: `${(() => {
                      // Helper function to parse values as numbers
                      const parseValue = (value) => {
                        if (typeof value === "number") return value;
                        if (typeof value === "string")
                          return parseFloat(value.replace(/,/g, ""));
                        return 0; // Fallback for null, undefined, or unexpected values
                      };

                      // Parse original and current balances
                      const originalBalance = parseValue(
                        loanData["Original Principal Balance"]
                      );
                      const currentBalance = parseValue(
                        loanData["Current Principal Balance"]
                      );

                      // Calculate width as a percentage, with a fallback to 0 if currentBalance is 0
                      return (originalBalance / currentBalance) * 100;
                    })()}%`,
                    maxWidth: "100%", // Ensure it doesn't exceed the container width
                    backgroundColor: "#ffc000",
                    height: "100%",
                  }}
                ></div>
              </div>
              <span>
                {loanData["Original Principal Balance"].toLocaleString()}
              </span>
            </div>
          </div>
        </div>

        <div className="loan-contents-container">
          <div className="loan-section">
            <h3 className="loan-header-info">Collections</h3>
            <p>Scheduled Principal: {loanData["Scheduled Principal"]}</p>
            <p>Unscheduled Principal: {loanData["Unscheduled Principal"]}</p>
            <p>Interest Paid: {loanData["Interest Paid"]}</p>
            {/* Add more fields as needed */}
          </div>
          <div className="borrower-attributes">
            <h3 className="loan-header-info">Borrower Attributes</h3>
            <p>Current FICO: {loanData["Current FICO"]}</p>
            <p>Original FICO: {loanData["Original FICO"]}</p>
            <p>Original DTI: {loanData["Original DTI"]}</p>
            <p>Loan Purpose: {loanData["Loan Purpose"]}</p>
            <p>Occupancy Type: {loanData["Occupancy Type"]}</p>
            <p>Property Type: {loanData["Property Type"]}</p>
            {/* <p>Updated FICO: {loanData["Updated FICO"]}</p>
            <p>
              Updated LTV by HouseCanary:{" "}
              {loanData["Updated LTV by HouseCanary"]}
            </p> */}
          </div>

          <div className="performance-section">
            <h3 className="loan-header-info">Terms</h3>
            <p>Original Term: {loanData["Original Term"]}</p>
            <p>Age: {loanData["Age"]}</p>
            <p>Remaining Term: {loanData["Remaining Term"]}</p>
            <p>
              Original Amortisation Term:{" "}
              {loanData["Original Amortisation Term"]}
            </p>
            <p>
              Remaining Amortisation Term:{" "}
              {loanData["Remaining Amortisation Term"]}
            </p>
            {/* <p>
              Loan Int Paid Through Date:{" "}
              {loanData["Loan Int Paid Through Date"]}
            </p>
            <p>Original Term: {loanData["Original Term"]}</p>
            <p>Loan Age: {loanData["Loan Age"]}</p> */}
          </div>
          <div className="performance-section">
            <h3 className="loan-header-info">Performance</h3>
            <p>Days Delinquent: {loanData["Days Delinquent"]}</p>
            <p>Loan Status: {loanData["Loan Status"]}</p>
            <p>Realized Loss: {loanData["Realized Loss"]}</p>
            <p>Modification Status: {loanData["Modification Status"]}</p>
            {/* <p>
              Cumulative Interest Received:{" "}
              {loanData["Cumulative Interest Received"]}
            </p> */}
          </div>

          <div className="collateral-section">
            <h3 className="loan-header-info">Collateral</h3>
            <p>Original LTV: {loanData["Original LTV"]}</p>
            <p>Current LTV: {loanData["Current LTV"]}</p>
            <p>Purchased Price: {loanData["Purchased Price"]}</p>
            <p>Original Valuation: {loanData["Original Valuation"]}</p>
            <p>Current Valuation: {loanData["Current Valuation"]}</p>
          </div>
          <div className="collateral-section">
            <h3 className="loan-header-info">Interest Rate Details</h3>
            <p>ARM Index: {loanData["ARM Index"]}</p>
            <p>Initial Rate Cap: {loanData["Initial Rate Cap"]}</p>
            <p>Gross Margin: {loanData["Gross Margin"]}</p>
            <p>Subsequent Rate Cap: {loanData["Subsequent Rate Cap"]}</p>
          </div>
        </div>
        <div className="chart-container-portfoilo">
          {plots.map((plot, index) => (
            <div
              key={index}
              className="plot-borders"
              // ref={(el) => (plotRefs.current[index] = el)}
              ref={plotContainerRef}
            >
              <Plot
                data={plot.data}
                layout={{
                  ...plot.layout,
                  width: plotContainerRef.current?.offsetWidth,
                }}
                config={{
                  displaylogo: false,
                  modeBarButtonsToRemove: [
                    // "pan2d",
                    "select2d",
                    "lasso2d",
                    "zoom",
                    "resetScale2d",
                  ],
                }}
                className="plot-inline-chat-charts padding-graph"
              />
            </div>
          ))}

          {/* Render Tables using antd Table component */}
          <div className="performance-table">
            {tables.map((table, index) => (
              <div key={index} className="table-loans-wrapper">
                <table>
                  <thead className="normal_table_headers">
                    <tr>
                      {Object.keys(table[0] || {}).map((col, i) => (
                        <th key={i}>{col}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="table-body-wrapper">
                    {table.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.entries(row).map(([col, cell], cellIndex) => (
                          <td
                            key={cellIndex}
                            style={{
                              textAlign: col.includes("$") ? "right" : "left",
                            }}
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanDetailsAnalytics;
