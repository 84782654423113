/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  IssuerIcons,
  Servicer_Dashboard,
  getInvestorDashboard,
  PendingUsersDashBoard,
  Issuer_DashBoard,
} from "./menu";
import LinkItem from "../linkItem";
import SupportWindow from "../SupportWindow/SupportWindow";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Intan_AI from "../../images/Intan_AI.svg";
import IntainLogo from "../../images/IntainLogo.png";
import help from "../../images/help.svg";
import "./sidebar.css";
import moon from "../../images/moon.svg";
import sun from "../../images/sun.svg";
import { Tooltip } from "@mui/material";
import { Popover } from 'antd';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: sessionStorage.getItem("user_name"),
      userName: sessionStorage.getItem("user_name"),
      OrgName: sessionStorage.getItem("OrgName"),
      userrole: sessionStorage.getItem("userrole"),
      ChannelName: sessionStorage.getItem("ChannelName"),
      loading: false,
      getLoansLoader: false,
      open: false,
      message: "",
      currentmenu: [],
      menu_loader: false,
      title: "",
      visible: false,
      showProfileModal: false,
      isDark: sessionStorage.getItem("dark-theme") === "dark",
        //  isDark: sessionStorage.getItem("dark-theme") ? 
        // sessionStorage.getItem("dark-theme") === "dark" : 
        // true,
      isSidebarExpanded: true,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleTheme = this.toggleTheme.bind(this);
  }


  handleProfileClick = () => {
    this.setState({ showProfileModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showProfileModal: false });
  };

  handleLogout = () => {
    sessionStorage.clear();
    window.location.replace("/");
  };

  toggleTheme() {
    this.setState(
      (prevState) => ({
        isDark: !prevState.isDark,
      }),
      () => {
        const theme = this.state.isDark ? "dark" : "light";
        document.documentElement.setAttribute("dark-theme", theme);
        sessionStorage.setItem("dark-theme", theme);

        console.log({ theme });
      }
    );
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({
      isSidebarExpanded: !prevState.isSidebarExpanded,
    }));
  };

  async componentDidMount() {
    const savedTheme = sessionStorage.getItem("dark-theme");
    if (savedTheme) {
      const isDark = savedTheme === "dark";
      this.setState({ isDark }, () => {
        document.documentElement.setAttribute("dark-theme", savedTheme);
      });
    // } else {
    //   sessionStorage.setItem("dark-theme", "dark");
    //   document.documentElement.setAttribute("dark-theme", "dark");
    //
     }
    const { currentUser } = this.state;
    if (currentUser !== null) {
      const firstname = currentUser.charAt(0).toUpperCase();
      this.setState({ currentUser: firstname });
    }

    // Simplify currentmenu logic
    let currentMenu = [];
    switch (true) {
      case currentUser === "Trustee":
        currentMenu = IssuerIcons;
        break;
      case currentUser.includes("Investor") ||
        currentUser.includes("AngeloGordon"):
        currentMenu = getInvestorDashboard();
        break;
      case currentUser === "Issuer" || currentUser === "Issuer2":
        currentMenu = Issuer_DashBoard;
        break;
      case currentUser === "admin":
        currentMenu = PendingUsersDashBoard;
        break;
      default:
        currentMenu = Servicer_Dashboard;
    }

    this.setState({ currentmenu: currentMenu, menu_loader: true });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ visible: false });
    }
  }

  render() {
    const {
      currentmenu,
      menu_loader,
      visible,
      currentUser,
      showProfileModal,
      isDark,
      isSidebarExpanded,
    } = this.state;
    const { activeComponent } = this.props;

    const popoverContent = (
      <div style={{ width: '250px' }}>
        <div className="profile-section">
          <div className="profile-picture">
            {currentUser?.charAt(0) || "U"}
          </div>
          <div>
            <h4 style={{margin: 0, color: 'var(--headerText)'}}>{sessionStorage.getItem("user_name")}</h4>
            {/* <button className="modal-button" onClick={() => alert("View Profile")}>
          View Profile
        </button> */}
            <small style={{color: 'var(--headerText)', opacity: 0.7}}>{sessionStorage.getItem("userrole")}</small>
          </div>
        </div>

        <button className="modal-button" onClick={() => alert("View Profile")}>
          View Profile
        </button>

        <div className="theme-toggle-section" style={{display: 'flex', alignItems: 'center',gap:"25px"}}>
          <label style={{color: 'var(--headerText)',fontSize: '15px'}}>Light</label>
          <div className="tooltip-container">
            <input
              type="checkbox"
              id="darkmode-toggle"
              checked={isDark}
              onChange={this.toggleTheme}
              className="darkmode-checkbox"
            />
            <label htmlFor="darkmode-toggle" className="darkmode-label">
              <img src={sun} alt="sun" className="moon" />
              <img src={moon} alt="moon" className="sun" />
            </label>
          </div>
          <label style={{color: 'var(--headerText)',fontSize: '15px'}}>Dark</label>
        </div>

        <button className="modal-button-logout" onClick={this.handleLogout}>
          Logout
        </button>
      </div>
    );

    return (
      <div className="sidebar">
        {menu_loader && (
          <>
            <div className="sidebar-icons">
              <div className="sidebar-logo">
                <img src={Intan_AI} alt="Intan AI" className="logo-intan" />
                <img
                  src={IntainLogo}
                  alt="Intain Logo"
                  className="logo-intain-hidden"
                />
              </div>

              <ul>
                {currentmenu.map((item) => (
                  <li
                    key={item.title}
                    className={`issure-icons${
                      item.title === "ESMA Reporting" &&
                      sessionStorage.getItem("isESMA_Flag") === "No"
                      //  ||
                      // item.title === "Portfolio"
                        ? " disabledESMA"
                        : ""
                    }`}
                  >
                    <Link
                      to={item.linkto}
                      title={item.title}
                      className={
                        activeComponent === item.title && !visible
                          ? "issure-icon-active"
                          : "issure-icon"
                      }
                    >
                      <img alt="issuer icon" src={item.icon} />
                      <span className="sidebar-text content-show">
                        {item.title}
                      </span>
                      {/* Text is hidden by default, shows on hover */}
                    </Link>

                    {item.subitems && (
                      <ul>
                        <div className="arrow-left"></div>
                        <div className="arrow_box">
                          {item.subitems.map((subItem) => (
                            <li key={subItem.title}>
                              <LinkItem
                                to={subItem.linkto}
                                title={subItem.title}
                              ></LinkItem>
                            </li>
                          ))}
                        </div>
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            {currentUser.includes("Investor") && (
              <div
                className={`AI_Icon ${
                  visible ? "issure-icon-active" : "issure-icon"
                }`}
                ref={this.wrapperRef}
                onClick={() => this.setState({ visible: !visible })}
              >
                <h1 className="Beta">BETA</h1>
                <SupportWindow visible={visible} />
              </div>
            )}

            <div className="sidebar-footer">
              <ul>
                <li className="sidebar-item">
                  <div className="sidebar-item-content">
                    <img src={help} alt="Help" />
                    <span className="sidebar-text content-show">FAQ</span>
                  </div>
                </li>
                <li className="sidebar-item">
                  <div className="sidebar-item-content">
                    <NotificationsIcon className="notificationcolor" />
                    <span className="sidebar-text content-show">
                      Notifications
                    </span>
                  </div>
                </li>
                <li className="sidebar-item">
                  <Popover 
                    content={popoverContent}
                    trigger="click"
                    placement="rightTop"
                    open={showProfileModal}
                    onOpenChange={(visible) => this.setState({ showProfileModal: visible })}
                    overlayClassName="profile-popover"
                  >
                    <div className="sidebar-item-content">
                      <div className="sidebar-item-profile">
                        <p>{currentUser}</p>
                      </div>
                      <span className="sidebar-text content-show">
                        Profile & More
                        <svg
                          className="w-5 h-5 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m9 5 7 7-7 7"
                          />
                        </svg>
                      </span>
                    </div>
                  </Popover>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    );
  }
}
